import { privateRequest } from '../../utils/request'

class CompaniesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      companies: '/companies',
    }
  }

  getCompanies = async (params) => {
    const response = await this.#api.get(this.#endPoints.companies, { params })
    return response
  }
}

const companiesApiService = new CompaniesApiService({ api: privateRequest })

export default companiesApiService
