import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import BoxShadowed from '../../ui/Layout/BoxShadowed'
import UIPreloader from '../../ui/UIPreloader/UIPreloader'
import UITable from '../../ui/UITable/UITable'
import {
  awaitingRestitutionPoliciesHead,
  companiesTableHead,
} from '../../utils/constants/table.constants'
import {
  getCompaniesTableData,
  getOperatorRviRestitutionInProgressTableData,
} from '../../utils/helpers/table.helpers'
import RestitutionInProgressActionItems from '../RVI/Operator/RVIPoliciesActions/RestitutionInProgressActionItems'
import UITablePagination from '../../ui/UITable/UITablePagination'
import { setPoliciesInRestitutionParams } from '../../store/features/rviOperator/rviOperator.reducer'
import UITableWrapper from '../../ui/UITable/UITableWrapper'
import { getCompanies } from '../../store/features/companiesSlice/companies.actions'
import { setCompaniesParams } from '../../store/features/companiesSlice/companies.reducer'

const Companies = () => {
  const isLoading = useSelector((state) => state.companies.companies.isLoading)
  const companies = useSelector((state) => state.companies.companies.data)
  const params = useSelector((state) => state.companies.companies.params)
  const total = useSelector((state) => state.companies.companies.total)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPageTitle('Companies'))
    dispatch(getCompanies())
  }, [params])

  return (
    <BoxShadowed>
      <UITableWrapper>
        {isLoading && <UIPreloader />}
        <UITable
          head={companiesTableHead}
          content={getCompaniesTableData(companies)}
          emptyText="No companies found."
        />
        <UITablePagination
          count={total}
          page={params.page}
          rowsPerPage={params.itemsPerPage}
          onPageChange={(e, newValue) => {
            dispatch(
              setCompaniesParams({
                ...params,
                page: newValue,
              }),
            )
          }}
          onRowsPerPageChange={(e) => {
            dispatch(
              setCompaniesParams({
                ...params,
                page: 1,
                itemsPerPage: e.target.value,
              }),
            )
          }}
        />
      </UITableWrapper>
    </BoxShadowed>
  )
}

export default Companies
