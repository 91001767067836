import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import IconCheckmark from '../../../assests/img/icons/icon-checkmark-black.svg'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import { useSelector } from 'react-redux'

const AdditionalProductsBlock = (props) => {
  const { handleChange } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const selectedPackage = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.selectedPackage,
  )
  const additionalProducts = useSelector(
    (state) =>
      state.carFinance.loanApplications[selectedTab].loanCalculatorParams.additionalProducts,
  )

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.mainGreen,
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,.1)',
        px: '37px',
        py: '22px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&.xs-only': {
          px: '16px',
          py: '15px',
          display: 'block',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '18px',
            alignItems: 'flex-end',
            mb: selectedPackage === 'finance-only' ? '' : '8px',
          }}
        >
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              fontSize: '24px',
              '&.xs-only': {
                fontSize: '18px',
              },
            }}
          >
            {selectedPackage === 'full' ? 'Full Pack' : ''}
            {selectedPackage === 'finance-only' ? 'Finance Only' : ''}
            {selectedPackage === 'custom' ? 'Custom Pack' : ''}
          </TextBoldNormal>
          <TextNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              position: 'relative',
              top: '-4px',
              '&.xs-only': {
                top: '-1.5px',
              },
            }}
          >
            {selectedPackage === 'finance-only' ? 'Loan' : 'Loan +'}
          </TextNormal>
        </Box>
        {selectedPackage !== 'finance-only' &&
        (additionalProducts.casco ||
          additionalProducts.ppi ||
          additionalProducts.rvi ||
          additionalProducts.warranty) ? (
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              gap: '18px',
              '&.xs-only': {
                flexWrap: 'wrap',
                gap: '10px',
                '& img': {
                  width: '17px',
                },
                '& p': {
                  fontSize: '14px',
                },
                '& div': {
                  gap: '4px',
                },
              },
            }}
          >
            {additionalProducts.rvi || selectedPackage === 'full' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                }}
              >
                <img src={IconCheckmark} alt="" />
                <TextNormal>Residual Value</TextNormal>
              </Box>
            ) : null}
            {additionalProducts.warranty || selectedPackage === 'full' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                }}
              >
                <img src={IconCheckmark} alt="" />
                <TextNormal>Warranty</TextNormal>
              </Box>
            ) : null}
            {additionalProducts.casco || selectedPackage === 'full' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                }}
              >
                <img src={IconCheckmark} alt="" />
                <TextNormal>Casco</TextNormal>
              </Box>
            ) : null}
            {additionalProducts.ppi || selectedPackage === 'full' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                }}
              >
                <img src={IconCheckmark} alt="" />
                <TextNormal>PPI</TextNormal>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <ButtonHollow
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          borderColor: colorsConfig.mainBlack,
          color: colorsConfig.mainBlack,
          width: '179px',
          '&.xs-only': {
            width: '100%',
            mt: '20px',
          },
        }}
        onClick={() => {
          handleChange()
        }}
      >
        Change
      </ButtonHollow>
    </Box>
  )
}

export default AdditionalProductsBlock
