export const policiesOperatorTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },
  {
    title: 'Start date',
  },
  {
    title: 'End date',
  },
  {
    title: 'Status',
  },
  {
    title: 'Actions',
  },
]

export const awaitingRestitutionPoliciesHead = [
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },

  // {
  //   title: 'Location',
  // },
  {
    title: 'Mileage',
  },
  {
    title: 'Vehicle expertise',
  },
  {
    title: 'Status',
  },
  {
    title: 'Restitution date',
  },
  {
    title: 'Actions',
  },
]

export const policiesTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'table_col_brand_model',
  },
  {
    title: 'table_col_start_date',
  },
  {
    title: 'table_col_end_date',
  },
  {
    title: 'table_col_status',
  },
  {
    title: '',
  },
]

export const policyImportsTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'Uploaded at',
  },
  {
    title: 'Status',
  },
  {
    title: 'Error message',
  },
  {
    title: 'Amount of policies',
  },
  {
    title: '',
  },
]

export const importedPoliciesTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer name',
  },
  {
    title: 'Select dealer',
  },
]

export const companiesTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'Name',
  },
  {
    title: 'E-mail',
  },
  {
    title: 'Landline number',
  },
  {
    title: 'Created at',
  },
]
