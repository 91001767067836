import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Divider,
  Button,
  IconButton,
  Pagination,
  TablePagination,
  useMediaQuery,
} from '@mui/material'
import { API_ENDPOINTS } from '../../config/default'
import { privateRequest } from '../../utils/request'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { colorsConfig } from '../../config/themeConfig'
import TextFieldSmall from '../../ui/TextFieldSmall'
import Preloader from '../../components/Preloader'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { getFormattedDate } from '../../utils/date.helpers'

const OperatorSignUpRequests = () => {
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const { id: requestId } = useParams()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(0)
  const [filterApproved, setFilterApproved] = useState(null)
  const [numApproved, setNumApproved] = useState('')
  const [numAwaitingApproval, setNumAwaitingApproval] = useState('')
  const [pdfLink, setPdfLink] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchLine, setSearchLine] = useState('')
  const [expanded, setExpanded] = useState('panel1')
  const [userRequests, setUserRequests] = useState([])
  const [disapproveMessage, setDisapproveMessage] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const sections = [
    {
      id: 1,
      name: `Awaiting approval (${numAwaitingApproval})`,
      component: (
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '3px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle cx="5" cy="5" r="5" fill="#AC0000" />
          </svg>
        </Box>
      ),
    },
    {
      id: 2,
      name: `Approved (${numApproved})`,
    },
  ]

  const handleChange = (panel) => (event, isExpanded) => {
    requestId ? setExpanded(isExpanded ? panel : null) : setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (requestId) {
      setExpanded(`panel${requestId}`)
    }

    if (!location.pathname.includes(requestId)) {
      setExpanded(false)
    }
  }, [requestId, location])

  function handleFormatDate(date, symbol) {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*$/

    const match = date.match(regex)

    const day = match[3]
    const month = match[2]
    const year = match[1]

    return `${day}${symbol}${month}${symbol}${year}`
  }

  const getUserRequests = () => {
    setLoading(true)

    privateRequest
      .get(
        API_ENDPOINTS.registerManagerDemands,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const requests = response.data[`hydra:member`].filter(filterbyRejectMessage)
        setUserRequests(requests)
      })
  }

  useEffect(() => {
    getUserRequests()
    dispatch(setCurrentPageTitle('Sign up requests'))
  }, [])

  useEffect(() => {
    const approvedRequests = userRequests.filter((request) => request.approved)
    const awaitingApprovalRequests = userRequests.filter((request) => request.approved == null)

    setNumApproved(approvedRequests.length)
    setNumAwaitingApproval(awaitingApprovalRequests.length)

    setLoading(false)
  }, [filterApproved, userRequests, loading])

  // const filteredUserRequests = (filterApproved === null)
  //     ? userRequests.filter(filterBySearchLine)
  //     : (requestId)
  //         ? userRequests.filter(filterByRequestId)
  //         : userRequests.filter(filterByApproval).filter(filterBySearchLine);

  const filterBySearchLine = (request) => {
    // Filter by searchLine
    const searchValue = searchLine.toLowerCase()
    return (
      request.login.toLowerCase().includes(searchValue) ||
      request.firstName.toLowerCase().includes(searchValue) ||
      request.surname.toLowerCase().includes(searchValue)
    )
  }
  const filterByApproval = (request) =>
    filterApproved == null ? request.approved == null : request.approved
  const filterbyRejectMessage = (request) => !request.disapproveMessage
  const filterByRequestId = (request) => request.id === +requestId

  const filteredUserRequests = requestId
    ? userRequests.filter(filterByRequestId)
    : userRequests.filter(filterByApproval).filter(filterBySearchLine)

  const getMarkerColor = (createdAt, fill, stroke) => {
    const empty = !fill || !stroke

    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (empty) {
      if (timeDifferenceInHours > 24) {
        fill = '#AC0000'
        stroke = '#B83629' // Red color
      } else if (timeDifferenceInHours > 20) {
        fill = '#F5BD4F'
        stroke = '#D69E3C' // Yellow color
      } else {
        fill = '#4DED6D'
        stroke = '#3CC457' // Green color
      }
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  }

  const hasRequestsOver24Hours = () => {
    const awaitingApprovalRequests = userRequests.filter((request) => !request.approved)
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return awaitingApprovalRequests.some((request) => {
      const requestTime = new Date(request.createdAt)
      return requestTime < twentyFourHoursAgo && !request.approved
    })
  }

  const handleRequestOnApprove = (requestId) => {
    setLoading(true)

    privateRequest
      .post(
        API_ENDPOINTS.registerManagerDemandsApprove(requestId),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        getUserRequests()
        setLoading(false)
      })
  }

  const handleRequestOnReject = (requestId) => {
    setLoading(true)

    const requestBody = {
      message: disapproveMessage, // Include the disapprove message in the request body
    }

    privateRequest
      .post(API_ENDPOINTS.registerManagerDemandsReject(requestId), requestBody, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        getUserRequests()
        setLoading(false)
      })
  }

  const getIdFromString = (string) => {
    const regex = /\/files\/(.+)/
    const match = string.match(regex)
    return match ? match[1] : null
  }

  const handleRequestOnGettingPdfLink = (requestId) => {
    privateRequest
      .get(
        API_ENDPOINTS.privateFiles(requestId),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const { signedUrl } = response.data

        setPdfLink(signedUrl)
      })
  }

  useEffect(() => {
    if (pdfLink) {
      window.open(pdfLink, '_blank')
    }
  }, [pdfLink])

  const getPaginatedData = (data, page, rowsPerPage) => {
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return data.slice(startIndex, endIndex)
  }

  return (
    <Box
      sx={{
        pt: '15px',
        pb: '37px',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Preloader type="gooey" text={'Loading the requests...'} />
        ) : (
          <>
            {xsOnly ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  position: 'relative',
                  width: 'calc(100% + 4px)',
                  margin: '20px 0',
                }}
              >
                {sections.map((section, index) => {
                  return (
                    <Button
                      key={index}
                      className={index === tab ? 'active' : ''}
                      sx={{
                        background: colorsConfig.bgLightBlue,
                        color: colorsConfig.mainBlack,
                        position: 'relative',
                        height: '48px',
                        textTransform: 'none',
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: '16px',
                        fontWeight: 500,
                        mb: '4px',
                        mr: '4px',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        '&::after': {
                          content: '" "',
                          display: 'block',
                          width: '100%',
                          height: '3px',
                          bottom: 0,
                          left: 0,
                          position: 'absolute',
                          background: colorsConfig.mainGreen,
                          transformOrigin: '50% 50%',
                          transform: 'scale(0)',
                          transition: '0.25s all ease-in-out',
                        },
                        '&.active': {
                          background: colorsConfig.bgLightBlue,
                          color: colorsConfig.mainBlack,
                          '&::after': {
                            transform: 'scale(1)',
                            transition: '0.25s all ease-in-out',
                          },
                        },
                        '&.Mui-disabled > div > p': {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      onClick={() => {
                        setTab(index)
                        if (index === 0) {
                          setFilterApproved(null)
                        } else {
                          setFilterApproved(true)
                        }
                      }}
                    >
                      {section.name}
                      {hasRequestsOver24Hours(index) && section.component && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          style={{ margin: '0 0 6px 2px' }}
                        >
                          <circle cx="5" cy="5" r="5" fill="#AC0000" />
                        </svg>
                      )}
                    </Button>
                  )
                })}
              </Box>
            ) : (
              <Tabs
                value={tab}
                onChange={(e, newVal) => {
                  setTab(newVal)
                  if (newVal === 0) {
                    setFilterApproved(null)
                  } else {
                    setFilterApproved(true)
                  }
                }}
                variant="scrollable"
                sx={{
                  display: requestId ? 'none' : xsOnly ? 'flex' : 'block',
                  position: 'relative',
                  width: '100%',
                  '& .MuiTabs-indicator': {
                    background: colorsConfig.mainGreen,
                  },
                  '& .MuiTab-textColorPrimary': {
                    color: colorsConfig.mainBlack,
                    textTransform: 'none',
                    fontFamily: '"Helvetica", sans-serif',
                    fontSize: '16px',
                    fontWeight: 500,
                    paddingLeft: '15px',
                  },
                  '& .MuiButtonBase-root': {
                    background: colorsConfig.bgLightBlue,
                    width: xsOnly ? '50%' : 'initial',
                  },
                  '& .Mui-selected': {
                    color: colorsConfig.mainBlack,
                  },
                  ' .MuiButtonBase-root': {
                    height: '41px',
                  },
                }}
              >
                {sections.map((section, index) => {
                  return (
                    <Tab
                      key={index}
                      label={
                        <Box>
                          {section.name}
                          {hasRequestsOver24Hours(index) && section.component && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              style={{ margin: '0 0 6px 2px' }}
                            >
                              <circle cx="5" cy="5" r="5" fill="#AC0000" />
                            </svg>
                          )}
                        </Box>
                      }
                      sx={{
                        fontSize: '13px',
                      }}
                    />
                  )
                })}
              </Tabs>
            )}
            <Box
              sx={{
                position: xsOnly ? 'relative' : 'absolute',
                top: xsOnly ? '' : 0,
                right: xsOnly ? '' : 0,
                height: !xsOnly && '48px',
                display: 'flex',
                justifyContent: requestId ? 'flex-end' : 'flex-start',
                marginBottom: requestId ? '25px' : !xsOnly && '40px',
                order: xsOnly ? 1 : 0,
              }}
            >
              <Box
                sx={{
                  marginTop: xsOnly ? '25px' : '',
                  display: 'flex',
                  gap: xsOnly ? '12px' : '24px',
                  flexDirection: xsOnly ? 'column' : 'row',
                  '& .MuiBox-root': {
                    display: 'flex',
                    gap: xsOnly ? '2px' : '6px',
                    alignItems: 'center',
                    '& .MuiTypography-root': {
                      whiteSpace: 'nowrap',
                      // fontSize: xsOnly ? '14px' : '16px'
                      fontSize: '16px',
                    },
                  },
                }}
              >
                <Box>
                  {getMarkerColor('', '#4DED6D', '#3CC457')}
                  <Typography>—New request</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#F5BD4F', '#D69E3C')}
                  <Typography>—Approaching the deadline</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#AC0000', '#B83629')}
                  <Typography>—Action required</Typography>
                </Box>
              </Box>
            </Box>
            <TextField
              id="search"
              label="Search"
              size="small"
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
              sx={{
                marginTop: !xsOnly && '33px',
                display: requestId ? 'none' : 'block',
                width: '100%',
                '& .MuiInputBase-root': {
                  width: xsOnly ? '100%' : '245px',
                },
                '& .MuiInputBase-input': {
                  width: xsOnly ? 'calc(100% - 24px)' : '205px',
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                },
                marginBottom: '30px',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} alt="" />
                  </InputAdornment>
                ),
              }}
            />

            <Box
              sx={{
                marginBottom: xsOnly ? 0 : '120px',
                '& .MuiPaper-root': {
                  marginBottom: '30px',
                },
                '& .MuiAccordionSummary-gutters': {
                  height: xsOnly ? '94px' : '69px',
                },
              }}
            >
              {getPaginatedData(filteredUserRequests, page, rowsPerPage).map((request) => {
                const {
                  id,
                  phoneNumber,
                  login,
                  email,
                  country,
                  companyName,
                  tradeLicense,
                  tradeLicenseNumber,
                  tradeLicenseExpiryDate,
                  taxRegistrationNumber,
                  firstName,
                  surname,
                  landlineNumber,
                  postcode,
                  addressLine1,
                  addressLine2,
                  approved,
                  disapproveMessage: message,
                  createdAt,
                  updatedAt,
                } = request

                const tradeLicenseId = getIdFromString(tradeLicense)

                return (
                  <Accordion
                    key={id}
                    expanded={expanded === `panel${id}`}
                    onChange={handleChange(`panel${id}`)}
                    style={{
                      '&:before': {
                        display: 'none',
                      },
                      boxShadow: 'none',
                      width: '100%',
                    }}
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        flexDirection: xsOnly ? 'column !important' : 'row',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ fontSize: '30px' }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{
                        border: '1px solid #DCDCDC',
                        borderRadius: '6px',
                        backgroundColor: '#F7FAFB',
                        flexDirection: 'row',
                        padding: xsOnly && '25px 17px 25px 30px',
                        // alignItems: 'center',
                        '& .MuiAccordionSummaryExpandIconWrapper.MuiExpanded': {
                          transform: 'rotate(90deg)',
                        },
                        '& .MuiAccordionSummary-content': {
                          // marginLeft: '8px',
                        },
                        '& .MuiTypographyRoot': {
                          whiteSpace: 'nowrap',
                          lineHeight: 'normal',
                          fontStyle: 'normal',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: '"Helvetica", sans-serif',
                          fontSize: '18px',
                          fontWeight: '700',
                        }}
                      >
                        {login}
                        <span style={{ fontSize: '16px', marginBottom: '2px' }}>
                          {' '}
                          &#40;{companyName}&#41;{' '}
                        </span>
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginLeft: !xsOnly && '16px',
                        }}
                      >
                        {approved ? null : getMarkerColor(createdAt)}

                        <Typography sx={{ paddingTop: '3.5px' }}>
                          {createdAt ?? getFormattedDate(createdAt)}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        padding: xsOnly ? '16px 0 5px' : '16px',
                      }}
                    >
                      <Stack
                        direction="row"
                        divider={
                          xsOnly ? (
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{
                                borderColor: colorsConfig.mainBlack,
                              }}
                            />
                          ) : null
                        }
                        spacing={2}
                        sx={{
                          width: '100%',
                          flexDirection: xsOnly ? 'column' : 'row',
                          '& .MuiBox-root': {
                            marginLeft: xsOnly ? 0 : '62px',
                            width: xsOnly ? '100%' : 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                          },
                          '& .MuiBox-root:first-of-type': {
                            marginLeft: !xsOnly && '14px',
                          },
                          '& .MuiDivider-root': {
                            marginLeft: '62px',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            paddingBottom: xsOnly && '20px',
                            borderBottom: xsOnly && `1px solid ${colorsConfig.mainBlack}`,
                          }}
                        >
                          <Typography>Username: {login}</Typography>
                          <Typography>First name: {firstName} </Typography>
                          <Typography>Last name: {surname}</Typography>
                          <Typography>Mobile number: {phoneNumber}</Typography>
                          <Typography>Landline number: {landlineNumber}</Typography>
                        </Box>
                        <Box sx={{ paddingTop: xsOnly && '20px' }}>
                          <Typography>Company name: {companyName}</Typography>
                          <Typography>Address 1: {addressLine1}</Typography>
                          <Typography>Address 2: {addressLine2}</Typography>
                          <Typography>ZIP/postcode: {postcode ?? '-'}</Typography>
                          <Typography>Email: {email}</Typography>
                        </Box>
                        <Box
                          sx={{
                            paddingTop: xsOnly && '15px',
                            '& .MuiBox-root': {
                              display: 'flex',
                              flexDirection: 'row',
                            },
                          }}
                        >
                          <Typography>Country: {country ?? '-'}</Typography>
                          <Typography>UID: {tradeLicenseNumber}</Typography>
                          <Typography>
                            VAT Nr.: {taxRegistrationNumber ? taxRegistrationNumber : '-'}
                          </Typography>
                          {filterApproved ? null : (
                            <Box
                              sx={
                                xsOnly && {
                                  display: 'flex',
                                  flexDirection: 'row !important',
                                  gap: '4px !important',
                                }
                              }
                            >
                              <Typography
                                sx={{
                                  marginLeft: !xsOnly && '-14px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                Trade license:
                              </Typography>
                              <IconButton
                                sx={xsOnly && { width: 'fit-content' }}
                                onClick={() => handleRequestOnGettingPdfLink(tradeLicenseId)}
                              >
                                <Typography sx={{ color: colorsConfig.mainBlue, fontWeight: 700 }}>
                                  Open PDF
                                </Typography>
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </Stack>
                      {!approved ? (
                        <Box
                          sx={{
                            position: 'relative',
                            height: xsOnly ? 'fit-content' : '57px',
                            margin: xsOnly ? '15px 0 112px 0' : '30px 205px 0 -16px',
                            display: 'flex',
                            flexDirection: xsOnly ? 'column-reverse' : 'row',
                            gap: xsOnly ? '20px' : '34px',
                            '& .MuiInputBase-root': {
                              padding: '4px 18px',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            },
                            '& .MuiInputBase-input': {
                              padding: '8px 0px 0px',
                            },
                          }}
                        >
                          <TextFieldSmall
                            fullWidth
                            label="Write the reason of rejection"
                            type="textarea"
                            multiline
                            rows={xsOnly ? 9 : 2}
                            value={message}
                            onChange={(e) => setDisapproveMessage(e.target.value)}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              position: xsOnly ? 'relative' : 'absolute',
                              top: xsOnly ? '' : 0,
                              right: xsOnly ? '' : '-221px',
                              width: xsOnly ? '100%' : '187px',
                              height: '48px',
                              background: colorsConfig.mainBlue,
                              borderRadius: '4px',
                              fontSize: '16px',
                              fontWeight: 700,
                              textTransform: 'none',
                              fontStyle: 'normal',
                              px: '60px',
                              py: '10px',
                            }}
                            onClick={() => {
                              handleRequestOnApprove(id)
                              toast.success('The request was approved!')
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            sx={{
                              position: 'absolute',
                              top: !xsOnly && 0,
                              bottom: xsOnly && '-122px',
                              right: 0,
                              color: colorsConfig.mainRed,
                              fontFamily: 'Helvetica',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                              textTransform: 'none',
                              textDecorationLine: 'underline',
                              padding: '14.5px 22px',
                            }}
                            onClick={() => {
                              if (disapproveMessage.trim() === '') {
                                toast.error('Please write the reason of rejection')
                              } else {
                                handleRequestOnReject(id)
                                toast.success('The request was rejected!')
                              }
                            }}
                          >
                            Reject
                          </Button>
                        </Box>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
              <Box
                sx={{
                  '& .MuiToolbar-root': {
                    paddingLeft: 0,
                    display: 'flex',
                    flexWrap: xsOnly ? 'wrap' : '',
                    gap: xsOnly ? '10px 0' : '',
                  },
                  '& .MuiTablePagination-spacer': {
                    display: 'none',
                  },
                  '& .MuiTablePagination-displayedRows': {
                    display: xsOnly ? 'none' : 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    fontSize: '12px',
                  },
                  '& .MuiInputBase-root': {
                    order: -1,
                    margin: 'auto 10px',
                    fontSize: '12px',
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                      marginRight: '10px',
                    },
                    background: 'white',
                    border: `1px solid ${colorsConfig.bgLightBlue}`,
                    borderRadius: '4px',
                    padding: '7px 15px 7px 13px',
                  },
                  '& .MuiPagination-root': {
                    marginLeft: xsOnly ? 'auto' : '12px',
                  },
                  '& .MuiPaginationItem-previousNext': {
                    padding: '8px 12px',
                    '& .MuiSvgIcon-root': {
                      display: 'none',
                    },
                    "&[aria-label='Go to previous page']": {
                      '&::before': {
                        content: "'Previous'",
                      },
                    },
                    "&[aria-label='Go to next page']": {
                      '&::before': {
                        content: "'Next'",
                      },
                    },
                    '&.Mui-disabled': {
                      opacity: 1,
                    },
                  },
                  '& .MuiPaginationItem-root': {
                    background: colorsConfig.bgLightBlue,
                    border: 'none',
                    fontSize: '12px',

                    '&.MuiPaginationItem-previousNext': {
                      display: xsOnly && 'none',
                      background: 'white',
                      border: `1px solid ${colorsConfig.bgLightBlue}`,
                    },
                  },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    background: colorsConfig.mainBlue,
                    color: 'white',
                    '&:hover': {
                      background: 'rgb(21, 101, 192)',
                    },
                  },
                  '& .MuiTablePagination-selectLabel': {
                    fontSize: '12px',
                  },
                  '& .MuiTablePagination-select': {
                    // paddingRight: '18px',
                  },
                  '& .MuiSelect-select': {
                    padding: 0,
                    paddingRight: '12px',
                    '&:focus': {
                      background: 'none',
                    },
                  },
                }}
              >
                <TablePagination
                  component="div"
                  count={filteredUserRequests.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={false}
                  showLastButton={false}
                  ActionsComponent={() => (
                    <>
                      <p
                        className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                        id=":rh:"
                        style={{
                          display: 'flex',
                          order: -2,
                        }}
                      >
                        Show
                      </p>
                      <Pagination
                        count={Math.ceil(filteredUserRequests.length / rowsPerPage)}
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(event, newPage) => handleChangePage(event, newPage)}
                      />
                    </>
                  )}
                  labelRowsPerPage="entries"
                  labelDisplayedRows={({ count }) => {
                    const from = (page - 1) * rowsPerPage + 1
                    const to = Math.min(page * rowsPerPage, filteredUserRequests.length)

                    if (!count) return `Show 0 entries`
                    return `Show ${from}-${to} of ${count} entries`
                  }}
                  sx={{
                    display: requestId ? 'none' : 'block',
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Container>
    </Box>
  )
}

export default OperatorSignUpRequests
