import React, { useEffect, useState } from 'react'
import { Box, Table, Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import { tableHeadCellsUsers } from '../../../config/tablesConfig'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import SimplePagination from '../../../ui/SimplePagination'
import { CheckRounded, ClearRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import ModalWrap from '../../../components/Modals/ModalWrap'
import TextH1 from '../../../ui/Text/TextH1'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import { toast } from 'react-toastify'
import ButtonExtraSmall from '../../../ui/Buttons/ButtonExtraSmall'
import SearchInput from '../../../components/Search/SearchInput'
import UITablePreloader from '../../../ui/UIPreloader/UITablePreloader'
import { formatDate } from '../../../utils/date.helpers'

const AdminPanelUsers = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [managers, setManagers] = useState([])
  const [searchLine, setSearchLine] = useState('')
  const [sort, setSort] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [manageUserModal, setManageUserModal] = useState(false)
  const [userToManage, setUserToManage] = useState(null)
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)

  const renderActions = (isBlocked, id, userName) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {isBlocked ? (
          <ButtonExtraSmall
            onClick={(e) => {
              e.stopPropagation()
              setUserToManage({ id, isBlocked, userName })
              setManageUserModal(true)
            }}
          >
            Activate
          </ButtonExtraSmall>
        ) : (
          <ButtonExtraSmall
            className={'danger'}
            onClick={(e) => {
              e.stopPropagation()
              setUserToManage({ id, isBlocked, userName })
              setManageUserModal(true)
            }}
          >
            Deactivate
          </ButtonExtraSmall>
        )}
        <ButtonExtraSmall
          onClick={(e) => {
            e.stopPropagation()
            setUserToManage({ id, isBlocked, userName })
            setResetPasswordModal(true)
          }}
        >
          Reset password
        </ButtonExtraSmall>
      </Box>
    )
  }

  const renderIsActive = (value) => {
    return (
      <Box>
        {value ? (
          <CheckRounded
            sx={{
              fill: colorsConfig.mainGreen,
              fontSize: '30px',
            }}
          />
        ) : (
          <ClearRounded
            sx={{
              fill: colorsConfig.darkRed,
              fontSize: '30px',
            }}
          />
        )}
      </Box>
    )
  }

  const handleSetManagers = (data) => {
    setManagers(
      data.map((item) => {
        return {
          id: item.id,
          link: `/admin/users/${item.id}`,
          cells: [
            {
              type: 'text',
              value: item.id,
            },
            {
              type: 'text',
              value: `${item.firstName} ${item.surname}`,
            },
            {
              type: 'text',
              value: item.company.name,
            },
            {
              type: 'component',
              value: renderIsActive(!item.user.isBlocked),
            },
            {
              type: 'text',
              value: formatDate(item?.createdAt),
            },
            {
              type: 'component',
              value: renderActions(
                item.user.isBlocked,
                item.user.id,
                `${item.firstName} ${item.surname}`,
              ),
            },
          ],
        }
      }),
    )
  }

  const handleBlockUser = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.blockUser(userToManage.id), {})
      .then(() => {
        toast.success(`User ${userToManage.userName} has been successfully deactivated`)
        getManagers()
        setManageUserModal(false)
        setUserToManage(null)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  const handleUnblockUser = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.unblockUser(userToManage.id), {})
      .then(() => {
        toast.success(`User ${userToManage.userName} has been successfully activated`)
        getManagers()
        setManageUserModal(false)
        setUserToManage(null)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  const handleResetUserPassword = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.resetUserPassword(userToManage.id), {})
      .then(() => {
        toast.success(`An email with a new password has been sent to ${userToManage.userName}`)
        getManagers()
        setResetPasswordModal(false)
        setUserToManage(null)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  const getManagers = () => {
    setLoading(true)
    privateRequest
      .get(API_ENDPOINTS.managers, {
        params: {
          pagination: false,
          managerExtendedSearch: searchLine,
          ...(sort ? { 'order[createdAt]': sort } : null),
        },
      })
      .then((response) => {
        handleSetManagers(response.data['hydra:member'])
        setCount(response.data['hydra:totalItems'])
        setLoading(false)
      })
  }

  useEffect(() => {
    getManagers()
  }, [searchLine, sort])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Users'))
  }, [])

  return (
    <Box>
      <BoxShadowed>
        <Stack gap="30px">
          <Stack direction="row" justifyContent="flex-end">
            <SearchInput value={searchLine} onChange={(e) => setSearchLine(e.target.value)} />
          </Stack>
          <UITablePreloader
            sx={{
              minHeight: '300px',
            }}
            loading={loading}
          >
            <Table>
              <CarTableHead tableCells={tableHeadCellsUsers} sort={sort} setSort={setSort} />
              <CarTableBody
                content={managers.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
              />
            </Table>
          </UITablePreloader>
        </Stack>
      </BoxShadowed>
      <SimplePagination
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <ModalWrap open={manageUserModal} setOpen={setManageUserModal}>
        <TextH1
          sx={{
            fontSize: '32px',
            mb: '25px',
          }}
        >
          Are you you want to {userToManage?.isBlocked ? 'activate' : 'deactivate'} <br />{' '}
          {userToManage?.userName}?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <ButtonHollow
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setManageUserModal(false)
              setUserToManage(null)
            }}
          >
            Cancel
          </ButtonHollow>
          {userToManage?.isBlocked ? (
            <ButtonSuccess
              disabled={confirmButtonDisabled}
              sx={{
                width: 'calc(50% - 5px)',
              }}
              onClick={() => {
                handleUnblockUser()
              }}
            >
              Activate
            </ButtonSuccess>
          ) : (
            <ButtonDanger
              disabled={confirmButtonDisabled}
              sx={{
                width: 'calc(50% - 5px)',
              }}
              onClick={() => {
                handleBlockUser()
              }}
            >
              Deactivate
            </ButtonDanger>
          )}
        </Box>
      </ModalWrap>
      <ModalWrap open={resetPasswordModal} setOpen={setResetPasswordModal}>
        <TextH1
          sx={{
            fontSize: '32px',
            mb: '25px',
          }}
        >
          Are you you want to reset <br /> {userToManage?.userName}'s password?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <ButtonHollow
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setManageUserModal(false)
              setUserToManage(null)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonSuccess
            disabled={confirmButtonDisabled}
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              handleResetUserPassword()
            }}
          >
            Confirm
          </ButtonSuccess>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default AdminPanelUsers
