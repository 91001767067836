import React from 'react'
import { FormControl, InputLabel } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'

const FormPhoneNumberBase = ({ value, onChange, label, ...props }) => {
  const { t } = useTranslation()
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel shrink htmlFor="phone-input">
        {t(label)}
      </InputLabel>
      <PhoneInput
        country="ch"
        value={value}
        onChange={onChange}
        containerStyle={{
          border: 'none',
        }}
        inputStyle={{
          border: 'none',
          height: '37.13px',
          width: '100%',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, .1)',
        }}
        buttonStyle={{
          background: 'white',
          border: 'none',
        }}
        {...props}
      />
    </FormControl>
  )
}

export default FormPhoneNumberBase
