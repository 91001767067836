import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './features/authSlice'
import AddNewCarSlice from './features/addNewCarSlice/addNewCar.reducer'
import WorkflowsSlice from './features/workflowsSlice/workflows.reducer'
import AppraisalReferences from './features/appraisalReferencesSlices'
import CarData from './features/carSlice/carSlice'
import qualityCheck from './features/qualityCheckSlice'
import financeSlice from './features/financeSlice'
import carFinanceSlice from './features/financeCar'
import insuranceSlice from './features/insuranceSlice'
import insuranceOperatorSlice from './features/insuranceOperatorSlice'
import coinPricingsReducer from './features/coinPricings/coinPricings.reducer'
import warrantyApplicationReducer from './features/warrantyApplicationSlice/warrantyApplication.reducer'
import warrantyProductsReducer from './features/warrantyProductsSlice/warrantyProducts.reducer'
import coveredComponentsReducer from './features/coveredComponentsSlice/coveredComponents.reducer'
import warrantyApplicationsReducer from './features/warrantyApplicationsSlice/warrantyApplications.reducer'
import rviOperatorReducer from './features/rviOperator/rviOperator.reducer'
import companiesReducer from './features/companiesSlice/companies.reducer'
import rviPolicyReducer from './features/rviPolicy/rviPolicy.reducer'
import rviDealerReducer from './features/rviDealer/rviDealer.reducer'
import companyAddressesReducer from './features/companyAddressesSlice/companyAddresses.reducer'
import rviCarReducer from './features/rviCarSlice/rviCar.reducer'
import referencesReducer from './features/references/references.reducer'

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    addCar: AddNewCarSlice,
    workflows: WorkflowsSlice,
    references: AppraisalReferences,
    car: CarData,
    qualityCheck: qualityCheck,
    finance: financeSlice,
    carFinance: carFinanceSlice,
    insurance: insuranceSlice,
    insuranceOperator: insuranceOperatorSlice,
    coinPricings: coinPricingsReducer,
    warrantyApplication: warrantyApplicationReducer,
    warrantyApplications: warrantyApplicationsReducer,
    warrantyProducts: warrantyProductsReducer,
    coveredComponents: coveredComponentsReducer,
    rviOperator: rviOperatorReducer,
    companies: companiesReducer,
    rviPolicy: rviPolicyReducer,
    rviDealer: rviDealerReducer,
    companyAddresses: companyAddressesReducer,
    addCarrviCar: rviCarReducer,
    ref: referencesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
