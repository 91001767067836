import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent'
import { Group } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import AdminPanelUserManagerInfo from './AdminPanelUserManagerInfo'
import AdminPanelUserCompanyInfo from './AdminPanelUserCompanyInfo'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'

const AdminPanelUser = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const [user, setUser] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [balance, setBalance] = useState(0)

  const getUser = () => {
    privateRequest.get(`/managers/${params.id}`).then((response) => {
      setUser(response.data)
      dispatch(setCurrentPageTitle(`${response.data?.firstName} ${response.data?.surname}`))
      if (response.data?.user?.avatar) {
        privateRequest.get(response.data?.user?.avatar?.avatar).then((avatarResponse) => {
          setAvatar(`${CDN_BASE_URL}${avatarResponse.data?.path}`)
        })
        privateRequest
          .get(`${API_ENDPOINTS.coins.transactions}?pagination=false`)
          .then((transactionsResponse) => {
            const transactions = transactionsResponse.data['hydra:member']
            const companyTransactions = transactions.filter(
              (item) => item.company === response.data.company['@id'],
            )
            let balance = 0
            companyTransactions.forEach((item) => {
              if (item.type === 'deposit') {
                balance += item.amount
                return
              }
              if (item.type === 'withdraw') {
                balance -= item.amount
                return
              }
            })
            setBalance(balance)
          })
      }
    })
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Box>
      {user ? (
        <>
          <Box
            sx={{
              background: colorsConfig.bgLightBlue,
              py: '30px',
              pl: '23px',
              pr: '23px',
              position: 'relative',
              left: '-23px',
              top: '-30px',
              width: 'calc(100% + 46px)',
            }}
          >
            <BreadcrumbsComponent
              currentPage={{ name: `${user?.firstName} ${user?.surname}` }}
              parentPages={[
                {
                  name: 'Users',
                  icon: <Group sx={{ fill: colorsConfig.lightGray, fontSize: '20px' }} />,
                  link: '/admin/users',
                },
              ]}
            />
            <AdminPanelUserManagerInfo
              user={user}
              avatar={avatar}
              balance={balance}
              refresh={getUser}
            />
          </Box>
          <AdminPanelUserCompanyInfo company={user?.company} />
        </>
      ) : null}
    </Box>
  )
}

export default AdminPanelUser
