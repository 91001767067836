import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import {
  setLoanApplications,
  createLoanApplication,
  updateWorkflowState,
  updateLoanDealsWorkflow,
  setBanks,
} from '../../../store/features/financeCar'

import { useParams } from 'react-router-dom'
import { adminRequest, privateRequest } from '../../../utils/request'
import {
  API_ENDPOINTS,
  API_FINANCE_ADMIN_ENDPOINTS,
  API_FINANCE_ENDPOINTS,
} from '../../../config/default'
import Preloader from '../../../components/Preloader'
import FinancingTabs from './FinancingTabs'
import LoanCalculator from './LoanCalculator'
import CustomerDataForm from './CustomerDataForm'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { colorsConfig } from '../../../config/themeConfig'
import IconCheckmark from '../../../assests/img/icons/icon-checkmark-black.svg'
import BankResponses from './BankResponses'
import SendToBanksModal from '../../../components/Modals/SendToBanksModal'
import { setBalance } from '../../../store/features/authSlice'

export const LoanApplicationWorkflowTypes = {
  new: 'new',
  applicationPreparation: 'application_preparation',
  approvalProcessStarted: 'approval_process_started',
}

const LoanApplications = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.carFinance.loanApplicationsLoading)
  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const currentWorkflowState = useSelector(
    (state) => state.carFinance?.loanApplications[selectedTab]?.currentWorkflowState,
  )
  const loanApplication = useSelector((state) => state.carFinance?.loanApplications[selectedTab])
  const sendToBanksButtonDisabled = useSelector(
    (state) => state.carFinance?.loanApplications[selectedTab]?.sendToBanksButtonDisabled,
  )

  const [sendToBanksModal, setSendToBanksModal] = useState(false)

  const getLoanApplications = () => {
    privateRequest
      .get(`${API_FINANCE_ENDPOINTS.loanApplications}&car=${params.id}`)
      .then((response) => {
        if (response.data['hydra:member'].length > 0) {
          Promise.all(
            response.data['hydra:member'].map((item) => privateRequest.get(item['@id'])),
          ).then((result) => {
            dispatch(setLoanApplications(result.map((item) => item.data)))
          })
        } else {
          dispatch(createLoanApplication())
        }
      })
  }

  const getBanks = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}?pagination=false&type=bank`)
      .then((response) => {
        dispatch(setBanks(response.data['hydra:member']))
      })
  }

  const handleSubmitToBanks = () => {
    privateRequest
      .post(
        API_FINANCE_ENDPOINTS.loanApplicationWorkflowTransitions.sendToBanks(loanApplication.id),
        {},
      )
      .then(() => {
        dispatch(updateWorkflowState(LoanApplicationWorkflowTypes.approvalProcessStarted))
        setSendToBanksModal(false)
        dispatch(updateLoanDealsWorkflow('bank_sent'))
        privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
          dispatch(setBalance(response.data.manager.company.coinBalance.balance))
        })
      })
  }

  useEffect(() => {
    getLoanApplications()
    getBanks()
  }, [])

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            minHeight: '400px',
            position: 'relative',
          }}
        >
          <Preloader type={'gooey'} text={'Loading finance applications...'} />
        </Box>
      ) : (
        <Box>
          <FinancingTabs />
          <LoanCalculator />
          <CustomerDataForm setSendToBanksModal={setSendToBanksModal} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <ButtonLG
              className={
                currentWorkflowState === LoanApplicationWorkflowTypes.approvalProcessStarted &&
                loanApplication?.loanDeals?.filter((item) => item?.currentWorkflowState === 'new')
                  ?.length !== 3
                  ? 'success'
                  : ''
              }
              sx={{
                minWidth: '266px',
                mt: '30px',
                '&.success': {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  background: colorsConfig.mainGreen,
                  color: colorsConfig.mainBlack,
                  pointerEvents: 'none',
                },
              }}
              disabled={sendToBanksButtonDisabled}
              onClick={() => {
                setSendToBanksModal(true)
              }}
            >
              {currentWorkflowState === LoanApplicationWorkflowTypes.approvalProcessStarted &&
              loanApplication?.loanDeals?.filter((item) => item?.currentWorkflowState === 'new')
                ?.length !== 3 ? (
                <img style={{ width: '22px' }} src={IconCheckmark} alt="" />
              ) : null}
              {currentWorkflowState === LoanApplicationWorkflowTypes.approvalProcessStarted &&
              loanApplication?.loanDeals?.filter((item) => item?.currentWorkflowState === 'new')
                ?.length !== 3
                ? 'Sent to banks'
                : 'Send to banks'}
            </ButtonLG>
          </Box>
          {loanApplication?.loanDeals && loanApplication?.loanDeals?.length > 0 ? (
            <BankResponses />
          ) : null}
        </Box>
      )}
      <SendToBanksModal
        open={sendToBanksModal}
        setOpen={setSendToBanksModal}
        handleAction={handleSubmitToBanks}
      />
    </Box>
  )
}

export default LoanApplications
