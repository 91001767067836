import React, { useEffect, useState } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import ColorRadioGroup from '../../../ui/ColorRadio/ColorRadioGroup'
import { colorTypes } from '../../../config/formsConfig'
import UITextLabelExtraLarge from '../../../ui/UIText/UITextLabelExtraLarge'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UICheckboxPlain from '../../../ui/UICheckbox/UICheckboxPlain'

const CarColorField = ({ title, value, setValue, ...props }) => {
  const isMobileSmall = useMediaQuery('(max-width: 420px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const [isColorCustom, setIsColorCustom] = useState(false)

  const handleSetValue = (val) => {
    setValue(val)
  }

  useEffect(() => {
    if (value) {
      const matchColor = colorTypes.find((item) => item.label === value)
      if (!matchColor && !isColorCustom) setIsColorCustom(true)
    }
  }, [value])

  return (
    <Stack gap="20px" {...props}>
      <UITextLabelExtraLarge fontWeight="700" text={title} />
      <Stack>
        <ColorRadioGroup
          itemsPerRow={isMobileSmall || tabletOnly ? 3 : 4}
          colors={colorTypes}
          value={value}
          setValue={handleSetValue}
          sx={{
            opacity: !isColorCustom ? 1 : 0.5,
          }}
        />
        <Stack gap="10px">
          <Stack direction="row" gap="10px" alignItems="center">
            <UICheckboxPlain value={isColorCustom} setValue={(val) => setIsColorCustom(val)} />
            <UITextRegular text="Custom colour" />
          </Stack>
          {isColorCustom && (
            <TextFieldSmallEdible
              fullWidth
              label=""
              value={isColorCustom ? value : ''}
              onChange={(e) => {
                handleSetValue(e.target.value)
              }}
              sx={{
                opacity: isColorCustom ? 1 : 0.5,
                width: '50%',
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CarColorField
