import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery, Button, Modal, IconButton, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import VehicleDetailsSection from './CarPageSections/VehicleDetailsSection'
import DocumentsSection from './CarPageSections/DocumentsSection'
import DamagesSection from './CarPageSections/DamagesSection'
import WarningLights from '../Appraisal/WarningLights/WarningLights'
import CarLoggingNodes from './CarLoggingNodes'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getCarParts,
  // getControlPoints,
  getForeshortenings,
  getPilotLamps,
} from '../Appraisal/getAppraisalReferences'
import {
  setReferenceControlPoints,
  setReferences,
} from '../../store/features/appraisalReferencesSlices'
import {
  resetCar,
  setAppraisal,
  setCarData,
  setControlPoints,
  setDamages,
  setDeletedDamages,
  setPhotos,
  setPilotLamps,
  setAppraisalTab,
  setCarAppraisalChecksResource,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
} from '../../store/features/carSlice/carSlice'
import {
  resetQualityCheck,
  setSubBlocks,
  setQualityCheckDamages,
  setQualityCheckPhotos,
  setHints,
} from '../../store/features/qualityCheckSlice'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { resetData } from '../../store/features/financeCar'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS, API_FINANCE_ENDPOINTS, HINT_TYPES } from '../../config/default'
import AppraisalBlock from '../Appraisal/AppraisalBlock'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import { colorsConfig } from '../../config/themeConfig'
import CarActionBlock from './CarActionBlock'
import PhotosSection from './CarPageSections/PhotosSection'
import QualityCheckCarSummary from './QualityCheck/QualityCheckCarSummary'
import AddComment from './QualityCheck/AddComment'
import QualityCheckHints from './QualityCheck/QualityCheckHints'
import Preloader from '../../components/Preloader'
import AppraisalOnCompoundBlock from './AppraisalOnCompound/AppraisalOnCompoundBlock'
import CollapseWrapper from './CollapseWrapper'
import CarPageTabs from './CarPageTabs'
import CarPageEditButton from './CarPageEditButton'
import { useTranslation } from 'react-i18next'
import CarPageSummary from './CarPageHeader/CarPageSummary'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from '../Appraisal/appraisal.helpers'
import AppraisalInteriorConditions from '../Appraisal/AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from '../Appraisal/AppraisalMechanicalConditions/AppraisalMechanicalConditions'
import UIButtonDownloadAppraisal from '../../ui/UIButtons/UIButtonDownloadAppraisal'

const CarPage = () => {
  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)

  const [loading, setLoading] = useState(true)
  const [blocks, setBlocks] = useState([])
  const [loggingTreeModal, setLoggingTreeModal] = useState(false)
  const [appraisalDate, setAppraisalDate] = useState('')
  const [serviceTab, setServiceTab] = useState(null)

  const carDetailsTabs = [
    {
      id: 1,
      name: 'Vehicle Details',
      component: <VehicleDetailsSection />,
      isCompleted: carAppraisalChecksResource.vehicleDetailsCheck,
    },
    {
      id: 2,
      name: 'Documents',
      component: <DocumentsSection />,
      isCompleted: carAppraisalChecksResource.documentsCheck,
    },
  ]

  const appraisalDetailsTabs = [
    {
      id: 1,
      name: 'Photos',
      component: <PhotosSection />,
      isCompleted: carAppraisalChecksResource.photosCheck,
    },
    {
      id: 2,
      name: 'Damages',
      component: <DamagesSection />,
      isCompleted: carAppraisalChecksResource.damagesCheck,
    },
    {
      id: 3,
      name: 'Warning Lights',
      component: <WarningLights readonly={true} hideGaps />,
      isCompleted: carAppraisalChecksResource.warningLightsCheck,
    },
    {
      id: 4,
      name: 'Interior Conditions',
      component: <AppraisalInteriorConditions readonly hideGaps />,
      isCompleted: carAppraisalChecksResource.interiorConditionsCheck,
    },
    {
      id: 5,
      name: 'Mechanical Conditions',
      component: <AppraisalMechanicalConditions readonly hideGaps />,
      isCompleted: carAppraisalChecksResource.mechanicalConditionsCheck,
    },
  ]

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [tab, setTab] = useState(0)
  const [carDetailsTab, setCarDetailsTab] = useState(0)
  const [appraisalDetailsTab, setAppraisalDetailsTab] = useState(0)
  const [pickupDemand, setPickupDemand] = useState(null)

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [financeStatus, setFinanceStatus] = useState('')
  const [currentStatusColor, setCurrentStatusColor] = useState('')
  const [currentWorkflowSysName, setCurrentWorkflowSysName] = useState('')
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const carData = useSelector((state) => state.car.carData)
  const hints = useSelector((state) => state.qualityCheck.hints)
  const appraisalReferences = useSelector((state) => state.references)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const role = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()

  const { id } = useParams()

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const handleArchive = () => {
    if (carData.isArchived) {
      privateRequest.put(`/cars/${id}/unarchive`, { isArchived: !carData.isArchived }).then(() => {
        getCar()
      })
    } else {
      privateRequest.put(`/cars/${id}/archive`, { isArchived: !carData.isArchived }).then(() => {
        getCar()
      })
    }
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const checkDeletedDamages = () => {
    const deletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
    if (deletedDamages && deletedDamages.length > 0) {
      dispatch(setDeletedDamages(deletedDamages))
      deletedDamages.forEach(async (item) => {
        await privateRequest.delete(`/cars/${item.carId}/damages/${item.id}`).then(() => {
          const oldDeletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
          if (oldDeletedDamages && oldDeletedDamages.length > 0) {
            window.localStorage.setItem(
              'deletedDamages',
              JSON.stringify(
                oldDeletedDamages.filter(
                  (damageItem) => damageItem.id !== item.id && damageItem.carId !== item.carId,
                ),
              ),
            )
          }
        })
      })
    }
  }

  const getPickupDemand = () => {
    privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
      setPickupDemand(response.data)
    })
  }

  const getHints = () => {
    privateRequest.get(`/cars/${id}${API_ENDPOINTS.qualityCheckHints}`).then((response) => {
      if (role === 'COMPANY') {
        const finalHints = []
        const activeHints = response.data['hydra:member'].filter((item) => item.isActive)
        const updatedHints = activeHints.map((item) => {
          switch (item['@type']) {
            case HINT_TYPES.vehicleDetails:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 0,
                fixed: item.isCompleted,
                direct: 0,
                linkText: 'Go to Vehicle Details',
              }
              break
            case HINT_TYPES.subBlocks:
              let subBlockTab
              let linkText
              if (item.subblock === '/reference/appraisal/subblocks/1') {
                subBlockTab = 1
                linkText = 'Go to Documents'
              } else if (item.subblock === '/reference/appraisal/subblocks/2') {
                subBlockTab = 5
                linkText = 'Go to Interior Conditions'
              } else {
                subBlockTab = 6
                linkText = 'Go to Mechanical Conditions'
              }
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: subBlockTab,
                fixed: item.isCompleted,
                direct: subBlockTab,
                linkText: linkText,
              }
              break
            case HINT_TYPES.warningLights:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 4,
                fixed: item.isCompleted,
                direct: 4,
                linkText: 'Go to Warning Lights',
              }
              break
            case HINT_TYPES.photos:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 2,
                fixed: item.isCompleted,
                direct: 2,
                linkText: 'Go to Photos',
              }
              break
            case HINT_TYPES.photo:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 2,
                fixed: item.isCompleted,
                direct: 2,
                linkText: 'Go to Photos',
              }
              break
            case HINT_TYPES.damages:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 3,
                fixed: item.isCompleted,
                direct: 3,
                linkText: 'Go to Damages',
              }
              break
            case HINT_TYPES.damage:
              return {
                ...item,
                navigate: `/cars/${id}/appraisal`,
                tab: 3,
                fixed: item.isCompleted,
                direct: 3,
                linkText: 'Go to Damages',
              }
              break
            default:
              return item
              break
          }
        })
        dispatch(setHints(updatedHints.sort(compare)))
      } else if (role === 'OPERATOR') {
        dispatch(setHints(response.data['hydra:member']))
      }
    })
  }

  const getLoanApplicationStatus = (loanApplication) => {
    if (loanApplication.currentWorkflowState === 'new') {
      return 'Loan calculation saved'
    }
    if (loanApplication.currentWorkflowState === 'application_preparation') {
      return 'Customer data saved'
    }
    if (loanApplication.currentWorkflowState === 'approval_process_started') {
      if (
        loanApplication.loanDeals.filter((item) => item.currentWorkflowState === 'bank_sent')
          .length === loanApplication.loanDeals.length
      ) {
        return 'Awaiting bank replies'
      }
      if (loanApplication.loanDeals.filter((item) => item.isCustomerSelected)[0]) {
        return 'Financed'
      }
      if (loanApplication.loanDeals.filter((item) => item.isDealerSelected)[0]) {
        return "Awaiting customer's approval"
      }
      if (
        loanApplication.loanDeals.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        )[0]
      ) {
        return 'Bank replies received'
      }
      return 'Awaiting bank replies'
    }
    return loanApplication.currentWorkflowState
  }

  const handleSetLatestFinanceStatus = (data) => {
    if (data.length === 0) {
      return
    }
    const currentStatuses = data.map((item) => getLoanApplicationStatus(item))
    if (currentStatuses.filter((item) => item === 'Financed')[0]) {
      return 'Financed'
    }
    if (currentStatuses.filter((item) => item === "Awaiting customer's approval")[0]) {
      return "Awaiting customer's approval"
    }
    if (currentStatuses.filter((item) => item === 'Bank replies received')[0]) {
      return 'Bank replies received'
    }
    if (currentStatuses.filter((item) => item === 'Awaiting bank replies')[0]) {
      return 'Awaiting bank replies'
    }
    if (currentStatuses.filter((item) => item === 'Customer data saved')[0]) {
      return 'Customer data saved'
    }
    if (currentStatuses.filter((item) => item === 'Loan calculation saved')[0]) {
      return 'Loan calculation saved'
    }
    return ''
  }

  const getLoanApplications = () => {
    privateRequest.get(`${API_FINANCE_ENDPOINTS.loanApplications}&car=${id}`).then((response) => {
      setFinanceStatus(handleSetLatestFinanceStatus(response.data['hydra:member']))
    })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0 && carData.loaded) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints, carData.loaded])

  const getControlPoints = () => {
    privateRequest
      .get(API_ENDPOINTS.controlPoints, {
        params: {
          pagination: false,
        },
      })
      .then((response) => {
        dispatch(setReferenceControlPoints(response.data['hydra:member']))
      })
  }

  useEffect(() => {
    checkDeletedDamages()
    getControlPoints()
    getCar()
    getLoanApplications()
    getCarAppraisal()
    getHints()
    return () => {
      dispatch(resetQualityCheck())
      dispatch(resetCar())
      dispatch(resetData())
    }
  }, [])

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded && carData.id?.toString() === id) {
      // setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      // dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))
      // dispatch(
      //   setSubBlocks(
      //     appraisalReferences.controlPoints[2].subblocks.map((item) => {
      //       return {
      //         '@id': item['@id'],
      //         hint: '',
      //         approved: true,
      //       }
      //     }),
      //   ),
      // )
      dispatch(
        setQualityCheckDamages(
          carData.damages.map((item) => {
            return {
              '@id': item['@id'],
              hint: '',
              approved: true,
            }
          }),
        ),
      )
      dispatch(
        setQualityCheckPhotos(
          carData.foreshortenings.map((item) => {
            return {
              '@id': item['@id'],
              hint: '',
              approved: true,
            }
          }),
        ),
      )
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
  }, [appraisalReferences.loaded, carData])

  useEffect(() => {
    if (car && workflowsLoaded && combinedStatus) {
      let findWorkflow
      if (combinedStatus.includes('car_workflow')) {
        findWorkflow = carWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('auction_workflow')) {
        findWorkflow = auctionWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('deal_workflow')) {
        findWorkflow = dealWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
        )[0]
        // getDeal()
      }

      if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
        getPickupDemand()
      }

      if (findWorkflow) {
        setCurrentWorkflow(findWorkflow.value)
        setCurrentStatusColor(`#${findWorkflow.color}`)
      }
    }
  }, [car, workflowsLoaded, combinedStatus])

  const getFormattedDate = (date) => {
    if (!date) return null
    const endTimeDate = new Date(date)
    const endYear = endTimeDate.getFullYear()
    let endMonth = endTimeDate.getMonth() + 1
    let endDay = endTimeDate.getDate()
    if (endDay < 10) endDay = '0' + endDay
    if (endMonth < 10) endMonth = '0' + endMonth
    const formattedEndDate = endDay + '.' + endMonth + '.' + endYear
    return formattedEndDate
  }

  const getCar = () => {
    privateRequest.get(API_ENDPOINTS.carId(id)).then((response) => {
      setCar(response.data)
      dispatch(setCarData(response.data))
      setCarTitle(`${response.data.make.name} ${response.data.model.name}`)
      dispatch(setCurrentPageTitle(`${response.data.make.name} ${response.data.model.name}`))
      setCurrentWorkflowSysName(response.data.currentWorkflowState)
      if (response.data.combinedStatus === 'car_workflow.new') {
        dispatch(setAppraisalTab(0))
      } else {
        dispatch(setAppraisalTab(1))
      }
      if (
        response.data.combinedStatus === 'car_workflow.ready_for_sale' ||
        response.data.combinedStatus.includes('auction_workflow') ||
        response.data.combinedStatus.includes('deal_workflow')
      ) {
        setAppraisalDate(getFormattedDate(response.data.lastAppraisalDoneAt))
      }
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(id)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
      if (response.data.appraisalChecksResource) {
        dispatch(setCarAppraisalChecksResource(response.data.appraisalChecksResource))
      }
    })
  }

  const setControlPointsBlocks = () => {
    const newBlocks = appraisalReferences.controlPoints.map((blockItem, index) => {
      return {
        id: index === 0 ? 3 : index + 5,
        title: blockItem.name,
        component: (
          <AppraisalBlock key={index === 0 ? 3 : index + 5} blockIndex={index} readonly={true} />
        ),
      }
    })
    setBlocks(newBlocks)
  }

  return (
    <Box
      className={tabletOnly ? 'tablet-only' : ''}
      sx={{
        height: loading ? 'calc(100vh - 102px)' : 'auto',
        position: 'relative',
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={`${t('p.loading_the_car')}...`} />
      ) : (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              px: '16px',
              maxWidth: '100vw',
              pb: '30px',
            },
          }}
        >
          <Box
            sx={{
              pt: {
                xs: '20px',
                sm: '30px',
              },
            }}
          >
            <BreadcrumbsComponent
              currentPage={{ name: carTitle }}
              parentPages={[
                {
                  name: t('page_title_virtual_garage'),
                  icon: <img src={IconVirtualGarage} alt="" />,
                  link: '/virtual-garage',
                },
              ]}
            />
          </Box>
          {role === 'COMPANY' || role === 'EXPERT' ? (
            <CarPageSummary
              financeStatus={financeStatus}
              currentWorkflow={currentWorkflow}
              setLoggingTreeModal={setLoggingTreeModal}
              appraisalDate={appraisalDate}
            />
          ) : null}
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                background: colorsConfig.bgLightBlue,
                py: '18px',
                px: '16px',
                mx: '-16px',
                position: 'relative',
                mt: '20px',
              },
            }}
          >
            {role === 'COMPANY' ? (
              <CarActionBlock activeTab={serviceTab} setActiveTab={setServiceTab} />
            ) : null}
          </Box>
          {role === 'OPERATOR' &&
          (combinedStatus === 'car_workflow.requested_appraisal_compound' ||
            combinedStatus === 'car_workflow.awaiting_appraisal_compound') ? (
            <Box
              sx={{
                background: colorsConfig.bgLightBlue,
                padding: '0 100px 60px',
              }}
            >
              <AppraisalOnCompoundBlock />
            </Box>
          ) : null}

          {role === 'OPERATOR' && combinedStatus === 'car_workflow.quality_check' ? (
            <QualityCheckCarSummary />
          ) : null}
          {role === 'COMPANY' &&
          hints.length > 0 &&
          (combinedStatus === 'car_workflow.moderation_failed' ||
            combinedStatus === 'car_workflow.appraisal_on_site') &&
          !carData.isArchived &&
          serviceTab !== 2 ? (
            <QualityCheckHints hints={hints} />
          ) : null}
          {role === 'OPERATOR' &&
          hints.length > 0 &&
          combinedStatus === 'car_workflow.quality_check' ? (
            <QualityCheckHints hints={hints} />
          ) : null}
          <Box
            sx={{
              mt: '30px',
            }}
          >
            <CarPageEditButton />
            <Stack gap="30px">
              <CollapseWrapper title="Car details" background={colorsConfig.white} borderWrap>
                {combinedStatus === 'car_workflow.new' ? null : (
                  <CarPageTabs
                    tab={carDetailsTab}
                    setTab={setCarDetailsTab}
                    sections={carDetailsTabs}
                  />
                )}
                <Box sx={{ overflow: 'hidden' }}>{carDetailsTabs[carDetailsTab].component}</Box>
                {role === 'OPERATOR' && combinedStatus === 'car_workflow.quality_check' ? (
                  <AddComment tab={tab} setTab={setTab} />
                ) : null}
              </CollapseWrapper>
              <CollapseWrapper
                title="Appraisal details"
                background={colorsConfig.white}
                borderWrap
                addons={<UIButtonDownloadAppraisal />}
              >
                {combinedStatus === 'car_workflow.new' ? null : (
                  <CarPageTabs
                    tab={appraisalDetailsTab}
                    setTab={setAppraisalDetailsTab}
                    sections={appraisalDetailsTabs}
                  />
                )}
                <Box sx={{ overflow: 'hidden' }}>
                  {appraisalDetailsTabs[appraisalDetailsTab].component}
                </Box>

                {role === 'OPERATOR' && combinedStatus === 'car_workflow.quality_check' ? (
                  <AddComment tab={tab} setTab={setTab} />
                ) : null}
              </CollapseWrapper>
            </Stack>
          </Box>
          {role === 'COMPANY' &&
          (combinedStatus === 'car_workflow.new' ||
            combinedStatus === 'car_workflow.appraisal_on_site' ||
            combinedStatus === 'car_workflow.moderation_failed' ||
            combinedStatus === 'car_workflow.ready_for_sale' ||
            combinedStatus === 'car_workflow.sale_succeed') ? (
            <Box
              sx={{
                mt: '15px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  color: !carData.isArchived ? colorsConfig.mainRed : colorsConfig.mainGreen,
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
                onClick={() => {
                  handleArchive()
                }}
              >
                {!carData.isArchived ? 'Archive' : 'Restore in virtual garage'}
              </Button>
              {/*<Button*/}
              {/*    sx={{*/}
              {/*        display: 'flex',*/}
              {/*        flexDirection: 'row',*/}
              {/*        justifyContent: 'center',*/}
              {/*        alignItems: 'center',*/}
              {/*        textTransform: 'none'*/}
              {/*    }}*/}
              {/*>*/}
              {/*    <span*/}
              {/*        style={{*/}
              {/*            fontFamily: '"HelveticaNeue", sans-serif',*/}
              {/*            fontWeight: 700,*/}
              {/*            fontSize: '16px',*/}
              {/*            color: colorsConfig.mainBlue,*/}
              {/*            marginRight: '8px'*/}
              {/*        }}*/}
              {/*    >*/}
              {/*        Download appraisal as PDF*/}
              {/*    </span>*/}
              {/*    <img src={IconDownload} alt="" />*/}
              {/*</Button>*/}
            </Box>
          ) : null}
          <Modal
            open={loggingTreeModal}
            onClose={() => {
              setLoggingTreeModal(false)
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 870,
                background: 'white',
                borderRadius: '10px',
                padding: '55px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100% - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
            >
              <CarLoggingNodes />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setLoggingTreeModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
        </Box>
      )}
    </Box>
  )
}

export default CarPage
