export const localStorageKeys = {
  workflowsStates: 'workflowsStates',
  token: 'token',
  refreshToken: 'refresh_token',
  mercureToken: 'mercure_token',
}

export const getLocalWorkflowsState = () =>
  JSON.parse(localStorage.getItem(localStorageKeys.workflowsStates))

export const setLocalWorkflowsState = (data, key) => {
  const currentLocalStorage = getLocalWorkflowsState()
  if (currentLocalStorage) {
    currentLocalStorage[key] = data
    localStorage.setItem(localStorageKeys.workflowsStates, JSON.stringify(currentLocalStorage))
  } else {
    const newLocalStorage = {
      version: '1',
      [key]: data,
    }
    localStorage.setItem(localStorageKeys.workflowsStates, JSON.stringify(newLocalStorage))
  }
}

export const getToken = (key) => {
  return localStorage.getItem(key)
}

export const setToken = (key, value) => {
  return localStorage.setItem(key, value)
}

export const removeToken = (key) => {
  localStorage.removeItem(key)
}
