import React, { useEffect, useState } from 'react'
import { Box, Button, InputAdornment, Table, TextField, useMediaQuery } from '@mui/material'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { useDispatch } from 'react-redux'
import GreenBanner from '../../assests/img/banners/banner-green-2.png'
import BannerMobileApple from '../../assests/img/banners/banner-mobile-apple.png'
import TextH1 from '../../ui/Text/TextH1'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import TextNormal from '../../ui/Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import { AddRounded } from '@mui/icons-material'
import CarTableHead from '../VirtualGarage/CarTableHead'
import CarTableBody from '../VirtualGarage/CarTableBody'
import { tableHeadCellsFinancing } from '../../config/tablesConfig'
import { privateRequest } from '../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../config/default'
import Preloader from '../../components/Preloader'
import FinanceCarModal from './FinanceCarModal'
import FinancingTableTabs from './FinancingTableTabs'
import FilterByStatus from '../../components/FilterByStatus'
import { useNavigate } from 'react-router-dom'
import UIButtonAdd from '../../ui/Buttons/UIButtonAdd'

const loanApplicationStatuses = [
  'Loan calculation saved',
  'Customer data saved',
  'Awaiting bank replies',
  'Bank replies received',
  "Awaiting customer's approval",
]

const Financing = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [activeLoanApplications, setActiveLoanApplications] = useState([])
  const [filteredLoanApplications, setFilteredLoanApplications] = useState([])
  const [closedLoanApplications, setClosedLoanApplications] = useState([])
  const [searchLine, setSearchLine] = useState('')
  const [financeCarModal, setFinanceCarModal] = useState(false)
  const [tab, setTab] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(null)

  const sections = [
    {
      title: 'Finance in progress',
      count: activeLoanApplications.length,
    },
    {
      title: 'Financed',
      count: closedLoanApplications.length,
    },
  ]

  const handleFilter = (status) => {
    setCurrentFilter(status)
    if (!status || status === 'All') {
      setFilteredLoanApplications(activeLoanApplications)
      return
    }
    setFilteredLoanApplications(
      activeLoanApplications.filter((item) => item.cells[3].value === status),
    )
  }

  const renderBankReplies = (loanDeals) => {
    const acceptedRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'approved',
    ).length
    const bankRequestRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'bank_request_info',
    ).length
    const rejectedRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'bank_declined',
    ).length

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '14px',
        }}
      >
        <Box
          className={'success'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{acceptedRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Accepted
          </TextNormal>
        </Box>
        <Box
          className={'attention'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{bankRequestRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              whiteSpace: 'nowrap',
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Bank request
          </TextNormal>
        </Box>
        <Box
          className={'danger'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{rejectedRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Refused
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const getLoanApplicationStatus = (loanApplication) => {
    if (loanApplication.currentWorkflowState === 'new') {
      return 'Loan calculation saved'
    }
    if (loanApplication.currentWorkflowState === 'application_preparation') {
      return 'Customer data saved'
    }
    if (loanApplication.currentWorkflowState === 'approval_process_started') {
      if (
        loanApplication.loanDeals.filter((item) => item.currentWorkflowState === 'bank_sent')
          .length === loanApplication.loanDeals.length
      ) {
        return 'Awaiting bank replies'
      }
      if (loanApplication.loanDeals.filter((item) => item.isDealerSelected)[0]) {
        return "Awaiting customer's approval"
      }
      if (
        loanApplication.loanDeals.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        )[0]
      ) {
        return 'Bank replies received'
      }
    }
    if (
      loanApplication.loanDeals.find((item) => item.isCustomerSelected) ||
      loanApplication.currentWorkflowState === 'customer_selected'
    ) {
      return 'Financed'
    }
    return loanApplication.currentWorkflowState
  }

  const handleSetLoanApplications = (data) => {
    const filteredActiveLoanApplications = data.filter(
      (item) => getLoanApplicationStatus(item) !== 'Financed',
    )
    const filteredClosedLoanApplications = data.filter(
      (item) => getLoanApplicationStatus(item) === 'Financed',
    )
    setActiveLoanApplications(
      filteredActiveLoanApplications.map((item) => {
        return {
          id: item.id,
          link: `/cars/${item.car.id}?service=Finance`,
          cells: [
            {
              type: 'text',
              value: item.id,
            },
            {
              type: 'text-with-caption',
              value: `${item.car.make.name} ${item.car.model.name}`,
              caption: `VIN ${item.car.vin}`,
            },
            {
              type: 'text',
              value:
                item?.customer?.person?.firstName || item?.customer?.person?.lastName
                  ? `${item?.customer?.person?.firstName} ${item?.customer?.person?.lastName}`
                  : `Customer #${item.customer.id}`,
            },
            {
              type: 'text',
              value: getLoanApplicationStatus(item),
            },
            {
              type: 'component',
              value: renderBankReplies(item.loanDeals),
            },
          ],
        }
      }),
    )
    setFilteredLoanApplications(
      filteredActiveLoanApplications
        .map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.car.id}?service=Finance`,
            cells: [
              {
                type: 'text',
                value: item.id,
              },
              {
                type: 'text-with-caption',
                value: `${item.car.make.name} ${item.car.model.name}`,
                caption: `VIN ${item.car.vin}`,
              },
              {
                type: 'text',
                value:
                  item?.customer?.person?.firstName || item?.customer?.person?.lastName
                    ? `${item?.customer?.person?.firstName} ${item?.customer?.person?.lastName}`
                    : `Customer #${item.customer.id}`,
              },
              {
                type: 'text',
                value: getLoanApplicationStatus(item),
              },
              {
                type: 'component',
                value: renderBankReplies(item.loanDeals),
              },
            ],
          }
        })
        .filter((item) => item.cells[3].value === currentFilter || !currentFilter),
    )
    setClosedLoanApplications(
      filteredClosedLoanApplications.map((item) => {
        return {
          id: item.id,
          link: `/cars/${item.car.id}?service=Finance`,
          cells: [
            {
              type: 'text',
              value: item.id,
            },
            {
              type: 'text-with-caption',
              value: `${item.car.make.name} ${item.car.model.name}`,
              caption: `VIN ${item.car.vin}`,
            },
            {
              type: 'text',
              value:
                item?.customer?.person?.firstName || item?.customer?.person?.lastName
                  ? `${item?.customer?.person?.firstName} ${item?.customer?.person?.lastName}`
                  : `Customer #${item.customer.id}`,
            },
            {
              type: 'text',
              value: getLoanApplicationStatus(item),
            },
            {
              type: 'component',
              value: renderBankReplies(item.loanDeals),
            },
          ],
        }
      }),
    )
  }

  const getLoanApplications = () => {
    setLoading(true)
    let link = API_FINANCE_ENDPOINTS.loanApplications
    if (searchLine) {
      link = `${link}&loanApplicationExtendedSearch=${searchLine}`
    }
    privateRequest
      .get(link)
      .then((response) => {
        handleSetLoanApplications(response.data['hydra:member'])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Financing'))
    getLoanApplications()
  }, [searchLine])

  useEffect(() => {
    if (tab !== 0) handleFilter(null)
  }, [tab])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        '&.xs-only': {
          px: '16px',
          pb: '30px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {!xsOnly ? (
        <Box
          sx={{
            height: '200px',
            backgroundImage: `url(${GreenBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'cover',
            backgroundSize: 'cover',
            py: '15px',
            pt: '10px',
            px: '30px',
            borderRadius: '4px',
            overflow: 'hidden',
            mb: '30px',
          }}
        >
          <TextH1
            sx={{
              marginRight: '10px',
              lineHeight: 'normal',
              mb: '5px',
              '&.xs-only': {
                mr: 0,
                px: '20px',
                mt: '30px',
                textAlign: 'center',
                mb: '21px',
              },
            }}
          >
            Helping the world getting greener
          </TextH1>
          <TextNormal
            sx={{
              mb: '20px',
            }}
          >
            We are dedicated to promoting eco-friendly cars and raising awareness about the <br />{' '}
            importance of green transportation
          </TextNormal>
          <ButtonLG
            onClick={() => {
              navigate('/go-greener')
            }}
          >
            Learn more
          </ButtonLG>
        </Box>
      ) : null}
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          my: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
          '&.xs-only': {
            mt: 0,
            mb: '15px',
          },
        }}
      >
        <UIButtonAdd onClick={() => setFinanceCarModal(true)}>Finance a car</UIButtonAdd>
      </Box>
      <Box
        className={[tabletOnly ? 'tablet-only' : '', xsOnly ? 'xs-only' : ''].join(' ')}
        sx={{
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          borderRadius: '6px',
          background: 'white',
          overflow: 'hidden',
          pb: '30px',
          px: '30px',
          '&.tablet-only': {
            px: 0,
            boxShadow: 'none',
            borderRadius: 0,
          },
          '&.xs-only': {
            px: 0,
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      >
        <Box
          className={[tabletOnly ? 'tablet-only' : '', xsOnly ? 'xs-only' : ''].join(' ')}
          sx={{
            pt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: '10px',
            '&.tablet-only': {
              display: 'block',
              pt: 0,
            },
            '&.xs-only': {
              display: 'block',
              pt: 0,
            },
          }}
        >
          <FinancingTableTabs tab={tab} setTab={setTab} sections={sections} />
          <Box
            className={[tabletOnly ? 'tablet-only' : '', xsOnly ? 'xs-only' : ''].join(' ')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              '&.tablet-only': {
                justifyContent: 'space-between',
                mt: '20px',
              },
              '&.xs-only': {
                mt: '15px',
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  width: 'calc(50% - 5px)',
                },
              }}
            >
              <TextField
                className={xsOnly ? 'xs-only' : ''}
                id="search"
                label="Search"
                size="small"
                value={searchLine}
                onChange={(e) => {
                  setSearchLine(e.target.value)
                }}
                sx={{
                  position: 'relative',
                  mb: '-2px',
                  '&.xs-only': {
                    width: '100% !important',
                    '& .MuiInputBase-input': {
                      width: '100% !important',
                    },
                  },
                  '& .MuiInputBase-input': {
                    width: '205px',
                    height: '38px',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {tab === 0 ? (
              <FilterByStatus
                filters={loanApplicationStatuses}
                handleFilter={handleFilter}
                currentFilter={currentFilter}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'relative',
            minHeight: '300px',
            '&.xs-only': {
              overflowX: 'auto',
              maxWidth: 'calc(100vw - 32px)',
            },
          }}
        >
          {loading ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'white',
                zIndex: 2,
                opacity: 0.5,
              }}
            >
              <Preloader type={'gooey'} text={'Loading financing applications...'} />
            </Box>
          ) : null}
          <Table
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                minWidth: '900px',
              },
            }}
          >
            <CarTableHead
              tableCells={tableHeadCellsFinancing}
              disableColumns={tabletOnly ? [1] : []}
            />
            {tab === 0 ? (
              <CarTableBody
                content={filteredLoanApplications}
                disableColumns={tabletOnly ? [1] : []}
              />
            ) : null}
            {tab === 1 ? (
              <CarTableBody
                content={closedLoanApplications}
                disableColumns={tabletOnly ? [1] : []}
              />
            ) : null}
          </Table>
        </Box>
      </Box>
      {xsOnly ? (
        <Box
          sx={{
            height: '268px',
            backgroundImage: `url(${BannerMobileApple})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'cover',
            backgroundSize: 'cover',
            py: '22px',
            px: '16px',
            borderRadius: '6px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextH1
            sx={{
              lineHeight: 'normal',
              mb: '12px',
              fontSize: '32px',
              textAlign: 'center',
            }}
          >
            Helping the world getting greener
          </TextH1>
          <TextNormal
            sx={{
              mb: '36px',
              lineHeight: 'normal',
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            We are dedicated to promoting eco-friendly cars and raising awareness about the <br />{' '}
            importance of green transportation
          </TextNormal>
          <ButtonLG>Learn more</ButtonLG>
        </Box>
      ) : null}
      <FinanceCarModal open={financeCarModal} setOpen={setFinanceCarModal} />
    </Box>
  )
}

export default Financing
