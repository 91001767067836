import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import AppleBackground from '../../../assests/img/dealer-dashboard-apple-bg.png'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useNavigate } from 'react-router-dom'
import ButtonLG from '../../../ui/Buttons/ButtonLG'

const { mainBlack } = colorsConfig

export const DealerCO2Compensation = ({ title }) => {
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()

  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '180px',
            margin: 0,
            padding: '23px 36px',
            background: 'linear-gradient(192deg, #F5F5F5 0%, #F4FFF2 100%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '7px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                width: 'fit-content',
                color: mainBlack,
                fontSize: '40px',
                fontWeight: 700,
                lineHeight: 'normal',
                marginBottom: '5px',
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '4px',
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '15px',
                  left: '5px',
                  textTransform: 'uppercase',
                  fontSize: '7.5px',
                  fontWeight: 700,
                }}
              >
                ECO
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
              >
                <path
                  d="M17.9137 28.14C16.7721 28.2331 15.629 27.9805 14.633 27.4149C14.4201 27.2623 14.1647 27.1802 13.9027 27.1802C13.6407 27.1802 13.3853 27.2623 13.1724 27.4149C12.1792 27.9808 11.0383 28.2336 9.89897 28.14C5.63463 28.14 -2.94864 15.5994 1.99346 8.02242C5.16493 3.16375 9.15529 3.65302 11.6171 4.63361C13.0831 5.2195 14.7182 5.2195 16.1842 4.63361C18.646 3.65302 22.6364 3.16375 25.8078 8.02242C30.7603 15.5994 22.1667 28.14 17.9137 28.14Z"
                  fill="#00DD7B"
                />
                <path
                  d="M17.9135 28.657C16.6667 28.7454 15.4217 28.4607 14.3372 27.8392C14.2115 27.7467 14.0595 27.6967 13.9034 27.6963C13.7473 27.696 13.5951 27.7453 13.4689 27.8371C9.1469 30.233 5.47586 27.318 2.33117 21.7249C-0.478764 16.5387 -0.765113 11.3113 1.56276 7.74322C5.01647 2.44884 9.46312 3.22137 11.8075 4.15664C13.1508 4.69363 14.6492 4.69363 15.9925 4.15664C18.3389 3.22446 22.7825 2.44987 26.2393 7.74219C28.5682 11.3071 28.2849 16.5315 25.4802 21.7156C23.3037 25.7379 20.1209 28.657 17.9135 28.657ZM13.9005 26.666C14.269 26.6671 14.6281 26.7823 14.9285 26.9956C15.8383 27.4989 16.8778 27.7188 17.9135 27.627C19.7058 27.627 22.6311 24.815 24.5737 21.2253C27.1962 16.379 27.4959 11.5492 25.3761 8.30561C22.4735 3.85896 18.8797 4.11543 16.3736 5.11251C14.7858 5.74802 13.0142 5.74802 11.4264 5.11251C8.92339 4.11543 5.32651 3.85999 2.4249 8.30561C0.307152 11.5523 0.611012 16.3852 3.23657 21.2336C5.21114 24.8789 8.07464 27.627 9.8978 27.627C10.9309 27.7186 11.9677 27.4987 12.8746 26.9956C13.1741 26.782 13.5326 26.6668 13.9005 26.666Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M13.9034 8.55122C12.6273 8.50532 11.4063 8.0195 10.4476 7.17613C10.3947 7.13386 10.3507 7.08158 10.318 7.02229C10.2853 6.963 10.2646 6.89785 10.2571 6.83056C10.2496 6.76327 10.2554 6.69516 10.2742 6.63012C10.293 6.56508 10.3245 6.50439 10.3667 6.4515C10.4521 6.34468 10.5764 6.27616 10.7123 6.26099C10.7796 6.25349 10.8477 6.2593 10.9127 6.27812C10.9778 6.29693 11.0385 6.32837 11.0914 6.37064C11.8462 7.09919 12.8543 7.50635 13.9034 7.50635C14.9524 7.50635 15.9605 7.09919 16.7153 6.37064C16.8222 6.28527 16.9585 6.24583 17.0944 6.26099C17.2303 6.27616 17.3546 6.34468 17.44 6.4515C17.5253 6.55831 17.5648 6.69467 17.5496 6.83056C17.5345 6.96645 17.4659 7.09076 17.3591 7.17613C16.4004 8.0195 15.1794 8.50532 13.9034 8.55122Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M13.8883 8.54261C13.8143 8.54268 13.741 8.52922 13.6733 8.5031C13.6056 8.47699 13.5449 8.4388 13.4952 8.391C13.4455 8.3432 13.4078 8.28685 13.3847 8.2256C13.3615 8.16434 13.3534 8.09954 13.3608 8.03538C13.4839 6.6334 13.3379 5.22335 12.9286 3.86317C12.6095 2.68905 11.8209 1.6521 10.7039 0.937823C10.6459 0.904326 10.5959 0.861184 10.557 0.810867C10.5181 0.760549 10.491 0.704046 10.4773 0.644593C10.4635 0.585139 10.4633 0.523904 10.4768 0.464393C10.4902 0.404883 10.517 0.348267 10.5556 0.297788C11.4105 -0.566351 13.2729 1.90894 13.578 2.69377C14.5451 4.33167 14.5208 8.15066 14.4138 8.12761C14.4009 8.24119 14.34 8.34656 14.2429 8.42325C14.1458 8.49994 14.0195 8.54248 13.8883 8.54261Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M17.5633 3.50051C17.1027 3.84994 16.5363 4.10687 15.9145 4.24848C15.2928 4.39008 14.6349 4.41198 13.9994 4.31221C12.6109 2.23253 15.8648 -0.0748971 18.5687 0.605983C18.7474 1.1136 18.7485 1.64825 18.5719 2.1563C18.3952 2.66434 18.0481 3.12792 17.5633 3.50051Z"
                  fill="#8CEA79"
                />
                <path
                  d="M14.7836 4.76935C14.3561 4.73782 13.6732 4.81505 13.5022 4.42575C13.4168 4.12787 13.1912 3.84259 13.1211 3.53761C13.1182 3.01524 13.2724 2.49936 13.5718 2.02987C13.8711 1.56038 14.3076 1.14986 14.8475 0.830055C15.3874 0.510255 16.0161 0.289747 16.6852 0.185586C17.3543 0.081424 18.0457 0.0964 18.706 0.229356C18.7926 0.247646 18.8714 0.282943 18.9346 0.331742C18.9979 0.380541 19.0433 0.441145 19.0665 0.507539C19.267 1.08209 19.2672 1.6868 19.0671 2.26143C18.8671 2.83606 18.4737 3.36057 17.9262 3.7827C17.0896 4.41564 15.9602 4.77024 14.7836 4.76935ZM14.3943 3.96474C14.9188 4.00934 15.4497 3.96023 15.9452 3.82128C16.4407 3.68232 16.8873 3.45731 17.2499 3.16393C17.6125 2.87056 17.8812 2.51682 18.0348 2.13055C18.1884 1.74427 18.2229 1.33599 18.1353 0.937818C16.0269 0.560339 13.4312 2.32164 14.3943 3.96395V3.96474Z"
                  fill="#3D3D3D"
                />
              </svg>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              mb: '10px',
            }}
          >
            You are better than 95% of dealers
          </Typography>
          <ButtonLG
            onClick={() => {
              navigate('/go-greener')
            }}
          >
            Learn more
          </ButtonLG>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-3px',
              right: 0,
            }}
          >
            <img src={AppleBackground} alt="apple-bg" style={{ mixBlendMode: 'darken' }} />
          </Box>
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'relative',
            width: '100%',
            height: '170px',
            margin: 0,
            padding: '23px 36px',
            background: 'linear-gradient(192deg, #F5F5F5 0%, #F4FFF2 100%)',
            '&.xs-only': {
              height: '232px',
              px: '9px',
              pt: '32px',
              pr: '20px',
              overflow: 'hidden',
              borderRadius: '6px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '7px',
            }}
          >
            <Typography
              className={xsOnly ? 'xs-only' : ''}
              variant="body1"
              sx={{
                width: 'fit-content',
                color: mainBlack,
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: 'normal',
                marginBottom: '7px',
                '&.xs-only': {
                  fontSize: '24px',
                },
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '4px',
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '15px',
                  left: '5px',
                  textTransform: 'uppercase',
                  fontSize: '7.5px',
                  fontWeight: 700,
                }}
              >
                ECO
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
              >
                <path
                  d="M17.9137 28.14C16.7721 28.2331 15.629 27.9805 14.633 27.4149C14.4201 27.2623 14.1647 27.1802 13.9027 27.1802C13.6407 27.1802 13.3853 27.2623 13.1724 27.4149C12.1792 27.9808 11.0383 28.2336 9.89897 28.14C5.63463 28.14 -2.94864 15.5994 1.99346 8.02242C5.16493 3.16375 9.15529 3.65302 11.6171 4.63361C13.0831 5.2195 14.7182 5.2195 16.1842 4.63361C18.646 3.65302 22.6364 3.16375 25.8078 8.02242C30.7603 15.5994 22.1667 28.14 17.9137 28.14Z"
                  fill="#00DD7B"
                />
                <path
                  d="M17.9135 28.657C16.6667 28.7454 15.4217 28.4607 14.3372 27.8392C14.2115 27.7467 14.0595 27.6967 13.9034 27.6963C13.7473 27.696 13.5951 27.7453 13.4689 27.8371C9.1469 30.233 5.47586 27.318 2.33117 21.7249C-0.478764 16.5387 -0.765113 11.3113 1.56276 7.74322C5.01647 2.44884 9.46312 3.22137 11.8075 4.15664C13.1508 4.69363 14.6492 4.69363 15.9925 4.15664C18.3389 3.22446 22.7825 2.44987 26.2393 7.74219C28.5682 11.3071 28.2849 16.5315 25.4802 21.7156C23.3037 25.7379 20.1209 28.657 17.9135 28.657ZM13.9005 26.666C14.269 26.6671 14.6281 26.7823 14.9285 26.9956C15.8383 27.4989 16.8778 27.7188 17.9135 27.627C19.7058 27.627 22.6311 24.815 24.5737 21.2253C27.1962 16.379 27.4959 11.5492 25.3761 8.30561C22.4735 3.85896 18.8797 4.11543 16.3736 5.11251C14.7858 5.74802 13.0142 5.74802 11.4264 5.11251C8.92339 4.11543 5.32651 3.85999 2.4249 8.30561C0.307152 11.5523 0.611012 16.3852 3.23657 21.2336C5.21114 24.8789 8.07464 27.627 9.8978 27.627C10.9309 27.7186 11.9677 27.4987 12.8746 26.9956C13.1741 26.782 13.5326 26.6668 13.9005 26.666Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M13.9034 8.55122C12.6273 8.50532 11.4063 8.0195 10.4476 7.17613C10.3947 7.13386 10.3507 7.08158 10.318 7.02229C10.2853 6.963 10.2646 6.89785 10.2571 6.83056C10.2496 6.76327 10.2554 6.69516 10.2742 6.63012C10.293 6.56508 10.3245 6.50439 10.3667 6.4515C10.4521 6.34468 10.5764 6.27616 10.7123 6.26099C10.7796 6.25349 10.8477 6.2593 10.9127 6.27812C10.9778 6.29693 11.0385 6.32837 11.0914 6.37064C11.8462 7.09919 12.8543 7.50635 13.9034 7.50635C14.9524 7.50635 15.9605 7.09919 16.7153 6.37064C16.8222 6.28527 16.9585 6.24583 17.0944 6.26099C17.2303 6.27616 17.3546 6.34468 17.44 6.4515C17.5253 6.55831 17.5648 6.69467 17.5496 6.83056C17.5345 6.96645 17.4659 7.09076 17.3591 7.17613C16.4004 8.0195 15.1794 8.50532 13.9034 8.55122Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M13.8883 8.54261C13.8143 8.54268 13.741 8.52922 13.6733 8.5031C13.6056 8.47699 13.5449 8.4388 13.4952 8.391C13.4455 8.3432 13.4078 8.28685 13.3847 8.2256C13.3615 8.16434 13.3534 8.09954 13.3608 8.03538C13.4839 6.6334 13.3379 5.22335 12.9286 3.86317C12.6095 2.68905 11.8209 1.6521 10.7039 0.937823C10.6459 0.904326 10.5959 0.861184 10.557 0.810867C10.5181 0.760549 10.491 0.704046 10.4773 0.644593C10.4635 0.585139 10.4633 0.523904 10.4768 0.464393C10.4902 0.404883 10.517 0.348267 10.5556 0.297788C11.4105 -0.566351 13.2729 1.90894 13.578 2.69377C14.5451 4.33167 14.5208 8.15066 14.4138 8.12761C14.4009 8.24119 14.34 8.34656 14.2429 8.42325C14.1458 8.49994 14.0195 8.54248 13.8883 8.54261Z"
                  fill="#3D3D3D"
                />
                <path
                  d="M17.5633 3.50051C17.1027 3.84994 16.5363 4.10687 15.9145 4.24848C15.2928 4.39008 14.6349 4.41198 13.9994 4.31221C12.6109 2.23253 15.8648 -0.0748971 18.5687 0.605983C18.7474 1.1136 18.7485 1.64825 18.5719 2.1563C18.3952 2.66434 18.0481 3.12792 17.5633 3.50051Z"
                  fill="#8CEA79"
                />
                <path
                  d="M14.7836 4.76935C14.3561 4.73782 13.6732 4.81505 13.5022 4.42575C13.4168 4.12787 13.1912 3.84259 13.1211 3.53761C13.1182 3.01524 13.2724 2.49936 13.5718 2.02987C13.8711 1.56038 14.3076 1.14986 14.8475 0.830055C15.3874 0.510255 16.0161 0.289747 16.6852 0.185586C17.3543 0.081424 18.0457 0.0964 18.706 0.229356C18.7926 0.247646 18.8714 0.282943 18.9346 0.331742C18.9979 0.380541 19.0433 0.441145 19.0665 0.507539C19.267 1.08209 19.2672 1.6868 19.0671 2.26143C18.8671 2.83606 18.4737 3.36057 17.9262 3.7827C17.0896 4.41564 15.9602 4.77024 14.7836 4.76935ZM14.3943 3.96474C14.9188 4.00934 15.4497 3.96023 15.9452 3.82128C16.4407 3.68232 16.8873 3.45731 17.2499 3.16393C17.6125 2.87056 17.8812 2.51682 18.0348 2.13055C18.1884 1.74427 18.2229 1.33599 18.1353 0.937818C16.0269 0.560339 13.4312 2.32164 14.3943 3.96395V3.96474Z"
                  fill="#3D3D3D"
                />
              </svg>
            </Box>
          </Box>
          <Typography
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              mb: '10px',
              '&.xs-only': {
                fontSize: '14px',
              },
            }}
          >
            You are better than 95% of dealers
          </Typography>
          <ButtonLG
            onClick={() => {
              navigate('/go-greener')
            }}
          >
            Learn more
          </ButtonLG>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              position: 'absolute',
              bottom: '-3px',
              right: 0,
              '&.xs-only': {
                top: 'auto',
                bottom: 0,
                verticalAlign: 'top',
              },
            }}
          >
            <img
              src={AppleBackground}
              alt="apple-bg"
              style={{
                mixBlendMode: 'darken',
                verticalAlign: 'top',
              }}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )
}
