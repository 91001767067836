import React from 'react'
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  Button,
  styled,
  useMediaQuery,
} from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconArrowDown from '../../assests/img/icons/icon-arrow-down.svg'
import IconArrowDownBlue from '../../assests/img/icons/icon-arrow-down-blue.svg'
import { useNavigate } from 'react-router-dom'
import TextNormal from '../../ui/Text/TextNormal'
import CarMiniAction from '../VirtualGarage/CarMiniAction'

export const OperatorTableHead = (props) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '16px',
    position: 'relative',
    paddingTop: '12px',
    paddingBottom: '12px',
    border: 'none',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  }))

  const TriangleButtonUp = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '2px',
    right: '-17px',
    width: '13px',
    height: '10px',
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDown}) center center no-repeat`,
      transform: 'rotate(180deg)',
      transformOrigin: '50% 50%',
    },
    '&::before': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDownBlue}) center center no-repeat`,
      opacity: 0,
      transform: 'rotate(180deg)',
      transformOrigin: '50% 50%',
    },
    '&:hover': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
  }))

  const TriangleButtonDown = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '2px',
    right: '-17px',
    width: '13px',
    height: '10px',
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDown}) center center no-repeat`,
    },
    '&::before': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDownBlue}) center center no-repeat`,
      opacity: 0,
    },
    '&:hover': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
  }))

  const TableCellTitle = styled(Typography)(({ theme }) => ({
    color: colorsConfig.mainBlack,
    fontSize: '18px',
    fontWeight: 700,
    position: 'relative',
    display: 'inline',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
  }))

  const { tableCells, sort, setSort, handleSortClick } = props

  return (
    <TableHead>
      <TableRow>
        {tableCells.map((item, index) => {
          return (
            <StyledTableCell key={index}>
              <TableCellTitle>
                {item.title}
                {item.sortable ? (
                  <React.Fragment>
                    <TriangleButtonDown
                      className={sort === item.sortableAsc ? 'active' : ''}
                      onClick={() => handleSortClick(index, 'asc')}
                    />
                    <TriangleButtonUp
                      className={sort === item.sortableDesc ? 'active' : ''}
                      onClick={() => handleSortClick(index, 'desc')}
                    />
                  </React.Fragment>
                ) : null}
              </TableCellTitle>
            </StyledTableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export const OperatorTableBody = (props) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const navigate = useNavigate()

  const StyledCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  }))

  const CellText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    // fontSize: xsOnly ? '14px' : '16px',
    fontSize: '16px',
    color: colorsConfig.mainBlack,
  }))

  const CellCaption = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    // fontSize: xsOnly ? '12px' : '14px',
    fontSize: '14px',
    color: colorsConfig.lightGray,
    marginTop: '1px',
  }))

  const ImageWrapper = styled(Box)(({ theme }) => ({
    maxWidth: '86px',
    maxHeight: '52px',
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      objectFit: 'fill',
    },
  }))

  const { content, setCars, cars, getActionList } = props

  return (
    <TableBody>
      {content.map((row) => {
        return (
          <TableRow key={row.id}>
            {row.cells.map((cell, index) => {
              return (
                <StyledCell
                  key={index}
                  onClick={() => {
                    if (
                      row.cells.filter((item) => item.type === 'bids')[0] &&
                      row.cells[4].value !== 'Auction Running'
                    ) {
                      console.log('aborted')
                      return
                    }
                    if (
                      row.cells.filter((item) => item.type === 'bids')[0] &&
                      row.cells[4].value === 'Auction Running'
                    ) {
                      navigate(`/cars-on-auction/${row.id}`)
                      return
                    }
                    if (cell.type !== 'actions') {
                      navigate(row.link)
                    }
                  }}
                  sx={{
                    cursor: cell.type !== 'actions' ? 'pointer' : 'auto',
                  }}
                >
                  {cell.type === 'text' ? <CellText>{cell.value}</CellText> : null}
                  {cell.type === 'image' ? (
                    <ImageWrapper>
                      <img src={cell.value} alt="" />
                    </ImageWrapper>
                  ) : null}
                  {cell.type === 'text-with-caption' ? (
                    <Box>
                      <CellText>{cell.value}</CellText>
                      <CellCaption>{cell.caption}</CellCaption>
                    </Box>
                  ) : null}
                  {cell.type === 'actions' ? (
                    <CarMiniAction
                      cell={cell}
                      carId={row.id}
                      auctionCarId={row.auctionCarId ? row.auctionCarId : null}
                      cars={cars}
                      setCars={setCars}
                      getActionList={getActionList}
                    />
                  ) : null}
                  {cell.type === 'bids' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '160px',
                      }}
                    >
                      <TextNormal
                        sx={{
                          px: '10px',
                          py: '6px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: colorsConfig.mainBlack,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {cell.value.amount}
                      </TextNormal>
                      {cell.value.actionAvailable ? (
                        <Button
                          sx={{
                            textTransform: 'none',
                            color: colorsConfig.mainBlue,
                            // fontSize: xsOnly ? '14px' : '16px',
                            fontSize: '16px',
                            fontWeight: 400,
                            ml: '10px',
                            fontFamily: '"Helvetica", sans-serif',
                            textDecoration: 'underline',
                            transition: '0.25s all ease-in-out',
                            '&:hover': {
                              color: colorsConfig.buttonHover,
                              transition: '0.25s all ease-in-out',
                            },
                          }}
                        >
                          Change
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                </StyledCell>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
