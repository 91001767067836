import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMultiQueryParam } from '../../../utils/helpers/request.helpers'
import { policyWorkflowStates } from '../../../utils/constants/workflows.constants'
import rviApiService from '../../../api/rviService/rviApiService'
import { apiConstants } from '../../../api/api'

export const getActivePolicies = createAsyncThunk(
  'rviDealer/getActivePolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviDealer.activePolicies.params
      const extraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.restitution_started,
        policyWorkflowStates.restitution_in_progress,
        policyWorkflowStates.linked_to_dealer,
      ])
      const response = await rviApiService.getPolicies(params, extraQuery, 'getActivePolicies')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getClosedPolicies = createAsyncThunk(
  'rviDealer/getClosedPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviDealer.closedPolicies.params
      const extraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.closed,
        policyWorkflowStates.closed_due_to_end_of_contract,
        policyWorkflowStates.closed_by_dealer,
      ])
      const response = await rviApiService.getPolicies(params, extraQuery, 'getClosedPolicies')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getPoliciesStatusCount = createAsyncThunk(
  'rviDealer/getPoliciesStatusCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        isArchived: false,
        pagination: false,
      }
      const closedExtraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.closed,
        policyWorkflowStates.closed_due_to_end_of_contract,
        policyWorkflowStates.closed_by_dealer,
      ])
      const activeExtraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.restitution_started,
        policyWorkflowStates.restitution_in_progress,
        policyWorkflowStates.linked_to_dealer,
      ])
      const [activeRes, closedRes] = await Promise.all([
        rviApiService.getPolicies(params, activeExtraQuery, 'getPoliciesStatusCountActive'),
        rviApiService.getPolicies(params, closedExtraQuery, 'getPoliciesStatusCountClosed'),
      ])
      return {
        activePoliciesCount: activeRes.data?.[apiConstants.hydraMember]?.length ?? 0,
        closedPoliciesCount: closedRes.data?.[apiConstants.hydraMember]?.length ?? 0,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
