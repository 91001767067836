import React, { useEffect, useState } from 'react'
import ModalWrap from '../../../components/Modals/ModalWrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Table, useMediaQuery } from '@mui/material'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import { privateRequest } from '../../../utils/request'
import TextH1 from '../../../ui/Text/TextH1'
import SearchInput from '../../../components/Search/SearchInput'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import { tableHeadCellsFinanceCarModal } from '../../../config/tablesConfig'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import { apiConstants } from '../../../api/api'

const ActivateWarrantyModal = ({ open, setOpen }) => {
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  const navigate = useNavigate()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const [searchLine, setSearchLine] = useState('')
  const [cars, setCars] = useState([])

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const renderInsureButton = () => {
    return (
      <ButtonHollow
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        Activate warranty
      </ButtonHollow>
    )
  }

  const getCars = () => {
    let link = API_ENDPOINTS.cars.companyCars
    link = `${link}&combinedStatus[]=car_workflow.ready_for_sale&combinedStatus[]=car_workflow.new`
    link = `${link}&combinedStatus[]=car_workflow.appraisal_on_site&combinedStatus[]=car_workflow.quality_check`
    link = `${link}&combinedStatus[]=car_workflow.moderation_failed&combinedStatus[]=car_workflow.requested_appraisal_compound`
    link = `${link}&combinedStatus[]=car_workflow.awaiting_appraisal_compound&combinedStatus[]=car_workflow.appraisal_compound`
    link = `${link}&combinedStatus[]=auction_workflow.pending&combinedStatus[]=auction_workflow.active`
    link = `${link}&combinedStatus[]=auction_workflow.completed`
    if (searchLine) {
      link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[id]=${searchLine}`
    }
    privateRequest.get(link).then((response) => {
      const filteredCars = response.data[apiConstants.hydraMember]?.filter(
        (item) => !item.isWarrantyRelated,
      )
      setCars(
        filteredCars.map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.id}?service=Warranty`,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.make.name} ${item.model.name}`,
                caption: `VIN ${item.vin}`,
              },
              {
                type: 'text',
                value: getCarStatus(item.combinedStatus, item.isArchived),
              },
              {
                type: 'component',
                value: renderInsureButton(),
              },
            ],
          }
        }),
      )
    })
  }

  useEffect(() => {
    if (carWorkflows.length > 0) {
      getCars()
    }
  }, [searchLine, carWorkflows])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: '1100px',
        '&.xs-only': {
          width: 'calc(100vw - 30px)',
        },
      }}
    >
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: '40px',
          '&.xs-only': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '15px',
          },
          '&.tablet-only': {
            alignItems: 'flex-end',
          },
        }}
      >
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&.xs-only': {
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '15px',
              width: '100%',
            },
            '&.tablet-only': {
              display: 'block',
            },
          }}
        >
          <TextH1
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              mr: '30px',
              whiteSpace: 'nowrap',
              '&.xs-only': {
                fontSize: '24px',
                mr: '20px',
              },
              '&.tablet-only': {
                fontSize: '32px',
                mb: '15px',
              },
            }}
          >
            Choose a car
          </TextH1>
          <SearchInput
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
            }}
          />
        </Box>
        <ButtonHollow
          fullWidth={xsOnly}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={() => {
            navigate('/add-new-vehicle')
          }}
        >
          <AddRounded
            sx={{
              fill: colorsConfig.mainBlue,
              mr: '4px',
            }}
          />
          Add new vehicle
        </ButtonHollow>
      </Box>
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          mt: '30px',
          '&.xs-only': {
            overflowX: 'auto',
          },
        }}
      >
        <Table
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            '&.xs-only': {
              minWidth: '1000px',
            },
          }}
        >
          <CarTableHead small={tabletOnly} tableCells={tableHeadCellsFinanceCarModal} />
          <CarTableBody small={tabletOnly} content={cars} />
        </Table>
      </Box>
    </ModalWrap>
  )
}

export default ActivateWarrantyModal
