export const fileTypeEnums = {
  foreshortening: 'foreshortening',
  pilot_lamp: 'pilot_lamp',
  damage: 'damage',
  control_point_document: 'control_point_document',
  avatar: 'avatar',
  trade_license: 'trade_license',
  car_appraisal_pdf_result: 'car_appraisal_pdf_result',
  invoice: 'invoice',
  customer_file: 'customer_file',
  loan_deal_attachment: 'loan_deal_attachment',
  insurance_deal_attachment: 'insurance_deal_attachment',
  warranty_certificate: 'warranty_certificate',
  rvi_policy_import: 'rvi_policy_import',
  default: 'default',
}

export const vehicleExpertiseEnums = {
  dealership: 'dealership',
  expertCenter: 'expertCenter',
}
export const vehicleExpertiseNames = {
  [vehicleExpertiseEnums.dealership]: 'Dealership',
  [vehicleExpertiseEnums.expertCenter]: 'Expert Center',
}

export const CAR_ENUMS = {
  CAR_BODY: 'car_body',
  CAR_TYPE: 'car_type',
  CAR_DRIVE: 'car_drive',
  CAR_ENGINE: 'car_engine',
  CAR_EURO_NORM: 'car_euro_norm',
  CAR_GEARBOX: 'car_gearbox',
  CAR_ORIGIN: 'car_origin',
  CAR_RIMS: 'car_rims',
  CAR_STATE: 'car_state',
  CAR_UPHOLSTERY: 'car_upholstery',
}
