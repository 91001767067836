import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, useMediaQuery } from '@mui/material'
import { Numbers } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import IconVin from '../../../assests/img/icons/icon-vin.svg'
import GrayTable from '../../../ui/GrayTable'
import { colorsConfig } from '../../../config/themeConfig'
import IconMileage from '../../../assests/img/icons/icon-mileage.svg'
import IconCalendar from '../../../assests/img/icons/icon-calendar.svg'
import IconGear from '../../../assests/img/icons/icon-gear.svg'
import IconFuel from '../../../assests/img/icons/icon-fuel.svg'
import IconHP from '../../../assests/img/icons/icon-hp.svg'
import IconSeats from '../../../assests/img/icons/icon-seats.svg'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { API_ENDPOINTS, HINT_TYPES } from '../../../config/default'
import TextNormal from '../../../ui/Text/TextNormal'
import IconAttentionRed from '../../../assests/img/icons/icon-attention-red.svg'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import IconTriangle from '../../../assests/img/icons/icon-triangle.svg'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import CarSpecs from '../../AddNewVehicle/CarSpecs'
import {
  bodyTypes,
  driveTypes,
  engineTypes,
  gearboxTypes,
  originTypes,
  rimsTypes,
} from '../../../config/formsConfig'
import ManufacturerCarSpecs from '../../AddNewVehicle/CarSpecs/ManufacturerCarSpecs'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../utils/date.helpers'
import { getCarEnumName } from '../../../utils/helpers/enums.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'
import {
  capitalizeString,
  getCarPaintwork,
  isEngineTypeElectric,
} from '../../../utils/helpers/common.helpers'

const VehicleDetailsSection = () => {
  const params = useParams()
  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const vin = useSelector((state) => state.car.carData.vin)
  const carId = useSelector((state) => state.car.carData.id)
  const refNumber = useSelector((state) => state.car.carData.referenceNumber)
  const carCompany = useSelector((state) => state.car.carData.company)
  const company = useSelector((state) => state.auth.companyId)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)
  const car = useSelector((state) => state.car.car)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)

  const [isOwner, setIsOwner] = useState(false)
  const [showSpecs, setShowSpecs] = useState(false)
  const [carSpecs, setCarSpecs] = useState([])
  const [carSpecsLoaded, setCarSpecsLoaded] = useState(false)
  const [carManufacturerSpecs, setCarManufacturerSpecs] = useState([])
  const [carManufacturerSpecsLoaded, setCarManufacturerSpecsLoaded] = useState(false)
  const [carSpecsReference, setCarSpecsReference] = useState([])
  const [carSpecsBlocks, setCarSpecsBlocks] = useState([])
  const [carSpecsAmount, setCarSpecsAmount] = useState(0)

  const additionalCarSpecsNotEmpty = carSpecsBlocks.filter(
    (item) => item.specs.filter((spec) => spec.value)[0],
  )[0]

  const publicDetails = [
    {
      title: t('form_input_label_car_type'),
      value: getCarEnumName(car?.type, CAR_ENUMS.CAR_TYPE, enumDetails),
    },
    {
      title: t('form_input_label_model_details'),
      value: car?.details ?? '-',
    },
    {
      title: t('form_input_label_body_type'),
      value: getCarEnumName(car?.bodyType, CAR_ENUMS.CAR_BODY, enumDetails),
    },
    {
      title: t('form_input_label_drive'),
      value: getCarEnumName(car?.driveType, CAR_ENUMS.CAR_DRIVE, enumDetails),
    },
    {
      title: t('form_input_label_cylinder'),
      value: car?.cylinders ?? '-',
    },
    {
      title: `${t('form_input_label_engine_size')} (ccm)`,
      value: car.engineSize ? car.engineSize : '-',
    },
    {
      title: t('form_input_label_engine_number'),
      value: car.engineNumber ? car.engineNumber : '-',
    },
    {
      title: t('form_input_label_rims'),
      value: getCarEnumName(car?.rims, CAR_ENUMS.CAR_RIMS, enumDetails),
    },
    {
      title: t('form_input_label_steering_wheel_location'),
      value: car?.wheelLocation ? capitalizeString(car?.wheelLocation) : '-',
    },
    {
      title: t('form_input_label_origin'),
      value: getCarEnumName(car?.origin, CAR_ENUMS.CAR_ORIGIN, enumDetails),
    },
    {
      title: t('form_input_label_gears_number'),
      value: car.gearsNumber ? car.gearsNumber : '-',
    },
    {
      title: t('form_input_label_doors_number'),
      value: car?.doorsNumber ? `${car?.doorsNumber} / ${parseInt(car?.doorsNumber) + 1}` : '-',
    },
    {
      title: `${t('form_input_label_weight')} (kg)`,
      value: car?.weight ? car?.weight : '-',
    },
    {
      title: t('form_input_label_interior_color'),
      value: car?.interiorColor ? t(`color_${car.interiorColor.toLowerCase()}`) : '-',
    },
    {
      title: t('form_input_label_upholstery'),
      value: getCarEnumName(car?.upholstery, CAR_ENUMS.CAR_UPHOLSTERY, enumDetails),
    },
    {
      title: t('form_input_label_exterior_color'),
      value: car.exteriorColor ? t(`color_${car.exteriorColor.toLowerCase()}`) : '-',
    },
    {
      title: t('form_input_label_paintwork'),
      value: getCarPaintwork(car),
    },
    {
      title: t('form_input_label_gross_price_without_options'),
      value: car?.grossPriceWithoutOptions ? sumDelimiter(car?.grossPriceWithoutOptions) : '-',
    },
    {
      title: t('form_input_label_options_price'),
      value: car?.optionsPrice ? sumDelimiter(car?.optionsPrice) : '-',
    },
    {
      title: t('form_input_label_gross_price_with_options'),
      value: car?.grossPriceWithOptions ? sumDelimiter(car?.grossPriceWithOptions) : '-',
    },
  ]

  const privateDetails = [
    {
      title: t('form_input_label_purchase_price'),
      value: car?.purchasePrice ? sumDelimiter(car?.purchasePrice) : '-',
    },
    {
      title: t('form_input_label_booking_value'),
      value: car?.bookingValue ? sumDelimiter(car?.bookingValue) : '-',
    },
  ]

  const ecoDetails = [
    {
      title: isEngineTypeElectric(car?.engineType)
        ? `${t('form_input_label_electricity_consumption')} (kwh/100 km)`
        : `${t('form_input_label_fuel_consumption')} (L/100 km)`,
      value: car.fuelConsumption ? car.fuelConsumption : '-',
    },
    {
      title: `${t('form_input_label_co2_emission')} (g/km)`,
      value: car.CO2Emission ? car.CO2Emission : '-',
    },
    {
      title: t('form_input_label_energy_efficiency_category'),
      value: car.energyEfficiencyCategory ? car.energyEfficiencyCategory : '-',
    },
    {
      title: t('form_input_label_euro_norm'),
      value: car.euroNorm ? car.euroNorm : '-',
    },
  ]

  const getCarSpecsReference = () => {
    privateRequest.get(API_ENDPOINTS.carSpecsOptionsBlocks).then((response) => {
      setCarSpecsReference(response.data['hydra:member'])
    })
  }

  const getCarSpecs = () => {
    privateRequest
      .get(`${API_ENDPOINTS.carId(car?.id)}${API_ENDPOINTS.carSpecs.default}`)
      .then((response) => {
        setCarSpecs(response.data['hydra:member'])
        setCarSpecsAmount(
          response.data['hydra:member'].filter((item) => item.value === true).length,
        )
        setCarSpecsLoaded(true)
      })
  }

  const getCarManufacturerSpecs = () => {
    if (car?.manufacturerSpecs) {
      setCarManufacturerSpecs(car?.manufacturerSpecs)
      setCarManufacturerSpecsLoaded(true)
    }
    // privateRequest.get(`${API_ENDPOINTS.carId(car?.id)}`).then((response) => {
    //   const manufacturerSpecs = response.data?.manufacturerSpecs
    //   if (manufacturerSpecs) {
    //     setCarManufacturerSpecs(response.data.manufacturerSpecs)
    //     setCarManufacturerSpecsLoaded(true)
    //   }
    // })
  }

  const getFirstRegistrationMonthAndYear = (date) => {
    return formatDate(date).slice(3).replace('.', '/')
  }

  const handleSetCarSpecsBlocks = () => {
    setCarSpecsBlocks(
      carSpecsReference.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          const matchCarSpec = carSpecs.filter((carSpec) => carSpec.spec === spec['@id'])[0]
          if (matchCarSpec) {
            return {
              ...spec,
              carSpecId: matchCarSpec['@id'],
              value: matchCarSpec.value,
            }
          }
          return {
            ...spec,
            value: false,
          }
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  useEffect(() => {
    if (car?.['@id']) {
      getCarSpecs()
      getCarSpecsReference()
      getCarManufacturerSpecs()
    }
  }, [car])

  useEffect(() => {
    if (carSpecsLoaded && carSpecsReference.length > 0) {
      handleSetCarSpecsBlocks()
    }
  }, [carSpecs, carSpecsReference])

  useEffect(() => {
    if (carCompany && company) {
      if (company.toString() === carCompany.replace('/companies/', '')) {
        setIsOwner(true)
      }
    }
  }, [carCompany, company])

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        padding: '32px',
        boxShadow: '0 0 24px 0 rgba(0, 0, 0, .1)',
        '&.xs-only': {
          px: '8px',
          py: '17px',
          borderRadius: '6px',
        },
      }}
    >
      <Grid container spacing={xsOnly ? 0 : 3}>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '20px',
                  md: '35px',
                },
                marginRight: {
                  xs: '7px',
                  md: '10px',
                },
                py: '8px',
                height: '50px',
              }}
            >
              <Numbers
                style={{
                  fill: colorsConfig.mainBlue,
                }}
                sx={{
                  fontSize: {
                    xs: '25px',
                    md: '30px',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                className={xsOnly ? 'xs-only' : ''}
                variant="body1"
                sx={{
                  fontSize: '16px',
                  color: colorsConfig.mainBlack,
                  fontWeight: 400,
                  '&.xs-only': {
                    fontSize: '14px',
                  },
                }}
              >
                Reference {xsOnly ? '#' : 'number'}
              </Typography>
              <Typography
                className={xsOnly ? 'xs-only' : ''}
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontWeight: 500,
                  color: colorsConfig.mainBlack,
                  '&.xs-only': {
                    fontSize: '14px',
                  },
                }}
              >
                {refNumber ? refNumber : carId}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={4} xs={7}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                marginRight: '10px',
                py: '8px',
                height: '50px',
              }}
            >
              <img src={IconVin} alt="icon-vin" />
            </Box>
            <Box>
              <Typography
                variant="body1"
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  fontSize: '16px',
                  color: colorsConfig.mainBlack,
                  fontWeight: 400,
                  '&.xs-only': {
                    fontSize: '14px',
                  },
                }}
              >
                VIN
              </Typography>
              <Typography
                className={xsOnly ? 'xs-only' : ''}
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontWeight: 500,
                  color: colorsConfig.mainBlack,
                  '&.xs-only': {
                    fontSize: '12px',
                  },
                }}
              >
                {vin}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: {
                xs: 'wrap',
                md: 'nowrap',
              },
              mb: '45px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
                mb: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconMileage} alt="icon-mileage" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {car.mileage ? `${car.mileage} km` : 'N/A'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
                mb: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconCalendar} alt="" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {car?.firstRegistrationDate
                  ? getFirstRegistrationMonthAndYear(car?.firstRegistrationDate)
                  : '-'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
                mb: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconGear} alt="" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {getCarEnumName(car?.gearbox, CAR_ENUMS.CAR_GEARBOX, enumDetails)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconFuel} alt="" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {getCarEnumName(car?.engineType, CAR_ENUMS.CAR_ENGINE, enumDetails)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconHP} alt="" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {car.hp ? `${car.hp} HP` : '-'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: {
                  xs: 'calc(100% / 3)',
                  md: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                <img src={IconSeats} alt="" style={{ verticalAlign: 'middle' }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"HelveticaNeue", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {car.seatsNumber ? car.seatsNumber : '-'} seats
              </Typography>
            </Box>
          </Box>
          <TextBoldNormal
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              mb: '18px',
            }}
          >
            Main Characteristics
          </TextBoldNormal>
          <GrayTable content={publicDetails} />
          {isOwner || car.carHiddenPriceShow ? (
            <>
              <TextBoldNormal
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  mb: '18px',
                  mt: '20px',
                }}
              >
                Private fields
              </TextBoldNormal>
              <GrayTable content={privateDetails} />
            </>
          ) : null}
          <TextBoldNormal
            sx={{
              fontSize: '18px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mb: '18px',
              mt: '20px',
            }}
          >
            <img src={IconCoin} alt="" style={{ marginRight: '5.5px' }} />
            Rewarded fields
          </TextBoldNormal>
          <GrayTable content={ecoDetails} />
          {additionalCarSpecsNotEmpty ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '18px',
                mt: '20px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowSpecs(!showSpecs)
              }}
            >
              <TextBoldNormal
                sx={{
                  fontSize: '18px',
                }}
              >
                Additional specifications ({carSpecsAmount})
              </TextBoldNormal>
              <Box
                className={showSpecs ? 'active' : ''}
                sx={{
                  transformOrigin: '50% 50%',
                  transform: 'rotate(180deg)',
                  transition: '0.25s all ease-in-out',
                  '&.active': {
                    transform: 'rotate(0deg)',
                    transition: '0.25s all ease-in-out',
                  },
                }}
              >
                <img src={IconTriangle} alt="" />
              </Box>
            </Box>
          ) : null}
          {showSpecs ? (
            <CarSpecs
              blocks={carSpecsBlocks}
              changeCarSpec={() => {
                return false
              }}
              readOnly={true}
            />
          ) : null}
          {car.additionalInfo ? (
            <Box
              sx={{
                mt: '15px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  color: colorsConfig.mainBlack,
                  mb: '10px',
                }}
              >
                Additional Information
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: colorsConfig.mainBlack,
                }}
              >
                {car.additionalInfo}
              </Typography>
            </Box>
          ) : null}
        </Grid>
        {carManufacturerSpecs.length > 0 ? (
          <Grid item xs={12}>
            <ManufacturerCarSpecs manufacturerSpecs={carManufacturerSpecs} isCarPageDesign={true} />
          </Grid>
        ) : null}
      </Grid>

      {qualityCheckHints.filter((item) => item['@type'] === HINT_TYPES.vehicleDetails)[0] ? (
        <Box
          sx={{
            mt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              mr: '10px',
            }}
          >
            <img src={IconAttentionRed} alt="" />
          </Box>

          <TextNormal>
            {
              qualityCheckHints.filter((item) => item['@type'] === HINT_TYPES.vehicleDetails)[0]
                .hint
            }
          </TextNormal>
        </Box>
      ) : null}
    </Box>
  )
}

export default VehicleDetailsSection
