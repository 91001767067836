import React, { useState } from 'react'
import UITableActionsList from '../../../../ui/UITable/UITableActionsList'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  archiveRviPolicy,
  getLinkedPolicies,
  getUnlinkedPolicies,
} from '../../../../store/features/rviOperator/rviOperator.actions'
import LinkDealerModal from '../Modals/LinkDealerModal'
import CancelPolicyModal from '../Modals/CancelPolicyModal'
import CloseDueToEndOfContractModal from '../Modals/CloseDueToEndOfContractModal'
import { closeDueToEndOfContract } from '../../../../store/features/rviPolicy/rviPolicy.actions'
import { toast } from 'react-toastify'

const LinkedPoliciesActionItems = ({ data }) => {
  const dispatch = useDispatch()

  const [cancelPolicyModal, setCancelPolicyModal] = useState(false)
  const [closeDueToEndOfContractModal, setCloseDueToEndOfContractModal] = useState(false)

  const onCancelPolicy = () => {
    dispatch(getLinkedPolicies())
    setCancelPolicyModal(false)
  }

  const handleClosePolicyDueToEndOfContract = async () => {
    const res = await dispatch(closeDueToEndOfContract({ policyId: data?.initialData?.id }))
    if (closeDueToEndOfContract.fulfilled.match(res)) {
      toast.success('Policy has been closed successfully')
      setCloseDueToEndOfContractModal(false)
      dispatch(getLinkedPolicies())
    }
  }

  return (
    <>
      <UITableActionsList
        actions={[
          {
            name: 'Close due to the end of the contract',
            handleAction: () => {
              setCloseDueToEndOfContractModal(true)
            },
          },
          {
            name: 'Cancel policy',
            handleAction: () => {
              setCancelPolicyModal(true)
            },
          },
        ]}
      />
      {cancelPolicyModal && (
        <CancelPolicyModal
          open={cancelPolicyModal}
          setOpen={setCancelPolicyModal}
          data={data?.initialData}
          onClose={onCancelPolicy}
        />
      )}
      {closeDueToEndOfContractModal && (
        <CloseDueToEndOfContractModal
          open={closeDueToEndOfContractModal}
          setOpen={setCloseDueToEndOfContractModal}
          data={data?.initialData}
          handleAction={handleClosePolicyDueToEndOfContract}
        />
      )}
    </>
  )
}

export default LinkedPoliciesActionItems
