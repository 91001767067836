import { setCarMainData } from './addNewCar.reducer'
import dayjs from 'dayjs'
import {
  CO2EmissionOptions,
  fuelConsumptionOptions,
} from '../../../pages/AddNewVehicle/addNewVehicleForm.helpers'

export const initialCarMainData = {
  // Required fields
  firstRegistrationDate: '',
  mileage: '',
  engineType: null,
  engineSize: '',
  bodyType: null,
  wheelLocation: 'left',
  origin: null,
  driveType: null,
  gearbox: null,
  seatsNumber: '',
  doorsNumber: '',
  // Optional fields
  referenceNumber: '',
  details: '',
  type: null,
  weight: '',
  hp: '',
  cylinders: null,
  engineNumber: '',
  gearsNumber: null,
  rims: null,
  optionsPrice: '',
  grossPriceWithOptions: '',
  grossPriceWithoutOptions: '',
  purchasePrice: '',
  bookingValue: '',
  carHiddenPriceShow: false,
  CO2Emission: null,
  fuelConsumption: null,
  energyEfficiencyCategory: '',
  electricityConsumption: '',
  euroNorm: null,
  additionalSpecsDetails: '',
  interiorColor: '',
  exteriorColor: '',
  isMetallic: false,
  isWrapped: false,
  isMatt: false,
  upholstery: null,
  additionalInfo: '',
}

export const initialCarFirstData = {
  valid: false,
  vin: {
    value: '',
    valid: false,
    errorText: '',
  },
  make: {
    name: '',
    id: null,
  },
  model: {
    name: '',
    id: null,
  },
  policy: null,
  company: null,
}

export const getFieldsAutofilled = (data) => {
  const {
    cylinders,
    details,
    variant,
    bodyType,
    doorsNumber,
    engineType,
    driveType,
    weight,
    engineSize,
    gearbox,
    gearsNumber,
    origin,
    exteriorColor,
    interiorColor,
    purchasePrice,
    bookingValue,
    grossPrice,
    optionsPrice,
    grossPriceWithoutOptions,
    energyEfficiencyCategory,
    fuelConsumption,
    CO2Emission,
    seatsNumber,
    hp,
    specs,
  } = data

  const modelDetails = [`${variant ? variant : ''}`, `${details ? details : ''}`]
  const grossPriceWithOptions =
    grossPriceWithoutOptions && optionsPrice
      ? parseFloat(grossPriceWithoutOptions) + parseFloat(optionsPrice)
      : ''
  const additionalSpecsDetails = specs && specs?.length > 0 ? specs.join('; ') : ''
  console.log(findClosestOption(fuelConsumption, fuelConsumptionOptions))
  return {
    firstRegistrationDate: dayjs(
      new Date(parseInt(data?.buildYear), parseInt(data?.buildMonth) - 1, 2),
    ),
    engineSize,
    seatsNumber,
    engineType,
    origin,
    gearbox,
    bodyType,
    driveType,
    cylinders,
    details: modelDetails.join(' ').trim(),
    optionsPrice: optionsPrice ?? '',
    grossPriceWithoutOptions: grossPriceWithoutOptions ?? '',
    grossPriceWithOptions,
    hp,
    exteriorColor,
    manufacturerSpecs: specs,
    additionalSpecsDetails,
    interiorColor,
    purchasePrice,
    bookingValue,
    grossPrice,
    energyEfficiencyCategory,
    fuelConsumption: fuelConsumption
      ? findClosestOption(fuelConsumption, fuelConsumptionOptions)?.value
      : null,
    CO2Emission: CO2Emission ? findClosestOption(CO2Emission, CO2EmissionOptions)?.value : null,
    gearsNumber,
    doorsNumber,
    weight,
    isMetallic: exteriorColor?.toLowerCase()?.includes('metallic'),
  }
}

const findClosestOption = (targetValue, options) => {
  let closestOption = options[0]
  let minDifference = Infinity

  options.forEach((option) => {
    if (option.value !== '') {
      const optionValue = parseFloat(option.value)
      const difference = Math.abs(optionValue - targetValue)

      if (difference < minDifference) {
        minDifference = difference
        closestOption = option
      }
    }
  })

  return closestOption
}
