import React, { useState } from 'react'
import { Box } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import { useSelector, useDispatch } from 'react-redux'
import { updateCompanySettings } from '../../store/features/authSlice'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { colorsConfig } from '../../config/themeConfig'
import TextNormal from '../../ui/Text/TextNormal'

const TradeLicenseSettings = () => {
  const dispatch = useDispatch()

  const companySettings = useSelector((state) => state.auth.companySettings)

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)

  const [saved, setSaved] = useState(false)

  const handleSubmitTradeLicenseExpiryDate = (value) => {
    const formData = {
      tradeLicenseExpiryDate: new Date(value).toISOString(),
    }
    privateRequest.put(API_ENDPOINTS.companyCredentials, formData).then(() => {
      setSaved(true)
      setTimeout(() => {
        setSaved(false)
      }, 1500)
    })
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label="Trade license expiry date"
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => (
            <TextFieldSmallEdible
              {...params}
              fullWidth
              required
              disabled
              sx={{
                svg: {
                  fill: colorsConfig.mainBlue,
                },
              }}
            />
          )}
          onChange={(newVal) => {
            handleSubmitTradeLicenseExpiryDate(newVal)
            dispatch(
              updateCompanySettings({
                ...companySettings,
                tradeLicenseExpiryDate: newVal,
              }),
            )
          }}
          value={companySettings.tradeLicenseExpiryDate}
          disableHighlightToday={true}
          mask={'__/__/____'}
          minDate={currentDate}
        />
      </LocalizationProvider>
      <TextNormal
        className={saved ? 'active' : ''}
        sx={{
          color: colorsConfig.mainGreen,
          position: 'absolute',
          top: '100%',
          left: '14px',
          fontSize: '12px',
          opacity: 0,
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        Saved
      </TextNormal>
    </Box>
  )
}

export default TradeLicenseSettings
