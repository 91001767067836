import React, { useState } from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Box, Button, IconButton, Stack } from '@mui/material'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { ExpandMoreRounded } from '@mui/icons-material'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import AppraisalDocumentDownload from '../../assests/img/icons/icon-appraisal-download.svg'

const CollapseWrapper = ({
  children,
  title,
  background = colorsConfig.bgLightBlue,
  borderWrap,
  addons,
}) => {
  const [expanded, setExpanded] = useState(true)

  return (
    <Box
      sx={{
        background,
        overflow: 'hidden',
        position: 'relative',
        mt: '30px',
        borderRadius: '6px',
      }}
    >
      <Box
        sx={{
          height: '79px',
          py: '17px',
          px: '29px',
          border: '1px solid #DCDCDC',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Box>
          <TextBoldNormal
            sx={{
              color: colorsConfig.mainBlue,
              fontSize: '18px',
            }}
          >
            {title}
          </TextBoldNormal>
        </Box>
        <Stack direction="row" gap="16px" alignItems="center">
          {addons ?? null}
          <IconButton
            className={expanded ? 'open' : ''}
            sx={{
              position: 'relative',
              mr: '-15px',
              transition: '0.25s all ease-in-out',
              '&.open': {
                transform: 'rotate(180deg)',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            <ExpandMoreRounded
              sx={{
                fontSize: '23px',
                width: '40px',
                height: '40px',
              }}
            />
          </IconButton>
        </Stack>
      </Box>
      {expanded ? (
        <Box
          className={borderWrap ? 'border-wrap' : ''}
          sx={{
            '&.border-wrap': {
              border: '1px solid',
              borderColor: colorsConfig.buttonDisabled,
              borderTop: 'none',
              borderRadius: '0 0 8px 8px',
              position: 'relative',
              mt: '-4px',
              pt: '4px',
              overflow: 'hidden',
            },
          }}
        >
          {children}
        </Box>
      ) : null}
    </Box>
  )
}

export default CollapseWrapper
