import React, { createRef, useState } from 'react'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { NavigateNextRounded } from '@mui/icons-material'
import Flicking from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import { colorsConfig } from '../../../config/themeConfig'
import Preloader from '../../../components/Preloader'
import { useSelector } from 'react-redux'
import OffersSliderItem from './OffersSliderItem'

const OffersSlider = (props) => {
  const { loading, onSelectSlide } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const slides = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.terms,
  )
  const [currentIndex, setCurrentIndex] = useState(0)

  const flicking = createRef()

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        width: 'calc(100vw - 390px)',
        position: 'relative',
        '&.xs-only': {
          width: 'calc(100% + 40px)',
          ml: '-20px',
        },
        '&.tablet-only': {
          width: 'calc(100vw - 32px)',
        },
      }}
    >
      {slides.length > 4 && !xsOnly && !tabletOnly ? (
        <>
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              mt: '-15px',
              right: '-30px',
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              opacity: 0.5,
              background: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                opacity: 0.8,
                background: colorsConfig.mainBlack,
                transition: '0.25s all ease-in-out',
              },
            }}
            disabled={currentIndex >= 1}
            onClick={() => {
              flicking.current.next()
            }}
          >
            <NavigateNextRounded
              sx={{
                fill: 'white',
                fontSize: '26px',
              }}
            />
          </IconButton>
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              mt: '-15px',
              left: '-30px',
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              opacity: 0.5,
              background: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                opacity: 0.8,
                background: colorsConfig.mainBlack,
                transition: '0.25s all ease-in-out',
              },
            }}
            disabled={currentIndex === 0}
            onClick={() => {
              flicking.current.prev()
            }}
          >
            <NavigateNextRounded
              sx={{
                fill: 'white',
                fontSize: '26px',
                transformOrigin: '50% 50%',
                transform: 'rotate(180deg)',
              }}
            />
          </IconButton>
        </>
      ) : null}
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'white',
            opacity: 0.5,
            zIndex: 2,
          }}
        >
          <Preloader type={'gooey'} />
        </Box>
      ) : null}
      <Flicking
        panelsPerView={xsOnly ? '' : 4}
        circular={xsOnly}
        ref={flicking}
        align={{
          camera: xsOnly ? 50 : 0,
          panel: 0,
        }}
        onChanged={(e) => {
          setCurrentIndex(e.index)
        }}
      >
        {slides.map((item, index) => {
          return (
            <Box
              key={index}
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                px: '10px',
                py: '10px',
                position: 'relative',
                zIndex: 3,
                '&.xs-only': {
                  width: '142px',
                },
              }}
            >
              <OffersSliderItem slide={item} onSelectSlide={onSelectSlide} />
            </Box>
          )
        })}
      </Flicking>
    </Box>
  )
}

export default OffersSlider
