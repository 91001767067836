import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { MoreHorizRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const UITableActionButton = ({ sx, ...props }) => {
  return (
    <IconButton
      sx={{
        '& svg': {
          fontSize: '30px',
          fill: colorsConfig.mainBlue,
          transition: '0.25s all ease-in-out',
        },
        ':hover': {
          '& svg': {
            fill: colorsConfig.fadedBlue,
            transition: '0.25s all ease-in-out',
          },
        },
        ...sx,
      }}
      {...props}
    >
      <MoreHorizRounded />
    </IconButton>
  )
}

export default UITableActionButton
