import React, { useEffect, useState } from 'react'
import './App.scss'
import './assests/css/preloaders.scss'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './router/AppRouter'
import './assests/fonts/stylesheet.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkAuth,
  logOut,
  setAvatar,
  setAuctionCarsAmount,
  signIn,
  setBalance,
  setCompany,
  setUnreadNotificationsCount,
  setCoinPricings,
  setUser,
} from './store/features/authSlice'
import {
  setCarWorkflows,
  setAuctionWorkflows,
  setDealWorkflows,
} from './store/features/workflowsSlice/workflows.reducer'
import {
  API_BASE_URL,
  API_ENDPOINTS,
  CDN_BASE_URL,
  excludedPricings,
  pricingNames,
} from './config/default'
import { patchRequest, privateMultipartRequest, privateRequest } from './utils/request'
import ScrollToTop from './utils/ScrollUpTo'
import Avatar1 from './assests/img/avatars/avatar-1.png'
import axios from 'axios'
import DevModal from './utils/dev/devModal'
import { getWorkflowsStates } from './store/features/workflowsSlice/workflows.actions'
import { getEnumDetails } from './store/features/references/references.actions'
import { useTranslation } from 'react-i18next'

function App() {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const auth = useSelector((state) => state.auth.isAuthenticated)
  const role = useSelector((state) => state.auth.role)
  const companyId = useSelector((state) => state.auth.companyId)
  const refreshToken = useSelector((state) => state.auth.refreshToken)
  const [authChecked, setAuthChecked] = useState(false)
  const [userInfoReceived, setUserInfoReceived] = useState(false)
  const [devModal, setDevModal] = useState(false)

  useEffect(() => {
    dispatch(checkAuth())
    setAuthChecked(true)
  }, [])

  const getCarWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.carWorkflows).then((response) => {
      dispatch(setCarWorkflows(response.data['hydra:member']))
    })
  }

  const getAuctionWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.auctionWorkflows).then((response) => {
      dispatch(setAuctionWorkflows(response.data['hydra:member']))
    })
  }

  const getDealWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.dealWorkflows).then((response) => {
      dispatch(setDealWorkflows(response.data['hydra:member']))
    })
  }

  const getInsuranceWorkflows = () => {
    dispatch(getWorkflowsStates({}))
  }

  const getCoinPricings = () => {
    privateRequest.get(API_ENDPOINTS.coinPricings).then((response) => {
      dispatch(
        setCoinPricings(
          response.data['hydra:member'].filter(
            (item) => !excludedPricings.filter((pricing) => pricing === item.identifier)[0],
          ),
        ),
      )
    })
  }

  const getUser = () => {
    privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
      if (response.data.avatar) {
        dispatch(setAvatar(`${CDN_BASE_URL}${response.data.avatar.avatar.path}`))
      } else {
        dispatch(setAvatar(Avatar1))
      }
      if (role !== 'OPERATOR' && role !== 'EXPERT') {
        dispatch(setCompany(response.data.manager.company))
      }
      if (role === 'COMPANY') {
        dispatch(setBalance(response.data.manager.company.coinBalance.balance))
        dispatch(setUser(response.data))
      }
      if (response.data?.locale) {
        privateRequest.defaults.headers['Accept-Language'] = response.data?.locale
        privateMultipartRequest.defaults.headers['Accept-Language'] = response.data?.locale
        patchRequest.defaults.headers['Accept-Language'] = response.data?.locale
      }
      setUserInfoReceived(true)
    })
  }

  const getUnreadNotificationsCount = () => {
    privateRequest.get(API_ENDPOINTS.notificationLogs).then((response) => {
      dispatch(
        setUnreadNotificationsCount(
          response.data['hydra:member'].filter((item) => !item.readAt).length,
        ),
      )
    })
  }

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  useEffect(() => {
    if (authChecked && auth) {
      if (role && role === 'COMPANY' && auth) {
        getUser()
      }
      if (role && role === 'OPERATOR' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
      }
      if (role && role === 'EXPERT' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
      }

      if (role && role === 'ADMIN' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
      }
    }
  }, [authChecked, auth, role])

  useEffect(() => {
    if (userInfoReceived && auth) {
      getCarWorkflows()
      getAuctionWorkflows()
      getDealWorkflows()
      getAuctionsAmount()
      getUnreadNotificationsCount()
      getCoinPricings()
      getInsuranceWorkflows()
    }
  }, [userInfoReceived, auth])

  useEffect(() => {
    if (userInfoReceived && auth) {
      dispatch(getEnumDetails())
    }
  }, [userInfoReceived, auth])

  return (
    <BrowserRouter>
      <ScrollToTop />
      {(authChecked && auth && userInfoReceived) || (authChecked && !auth) ? <AppRouter /> : null}
      <DevModal open={devModal} setOpen={setDevModal} />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
