import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { updateCompanySettings, updateCompanyAddress } from '../../store/features/authSlice'
import { API_ENDPOINTS } from '../../config/default'
import TextFieldEditable from '../../ui/TextFieldEditable'
import { useDispatch, useSelector } from 'react-redux'
import { privateRequest } from '../../utils/request'
import TextNormal from '../../ui/Text/TextNormal'
import TradeLicenseSettings from './TradeLicenseSettings'
import { countries } from '../../default-data/coutries'
import AutocompleteEditable from '../../ui/AutocompleteEditable'
import { emirates } from '../../config/formsConfig'

const CompanySettings = () => {
  const dispatch = useDispatch()
  const companySettings = useSelector((state) => state.auth.companySettings)
  const companyAddress = useSelector((state) => state.auth.companyAddress)
  const user = useSelector((state) => state.auth.user)

  const [fieldsLoading, setFieldsLoading] = useState([])

  const handleSubmit = (name, endPoint, value) => {
    if (Boolean(fieldsLoading.find((item) => item === name))) return
    setFieldsLoading([...fieldsLoading, name])
    const formData = {}
    formData[name] = value
    privateRequest.put(endPoint, formData).then(() => {
      setFieldsLoading(fieldsLoading.filter((item) => item !== name))
    })
  }

  return (
    <Grid container spacing={'24px'}>
      <Grid item xs={12} sm={6} md={3}>
        <TextFieldEditable
          fullWidth
          label={'Company name'}
          value={companySettings.name}
          onChange={(e) => {
            dispatch(
              updateCompanySettings({
                ...companySettings,
                name: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit('name', API_ENDPOINTS.companyCredentials, companySettings.name)
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'name'))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextFieldEditable
          fullWidth
          label={'Landline number'}
          value={companySettings?.landlineNumber?.replace('971', '')}
          onChange={(e) => {
            dispatch(
              updateCompanySettings({
                ...companySettings,
                landlineNumber: `971${e.target.value}`,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'landlineNumber',
              API_ENDPOINTS.companyCredentials,
              companySettings.landlineNumber,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'landlineNumber'))}
          InputProps={{
            startAdornment: (
              <TextNormal
                sx={{
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  mr: '5px',
                  position: 'relative',
                  lineHeight: 'normal',
                }}
              >
                + 971
              </TextNormal>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextFieldEditable
          fullWidth
          label={'Trade license number'}
          value={companySettings.tradeLicenseNumber}
          onChange={(e) => {
            dispatch(
              updateCompanySettings({
                ...companySettings,
                tradeLicenseNumber: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'tradeLicenseNumber',
              API_ENDPOINTS.companyCredentials,
              companySettings.tradeLicenseNumber,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'tradeLicenseNumber'))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TradeLicenseSettings />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextFieldEditable
          fullWidth
          label={'Tax registration number'}
          value={companySettings.taxRegistrationNumber}
          onChange={(e) => {
            dispatch(
              updateCompanySettings({
                ...companySettings,
                taxRegistrationNumber: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'taxRegistrationNumber',
              API_ENDPOINTS.companyCredentials,
              companySettings.taxRegistrationNumber,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'taxRegistrationNumber'))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AutocompleteEditable
          label={'Country'}
          options={[
            {
              label: '',
            },
            ...countries,
          ]}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(props, option) => {
            if (!option.label) return null
            return (
              <Box
                component="li"
                sx={{
                  '& > img': {
                    mr: 2,
                    flexShrink: 0,
                  },
                }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label}
              </Box>
            )
          }}
          fullWidth
          autoHighlight
          value={countries.find((item) => item.label === companyAddress.country) || ''}
          onChange={(e, newVal) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                country: newVal?.label,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit('country', user?.manager?.company?.address['@id'], companyAddress.country)
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'country'))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AutocompleteEditable
          label={companyAddress.country === 'United Arab Emirates' ? 'Emirate' : 'State'}
          options={companyAddress.country === 'United Arab Emirates' ? emirates : []}
          fullWidth
          autoHighlight
          freeSolo
          value={companyAddress.state}
          onChange={(e, newVal) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                state: newVal,
              }),
            )
          }}
          onChangeInput={(e) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                state: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit('state', user?.manager?.company?.address['@id'], companyAddress.state)
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'state'))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextFieldEditable
          fullWidth
          label={'Postcode'}
          value={companyAddress.postcode}
          onChange={(e) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                postcode: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'postcode',
              user?.manager?.company?.address['@id'],
              companyAddress.postcode,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'postcode'))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldEditable
          fullWidth
          label={'Address line 1'}
          value={companyAddress.addressLine1}
          onChange={(e) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                addressLine1: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'addressLine1',
              user?.manager?.company?.address['@id'],
              companyAddress.addressLine1,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'addressLine1'))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldEditable
          fullWidth
          label={'Address line 2'}
          value={companyAddress.addressLine2}
          onChange={(e) => {
            dispatch(
              updateCompanyAddress({
                ...companyAddress,
                addressLine2: e.target.value,
              }),
            )
          }}
          handleSubmit={() => {
            handleSubmit(
              'addressLine2',
              user?.manager?.company?.address['@id'],
              companyAddress.addressLine2,
            )
          }}
          loading={Boolean(fieldsLoading.find((item) => item === 'addressLine2'))}
        />
      </Grid>
    </Grid>
  )
}

export default CompanySettings
