import { MenuItem } from '@mui/material'
import React from 'react'
import { cleanObject } from '../../utils/helpers/common.helpers'
import { CAR_ENUMS } from '../../utils/constants/enums.constants'

export const getCoreValuesFormData = (data) => {
  return {
    vin: data.vin.value,
    make: data.make.id,
    model: data.model.id,
  }
}

export const getMainValuesFormData = (data) => {
  const formData = {
    ...data,
    mileage: parseInt(data.mileage),
    engineType: parseInt(data.engineType),
    engineSize: parseInt(data.engineSize),
    bodyType: parseInt(data.bodyType),
    origin: parseInt(data.origin?.value),
    driveType: parseInt(data.driveType),
    gearbox: parseInt(data.gearbox),
    seatsNumber: parseInt(data.seatsNumber),
    doorsNumber: parseInt(data.seatsNumber),
    type: data?.type !== null ? parseInt(data?.type) : null,
    weight: data?.weight !== '' ? parseInt(data?.weight) : null,
    hp: data?.hp !== '' ? parseInt(data?.hp) : null,
    cylinders: data?.cylinders !== null ? parseInt(data?.cylinders) : null,
    gearsNumber: data?.gearsNumber !== null ? parseInt(data?.gearsNumber) : null,
    rims: data?.rims !== null ? parseInt(data?.rims) : null,
    fuelConsumption:
      data?.fuelConsumption !== null && data?.fuelConsumption !== ''
        ? parseFloat(data?.fuelConsumption)
        : null,
    electricityConsumption:
      data?.electricityConsumption !== null && data?.electricityConsumption !== ''
        ? parseFloat(data?.electricityConsumption)
        : null,
    CO2Emission:
      data?.CO2Emission !== null && data?.CO2Emission !== '' ? parseFloat(data?.CO2Emission) : null,
    euroNorm: data?.euroNorm !== null && data?.euroNorm !== '' ? parseInt(data?.euroNorm) : null,
    upholstery: data?.upholstery !== null ? parseInt(data?.upholstery) : null,
  }
  return cleanObject(formData)
  // const formData = {
  //   ...carMainData,
  //   vin: carFirstData.vin.value,
  //   make: carFirstData.make.id,
  //   model: carFirstData.model.id,
  //   engineType: carMainData.engineType?.systemName,
  //   mileage: carMainData.mileage !== '' ? parseFloat(carMainData.mileage) : 0,
  //   hp: carMainData.hp ? parseFloat(carMainData.hp) : null,
  //   gearsNumber: carMainData.gearsNumber ? parseFloat(carMainData.gearsNumber) : 0,
  //   seatsNumber: carMainData.seatsNumber ? parseFloat(carMainData.seatsNumber) : 0,
  //   doorsNumber: doorsNumber,
  //   weight: carMainData.weight ? parseFloat(carMainData.weight) : 0,
  //   bookingValue: carMainData.bookingValue ? parseFloat(carMainData.bookingValue) : 0,
  //   // newPriceWithOptions: carMainData.newPriceWithOptions
  //   //   ? parseFloat(carMainData.newPriceWithOptions)
  //   //   : 0,
  //   paintwork: paintwork,
  //   cylinder: carMainData.cylinder ? carMainData.cylinder.toString() : '0',
  //   CO2Emission: carMainData.CO2Emission ? parseFloat(carMainData.CO2Emission) : 0,
  //   fuelConsumption: carMainData.fuelConsumption ? carMainData.fuelConsumption.toString() : '0',
  //   energyEfficiencyCategory: carMainData.energyEfficiencyCategory,
  //   euroNorm: carMainData.euroNorm,
  //   carHiddenPriceShow: carMainData.carHiddenPriceShow,
  //   origin: carMainData.origin?.systemName,
  //   bodyType: carMainData.bodyType?.systemName,
  //   gearbox: carMainData.gearbox?.systemName,
  //   driveType: carMainData.driveType?.systemName,
  //   rims: carMainData.rims?.systemName,
  // }
  // const doorsNumber =
  //   carMainData.doorsNumber !== '' ? parseFloat(carMainData.doorsNumber.replace(/\D/g, '')) : 0
  // let paintwork = ''
  // if (carMainData.paintwork.length > 0) {
  //   for (let i = 0; i < carMainData.paintwork.length; i++) {
  //     paintwork += carMainData.paintwork[i]
  //     if (i !== carMainData.paintwork.length - 1) {
  //       paintwork += '_'
  //     }
  //   }
  // }
}

export const getUpholsteryCheckboxOptions = (enumDetails) => {
  if (!enumDetails) return []
  const caseDetails = enumDetails?.find(
    (item) => item?.name === CAR_ENUMS.CAR_UPHOLSTERY,
  )?.caseDetails
  if (!caseDetails) return []
  const filteredCaseDetails = caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  return filteredCaseDetails ?? []
}

export const seatsNumberOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '7+',
  },
]

export const doorsNumberOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  {
    value: '3',
    label: '2 / 3',
  },
  {
    value: '4',
    label: '2 / 4',
  },
  {
    value: '5',
    label: '4 / 5',
  },
  {
    value: '6',
    label: '4 / 6',
  },
  {
    value: '7',
    label: '6 / 7',
  },
]

export const wheelLocationOptions = [
  {
    label: 'steering_wheel_location_left',
    value: 'left',
  },
  {
    label: 'steering_wheel_location_right',
    value: 'right',
  },
]

// ['', '0', '1', '2', '3', '4', '5', '6', '8', '10', '12']
export const cylindersOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '12',
    label: '12',
  },
]

export const gearsNumberOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
]

export const fuelConsumptionOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  { value: '2.0', label: '2.0 L / 100 km' },
  { value: '2.5', label: '2.5 L / 100 km' },
  { value: '3.0', label: '3.0 L / 100 km' },
  { value: '3.5', label: '3.5 L / 100 km' },
  { value: '4.0', label: '4.0 L / 100 km' },
  { value: '4.5', label: '4.5 L / 100 km' },
  { value: '5.0', label: '5.0 L / 100 km' },
  { value: '5.5', label: '5.5 L / 100 km' },
  { value: '6.0', label: '6.0 L / 100 km' },
  { value: '6.5', label: '6.5 L / 100 km' },
  { value: '7.0', label: '7.0 L / 100 km' },
  { value: '7.5', label: '7.5 L / 100 km' },
  { value: '8.0', label: '8.0 L / 100 km' },
  { value: '8.5', label: '8.5 L / 100 km' },
  { value: '9.0', label: '9.0 L / 100 km' },
  { value: '9.5', label: '9.5 L / 100 km' },
  { value: '10.0', label: '10.0 L / 100 km' },
  { value: '10.5', label: '10.5 L / 100 km' },
  { value: '11.0', label: '11.0 L / 100 km' },
  { value: '11.5', label: '11.5 L / 100 km' },
  { value: '12.0', label: '12.0 L / 100 km' },
  { value: '13.0', label: '13.0 L / 100 km' },
  { value: '14.0', label: '14.0 L / 100 km' },
  { value: '15.0', label: '15.0 L / 100 km' },
  { value: '16.0', label: '16.0 L / 100 km' },
  { value: '18.0', label: '18.0 L / 100 km' },
  { value: '20.0', label: '20.0 L / 100 km' },
  { value: '25.0', label: '25.0 L / 100 km' },
]

export const CO2EmissionOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  { value: '50', label: '50 g / km' },
  { value: '60', label: '60 g / km' },
  { value: '70', label: '70 g / km' },
  { value: '80', label: '80 g / km' },
  { value: '90', label: '90 g / km' },
  { value: '100', label: '100 g / km' },
  { value: '110', label: '110 g / km' },
  { value: '120', label: '120 g / km' },
  { value: '130', label: '130 g / km' },
  { value: '140', label: '140 g / km' },
  { value: '150', label: '150 g / km' },
  { value: '160', label: '160 g / km' },
  { value: '170', label: '170 g / km' },
  { value: '180', label: '180 g / km' },
  { value: '190', label: '190 g / km' },
  { value: '200', label: '200 g / km' },
  { value: '210', label: '210 g / km' },
  { value: '220', label: '220 g / km' },
  { value: '230', label: '230 g / km' },
  { value: '240', label: '240 g / km' },
  { value: '250', label: '250 g / km' },
  { value: '260', label: '260 g / km' },
  { value: '280', label: '280 g / km' },
  { value: '300', label: '300 g / km' },
  { value: '320', label: '320 g / km' },
  { value: '340', label: '340 g / km' },
  { value: '360', label: '360 g / km' },
  { value: '380', label: '380 g / km' },
  { value: '400', label: '400 g / km' },
  { value: '420', label: '420 g / km' },
  { value: '440', label: '440 g / km' },
  { value: '460', label: '460 g / km' },
  { value: '480', label: '480 g / km' },
  { value: '500', label: '500 g / km' },
  { value: '520', label: '520 g / km' },
  { value: '540', label: '540 g / km' },
  { value: '560', label: '560 g / km' },
]

export const euroNormOptions = [
  {
    value: '',
    label: 'common_not_chosen',
  },
  {
    value: '1',
    label: 'Euro norm 1',
  },
  {
    value: '2',
    label: 'Euro norm 2',
  },
  {
    value: '3',
    label: 'Euro norm 3',
  },
  {
    value: '4',
    label: 'Euro norm 4',
  },
  {
    value: '5',
    label: 'Euro norm 5',
  },
  {
    value: '6',
    label: 'Euro norm 6',
  },
]
