import React from 'react'
import InputLabelTFS from '../../CustomSelect/InputLabelTFS'
import SelectTFS from '../../CustomSelect/SelectTFS'
import { FormHelperText, MenuItem, Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import FormControlTFS from '../../CustomSelect/FormControlTFS'
import { useTranslation } from 'react-i18next'
import UISelectOptionWithIcon from './UISelectOptionWithIcon'
import UITextRegular from '../../UIText/UITextRegular'

const UIFormInputSelect = ({
  required,
  error,
  formControlProps,
  label,
  id,
  name,
  options,
  showHelperText,
  helperText,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <FormControlTFS fullWidth id={id} error={error} {...formControlProps}>
      <InputLabelTFS>{t(label)}</InputLabelTFS>
      <SelectTFS name={name} {...props}>
        {options.map((item, index) => {
          if (item?.icon) {
            return (
              <MenuItem
                key={item?.value}
                sx={{
                  '& img': {
                    width: '35px',
                  },
                }}
                value={item?.value}
              >
                <Stack direction="row" alignItems="center" gap="8px">
                  <img src={item?.icon} alt={item?.label} />
                  <UITextRegular text={item?.label} />
                </Stack>
              </MenuItem>
            )
          }
          return (
            <MenuItem key={index} value={item.value}>
              {t(item.label)}
            </MenuItem>
          )
        })}
      </SelectTFS>
      {showHelperText ? (
        <FormHelperText sx={{ color: colorsConfig.mainRed }}>{t(helperText)}</FormHelperText>
      ) : null}
    </FormControlTFS>
  )
}

export default UIFormInputSelect
