import carThumbnail1 from '../../assests/img/car-thumbnail-1.jpg'
import carThumbnail2 from '../../assests/img/car-thumbnail-2.jfif'
import carThumbnail3 from '../../assests/img/car-thumbnail-3.jpg'
import carThumbnail4 from '../../assests/img/car-thumbnail-4.jfif'

export const statusTheme = {
  readyForAppraisal: {
    label: 'Ready for Appraisal',
    color: '#0fa6f7',
  },
  submittedToAppraisal: {
    label: 'Submitted to Appraisal',
    color: '#fcdb03',
  },
  readyForAuction: {
    label: 'Ready for Auction',
    color: '#1ae89c',
  },
  inAuction: {
    label: 'In Auction',
    color: '#1a9166',
  },
  auctionEnded: {
    label: "Auction Ended (waiting for seller's approval)",
    color: '#f56f42',
  },
  waitingForPayment: {
    label: 'Waiting for Payment',
    color: '#e9f542',
  },
  inDelivery: {
    label: 'In Delivery',
    color: '#42f5e3',
  },
  sold: {
    label: 'Sold at Autrada Auction',
    color: '#42f587',
  },
  archived: {
    label: 'Archived',
    color: '#cee0d5',
  },
}

export const filters = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: statusTheme.readyForAppraisal.label,
    label: 'Newly Registered',
  },
  {
    value: statusTheme.submittedToAppraisal.label,
    label: statusTheme.submittedToAppraisal.label,
  },
  {
    value: statusTheme.readyForAuction.label,
    label: statusTheme.readyForAuction.label,
  },
  {
    value: statusTheme.inAuction.label,
    label: statusTheme.inAuction.label,
  },
  {
    value: statusTheme.auctionEnded.label,
    label: statusTheme.auctionEnded.label,
  },
  {
    value: statusTheme.waitingForPayment.label,
    label: statusTheme.waitingForPayment.label,
  },
  {
    value: statusTheme.inDelivery.label,
    label: statusTheme.inDelivery.label,
  },
  {
    value: statusTheme.sold.label,
    label: statusTheme.sold.label,
  },
  {
    value: statusTheme.archived.label,
    label: statusTheme.archived.label,
  },
]

export const tableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
    translation: 'table_col_number',
  },
  {
    title: 'Image',
    sortable: false,
    translation: 'table_col_image',
  },
  {
    title: 'Brand/Model',
    sortable: false,
    translation: 'table_col_brand_model',
  },
  {
    title: 'Location',
    sortable: false,
    translation: 'table_col_location',
  },
  {
    title: 'Days in stock',
    sortable: true,
    sortableAsc: '&order[createdAt]=asc',
    sortableDesc: '&order[createdAt]=desc',
    translation: 'table_col_days_in_stock',
  },
  {
    title: 'Status',
    sortable: false,
    translation: 'table_col_status',
  },
  {
    title: 'Action',
    sortable: false,
    translation: 'table_col_action',
  },
]

export const auctionTableCells = [
  {
    title: 'Reference Number',
    sortable: true,
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand, Model',
    sortable: true,
  },
  {
    title: 'Auction Start Time',
    sortable: true,
  },
  {
    title: 'Auction End Time',
    sortable: true,
  },
  {
    title: 'Highest Bid',
    sortable: true,
  },
  {
    title: 'Status',
    sortable: false,
  },
]

export const dealsTableCells = [
  {
    title: 'Reference Number',
    sortable: true,
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand, Model',
    sortable: true,
  },
  {
    title: 'Status',
    sortable: false,
  },
]

export const tableCellsContent = [
  {
    id: 1,
    body: [
      {
        type: 'text',
        content: '211212',
      },
      {
        type: 'image',
        content: carThumbnail1,
      },
      {
        type: 'text',
        content: 'MERCEDES-BENZ B 200 NIGHT STAR',
      },
      {
        type: 'text',
        content: '2016',
      },
      {
        type: 'text',
        content: 'Your Garage',
      },
      {
        type: 'text',
        content: '21/09/2022 14:21',
      },
      {
        type: 'status',
        color: statusTheme.readyForAppraisal.color,
        content: statusTheme.readyForAppraisal.label,
      },
    ],
  },
  {
    id: 2,
    body: [
      {
        type: 'text',
        content: '775321',
      },
      {
        type: 'image',
        content: carThumbnail2,
      },
      {
        type: 'text',
        content: 'VOLVO XC60 2.0 B5 MH R-DESIGN',
      },
      {
        type: 'text',
        content: '2019',
      },
      {
        type: 'text',
        content: "Insercle Middleeast LLC's garage",
      },
      {
        type: 'text',
        content: '21/09/2022 14:21',
      },
      {
        type: 'status',
        color: statusTheme.submittedToAppraisal.color,
        content: statusTheme.submittedToAppraisal.label,
      },
    ],
  },
  {
    id: 3,
    body: [
      {
        type: 'text',
        content: '392212',
      },
      {
        type: 'image',
        content: carThumbnail3,
      },
      {
        type: 'text',
        content: 'TOYOTA C-HR 1.8 HSD PREMIUM CVT',
      },
      {
        type: 'text',
        content: '2020',
      },
      {
        type: 'text',
        content: "Insercle Middleeast LLC's garage",
      },
      {
        type: 'text',
        content: '19/01/2022 15:02',
      },
      {
        type: 'status',
        color: statusTheme.submittedToAppraisal.color,
        content: statusTheme.submittedToAppraisal.label,
      },
    ],
  },
  {
    id: 4,
    body: [
      {
        type: 'text',
        content: '000438',
      },
      {
        type: 'image',
        content: carThumbnail4,
      },
      {
        type: 'text',
        content: 'RENAULT CLIO 1.0 ZEN',
      },
      {
        type: 'text',
        content: '2022',
      },
      {
        type: 'text',
        content: 'Your Garage',
      },
      {
        type: 'text',
        content: '09/02/2022 00:58',
      },
      {
        type: 'status',
        color: statusTheme.waitingForPayment.color,
        content: statusTheme.waitingForPayment.label,
      },
    ],
  },
]
