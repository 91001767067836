import React, { useEffect, useState } from 'react'
import { Box, IconButton, Autocomplete } from '@mui/material'
import TextFieldSmallEdible from './TextFieldSmallEdible'
import IconEdit from '../assests/img/icons/icon-edit.svg'
import IconCheckmark from '../assests/img/icons/icon-checkbox.svg'
import LDSRoller from '../components/Preloaders/LDSRoller'
import TextNormal from './Text/TextNormal'
import { colorsConfig } from '../config/themeConfig'
import TextFieldSmall from './TextFieldSmall'

const AutocompleteEditable = (props) => {
  const { label, loading, handleSubmit, value, onChange, ...rest } = props

  const [editMode, setEditMode] = useState(false)
  const [saved, setSaved] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const [inputRef, setInputRef] = useState(null)

  useEffect(() => {
    if (!loading && firstRender) {
      setSaved(true)
      setTimeout(() => {
        setSaved(false)
      }, 1500)
    }
    if (!firstRender) setFirstRender(true)
  }, [loading])

  useEffect(() => {
    if (editMode) {
      inputRef.focus()
      inputRef.select()
    }
  }, [editMode])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Autocomplete
        value={value}
        onChange={onChange}
        disabled={!editMode}
        sx={{
          '& .MuiInputBase-adornedEnd': {
            pr: '20px !important',
          },
          '& .MuiAutocomplete-endAdornment': {
            pointerEvents: 'none',
            opacity: 0,
          },
        }}
        renderInput={(params) => (
          <TextFieldSmall
            {...params}
            label={label}
            inputRef={(input) => {
              setInputRef(input)
            }}
            onChange={rest.freeSolo ? rest.onChangeInput : null}
          />
        )}
        {...rest}
      />
      <TextNormal
        className={saved ? 'active' : ''}
        sx={{
          color: colorsConfig.mainGreen,
          position: 'absolute',
          top: '100%',
          left: '14px',
          fontSize: '12px',
          opacity: 0,
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        Saved
      </TextNormal>
      <Box
        sx={{
          position: 'absolute',
          right: '0',
          top: '0',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          pr: '5px',
        }}
      >
        {loading ? (
          <LDSRoller />
        ) : editMode ? (
          <IconButton
            className={'edit-submit'}
            sx={{
              borderRadius: '6px',
              position: 'relative',
              width: '32px',
              height: '32px',
            }}
            onClick={() => {
              setEditMode(false)
              handleSubmit()
            }}
          >
            <img
              src={IconCheckmark}
              alt=""
              style={{
                width: '15px',
              }}
            />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              borderRadius: '6px',
            }}
            onClick={() => {
              setEditMode(true)
            }}
          >
            <img src={IconEdit} alt="" />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default AutocompleteEditable
