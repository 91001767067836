import React from 'react'
import { Box, Button, Tab, Tabs, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const AuctionsTabs = (props) => {
  const { tab, setTab, sections, renderTabTitle } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  return (
    <Box
      className={tabletOnly ? 'tablet-only' : ''}
      sx={{
        '&.tablet-only': {
          width: '100%',
        },
      }}
    >
      {xsOnly ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            width: 'calc(100% + 4px)',
          }}
        >
          {sections.map((section, index) => {
            return (
              <Button
                key={index}
                className={index === tab ? 'active' : ''}
                sx={{
                  background: colorsConfig.bgLightBlue,
                  color: colorsConfig.mainBlack,
                  position: 'relative',
                  height: '48px',
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  mb: '4px',
                  mr: '4px',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  '&::after': {
                    content: '" "',
                    display: 'block',
                    width: '100%',
                    height: '3px',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    background: colorsConfig.mainGreen,
                    transformOrigin: '50% 50%',
                    transform: 'scale(0)',
                    transition: '0.25s all ease-in-out',
                  },
                  '&.active': {
                    background: colorsConfig.bgLightBlue,
                    color: colorsConfig.mainBlack,
                    '&::after': {
                      transform: 'scale(1)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                }}
                onClick={() => {
                  setTab(index)
                }}
              >
                {renderTabTitle(section.title)}
              </Button>
            )
          })}
        </Box>
      ) : (
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
          fullWidth={tabletOnly}
          variant="scrollable"
          sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
          }}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={index}
                label={renderTabTitle(section.title)}
                className={tabletOnly ? 'tablet-only' : ''}
                sx={{
                  fontSize: '13px',
                  '&.tablet-only': {
                    flex: 1,
                  },
                }}
              />
            )
          })}
        </Tabs>
      )}
    </Box>
  )
}

export default AuctionsTabs
