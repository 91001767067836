import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIPreloaderCircular = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        ...sx,
      }}
      {...props}
    >
      <CircularProgress
        size={60}
        sx={{
          '.css-176wh8e-MuiCircularProgress-circle': {
            color: colorsConfig.mainBlue,
          },
          margin: '0 8px',
        }}
      />
    </Box>
  )
}

export default UIPreloaderCircular
