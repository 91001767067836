export const getCarEnumOptionsList = (carEnum, enumDetails) => {
  if (!enumDetails) return []
  const currentEnumCase = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnumCase) return []
  const caseDetails = currentEnumCase?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  if (!caseDetails) return []
  const options = caseDetails.map((item) => ({
    label: item?.readableValue,
    value: item?.value,
  }))
  return [
    {
      value: '',
      label: 'common_not_chosen',
    },
    ...options,
  ]
}

export const getCarEnumOptionsListWithIcons = (carEnum, enumDetails) => {
  if (!enumDetails) return []
  const currentEnumCase = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnumCase) return []
  const caseDetails = currentEnumCase?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  if (!caseDetails) return []
  const options = caseDetails.map((item) => ({
    label: item?.readableValue,
    value: item?.value,
    icon: item?.metadata?.iconUrl,
  }))
  return [
    {
      value: '',
      label: 'common_not_chosen',
    },
    ...options,
  ]
}
