import React from 'react'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { AccountCircleRounded, Close } from '@mui/icons-material'
import IconSettings from '../assests/img/icons/icon-settings.svg'
import IconHelp from '../assests/img/icons/icon-help.svg'
import { colorsConfig } from '../config/themeConfig'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { menuConfig, operatorMenuConfig, expertMenuConfig } from '../config/menuConfig'
import { logOut } from '../store/features/authSlice'
import TextNormal from '../ui/Text/TextNormal'
import TextBoldNormal from '../ui/Text/TextBoldNormal'
import { COMPANY_ADDRESS, COMPANY_PHONE, MAIN_EMAIL } from '../config/configMainInfo'
import UITextLabelMedium from '../ui/UIText/UITextLabelMedium'
import NoTranslate from '../NoTranslate'
import UITextBodySmall from '../ui/UIText/UITextBodySmall'

const HeaderMobileMenu = (props) => {
  const { open, setOpen } = props

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const avatar = useSelector((state) => state.auth.avatar)
  const login = useSelector((state) => state.auth.login)
  const balance = useSelector((state) => state.auth.balance)
  const role = useSelector((state) => state.auth.role)
  const auctionCarsAmount = useSelector((state) => state.auth.auctionCarsAmount)
  const unreadNotificationsCount = useSelector((state) => state.auth.unreadNotificationsCount)

  return (
    <Box
      className={open ? 'open' : ''}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: colorsConfig.bgLightBlue,
        zIndex: 999999,
        pt: '30px',
        pl: '44px',
        pr: '10px',
        pb: '20px',
        opacity: 0,
        pointerEvents: 'none',
        overflowY: 'auto',
        transformOrigin: '50% 50%',
        transform: 'scale(0.5)',
        transition: '0.4s all ease-in-out',
        '&.open': {
          pointerEvents: 'auto',
          opacity: 1,
          transform: 'scale(1)',
          transition: '0.4s all ease-in-out',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
              onClick={() => {
                setOpen(false)
              }}
            >
              <Close
                style={{
                  fill: colorsConfig.mainRed,
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => {
                setOpen(false)
                navigate('/profile-management')
              }}
            >
              <Box>
                {role && role === 'COMPANY' ? (
                  <Box
                    sx={{
                      width: '34px',
                      height: '34px',
                      border: `${colorsConfig.mainBlue} 2px solid`,
                      borderRadius: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={avatar}
                      alt=""
                      style={{
                        width: '100%',
                      }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <AccountCircleRounded
                      sx={{
                        fontSize: '30px',
                        position: 'relative',
                        bottom: '-2px',
                        fill: colorsConfig.iconGray,
                      }}
                    />
                  </Box>
                )}
              </Box>
              <TextBoldNormal sx={{ ml: '6px' }}>{login}</TextBoldNormal>
            </Button>
          </Box>
          <Box
            sx={{
              mt: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '6px',
            }}
          >
            {role === 'COMPANY' ? (
              <>
                {menuConfig.map((menuItem, index) => {
                  return (
                    <Box key={index}>
                      <Button
                        className={location.pathname === menuItem.link ? 'active' : ''}
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          position: 'relative',
                          '&::after': {
                            content: '" "',
                            position: 'absolute',
                            display: 'block',
                            height: '2px',
                            width: 'calc(100% - 12px)',
                            bottom: '4px',
                            left: '6px',
                            background: colorsConfig.mainGreen,
                            opacity: 0,
                            transformOrigin: '0% 50%',
                            transition: '0.3s all ease-in-out',
                            transform: 'scaleX(0)',
                          },
                          '&:hover': {
                            '&::after': {
                              opacity: 1,
                              transition: '0.3s all ease-in-out',
                              transform: 'scaleX(1)',
                            },
                          },
                          '&.active': {
                            '&::after': {
                              opacity: 1,
                              transform: 'scaleX(1)',
                            },
                          },
                        }}
                        onClick={() => {
                          navigate(menuItem.link)
                          setOpen(false)
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: colorsConfig.mainBlack,
                            fontWeight: 400,
                            fontSize: '16px',
                            fontFamily: '"Helvetica", sans-serif',
                          }}
                        >
                          {menuItem.title}
                        </Typography>
                        {menuItem.addons && menuItem.addons === 'auctionCarsAmount' ? (
                          <Typography
                            sx={{
                              ml: '3px',
                              minWidth: '28px',
                              height: '11px',
                              textAlign: 'center',
                              fontFamily: '"Helvetica", sans-serif',
                              fontSize: '10px',
                              fontWeight: 400,
                              background: colorsConfig.mainGreen,
                              borderRadius: '100px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'relative',
                              mb: '-1px',
                            }}
                          >
                            +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
                          </Typography>
                        ) : null}
                      </Button>
                    </Box>
                  )
                })}
              </>
            ) : null}
            {role === 'OPERATOR' ? (
              <>
                {operatorMenuConfig.map((menuItem, index) => {
                  if (menuItem.children) {
                    return (
                      <Box
                        key={index}
                        className={
                          Boolean(
                            menuItem.children.filter(
                              (item) =>
                                item.link === location.pathname ||
                                location.pathname.includes(item.link),
                            )[0],
                          )
                            ? 'submenu-open'
                            : ''
                        }
                        sx={{
                          '&:last-child': {
                            mb: 0,
                          },
                          transition: '0.25s all ease-in-out',
                          '&:hover': {
                            transition: '0.25s all ease-in-out',
                            '& .submenu': {
                              mt: 0,
                              opacity: 1,
                              transition: '0.25s all ease-in-out',
                            },
                          },
                          '&.submenu-open': {
                            transition: '0.25s all ease-in-out',
                            '& .submenu': {
                              mt: 0,
                              transform: 'scaleY(1) translateY(0%)',
                              opacity: 1,
                              transition: '0.25s all ease-in-out',
                            },
                          },
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            position: 'relative',
                            '&::after': {
                              content: '" "',
                              position: 'absolute',
                              display: 'block',
                              height: '2px',
                              width: 'calc(100% - 12px)',
                              bottom: '4px',
                              left: '6px',
                              background: colorsConfig.mainGreen,
                              opacity: 0,
                              transformOrigin: '0% 50%',
                              transition: '0.3s all ease-in-out',
                              transform: 'scaleX(0)',
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              color: colorsConfig.mainBlack,
                              fontSize: '16px',
                              fontFamily: '"Helvetica", sans-serif',
                            }}
                          >
                            {menuItem.title}
                          </Typography>
                        </Button>
                        <Box
                          sx={{
                            overflow: 'hidden',
                            position: 'relative',
                          }}
                        >
                          <Box
                            className={'submenu'}
                            sx={{
                              opacity: '0.2',
                              pl: '15px',
                              position: 'relative',
                              mt: 'calc(-100% - 60px)',
                              transition: '0.25s all ease-in-out',
                              '&::after': {
                                content: '" "',
                                display: 'block',
                                position: 'absolute',
                                height: 'calc(100% - 22px)',
                                width: '1px',
                                top: '11px',
                                left: '13px',
                                background: 'white',
                              },
                            }}
                          >
                            {menuItem.children.map((menuItemChild, index) => {
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    mb: '6px',
                                    '&:last-child': {
                                      mb: 0,
                                    },
                                  }}
                                >
                                  <Button
                                    className={
                                      location.pathname === menuItemChild.link ||
                                      location.pathname.includes(menuItemChild.link)
                                        ? 'active'
                                        : ''
                                    }
                                    sx={{
                                      textTransform: 'none',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      position: 'relative',
                                      '&::after': {
                                        content: '" "',
                                        position: 'absolute',
                                        display: 'block',
                                        height: '2px',
                                        width: 'calc(100% - 12px)',
                                        bottom: '4px',
                                        left: '6px',
                                        background: colorsConfig.mainGreen,
                                        opacity: 0,
                                        transformOrigin: '0% 50%',
                                        transition: '0.3s all ease-in-out',
                                        transform: 'scaleX(0)',
                                      },
                                      '&:hover': {
                                        '&::after': {
                                          opacity: 1,
                                          transition: '0.3s all ease-in-out',
                                          transform: 'scaleX(1)',
                                        },
                                      },
                                      '&.active': {
                                        '&::after': {
                                          opacity: 1,
                                          transform: 'scaleX(1)',
                                        },
                                      },
                                    }}
                                    onClick={() => {
                                      navigate(menuItemChild.link)
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: colorsConfig.mainBlack,
                                        fontWeight: 400,
                                        fontSize: '15px',
                                        fontFamily: '"Helvetica", sans-serif',
                                      }}
                                    >
                                      {menuItemChild.title}
                                    </Typography>
                                  </Button>
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      </Box>
                    )
                  }

                  return (
                    <Box key={index}>
                      <Button
                        className={location.pathname === menuItem.link ? 'active' : ''}
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          position: 'relative',
                          '&::after': {
                            content: '" "',
                            position: 'absolute',
                            display: 'block',
                            height: '2px',
                            width: 'calc(100% - 12px)',
                            bottom: '4px',
                            left: '6px',
                            background: colorsConfig.mainGreen,
                            opacity: 0,
                            transformOrigin: '0% 50%',
                            transition: '0.3s all ease-in-out',
                            transform: 'scaleX(0)',
                          },
                          '&:hover': {
                            '&::after': {
                              opacity: 1,
                              transition: '0.3s all ease-in-out',
                              transform: 'scaleX(1)',
                            },
                          },
                          '&.active': {
                            '&::after': {
                              opacity: 1,
                              transform: 'scaleX(1)',
                            },
                          },
                        }}
                        onClick={() => {
                          navigate(menuItem.link)
                          setOpen(false)
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: colorsConfig.mainBlack,
                            fontWeight: 400,
                            fontSize: '16px',
                            fontFamily: '"Helvetica", sans-serif',
                          }}
                        >
                          {menuItem.title}
                        </Typography>
                      </Button>
                    </Box>
                  )
                })}
              </>
            ) : null}
            {role === 'EXPERT' ? (
              <>
                {expertMenuConfig.map((menuItem, index) => {
                  return (
                    <Box key={index}>
                      <Button
                        className={location.pathname === menuItem.link ? 'active' : ''}
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          position: 'relative',
                          '&::after': {
                            content: '" "',
                            position: 'absolute',
                            display: 'block',
                            height: '2px',
                            width: 'calc(100% - 12px)',
                            bottom: '4px',
                            left: '6px',
                            background: colorsConfig.mainGreen,
                            opacity: 0,
                            transformOrigin: '0% 50%',
                            transition: '0.3s all ease-in-out',
                            transform: 'scaleX(0)',
                          },
                          '&:hover': {
                            '&::after': {
                              opacity: 1,
                              transition: '0.3s all ease-in-out',
                              transform: 'scaleX(1)',
                            },
                          },
                          '&.active': {
                            '&::after': {
                              opacity: 1,
                              transform: 'scaleX(1)',
                            },
                          },
                        }}
                        onClick={() => {
                          navigate(menuItem.link)
                          setOpen(false)
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: colorsConfig.mainBlack,
                            fontWeight: 400,
                            fontSize: '16px',
                            fontFamily: '"Helvetica", sans-serif',
                          }}
                        >
                          {menuItem.title}
                        </Typography>
                      </Button>
                    </Box>
                  )
                })}
              </>
            ) : null}
            {role === 'COMPANY' ? (
              <Box
                sx={{
                  mt: '20px',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  className={location.pathname === '/profile-management' ? 'active' : ''}
                  sx={{
                    textTransform: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    textAlign: 'center',
                    '&::after': {
                      content: '" "',
                      position: 'absolute',
                      display: 'block',
                      height: '2px',
                      width: 'calc(100% - 12px)',
                      bottom: '4px',
                      left: '6px',
                      background: colorsConfig.mainGreen,
                      opacity: 0,
                      transformOrigin: '0% 50%',
                      transition: '0.3s all ease-in-out',
                      transform: 'scaleX(0)',
                    },
                    '&:hover': {
                      '&::after': {
                        opacity: 1,
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(1)',
                      },
                    },
                    '&.active': {
                      '&::after': {
                        opacity: 1,
                        transform: 'scaleX(1)',
                      },
                    },
                  }}
                  onClick={() => {
                    navigate('/profile-management')
                    setOpen(false)
                  }}
                >
                  <img src={IconSettings} alt="" />
                  <TextNormal sx={{ ml: '6px' }}>Account Settings</TextNormal>
                </Button>
                <Button
                  onClick={() => {
                    navigate('/help')
                    setOpen(false)
                  }}
                  className={location.pathname === '/help' ? 'active' : ''}
                  sx={{
                    textTransform: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    textAlign: 'center',
                    '&::after': {
                      content: '" "',
                      position: 'absolute',
                      display: 'block',
                      height: '2px',
                      width: 'calc(100% - 12px)',
                      bottom: '4px',
                      left: '6px',
                      background: colorsConfig.mainGreen,
                      opacity: 0,
                      transformOrigin: '0% 50%',
                      transition: '0.3s all ease-in-out',
                      transform: 'scaleX(0)',
                    },
                    '&:hover': {
                      '&::after': {
                        opacity: 1,
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(1)',
                      },
                    },
                    '&.active': {
                      '&::after': {
                        opacity: 1,
                        transform: 'scaleX(1)',
                      },
                    },
                  }}
                >
                  <img
                    src={IconHelp}
                    alt=""
                    style={{
                      position: 'relative',
                      marginTop: '-2px',
                    }}
                  />
                  <TextNormal
                    sx={{
                      ml: '6px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    Fleatify help
                  </TextNormal>
                </Button>
              </Box>
            ) : null}
            <Box
              sx={{
                mt: '20px',
                position: 'relative',
              }}
            >
              <Button
                sx={{
                  textTransform: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  position: 'relative',
                  '&::after': {
                    content: '" "',
                    position: 'absolute',
                    display: 'block',
                    height: '2px',
                    width: 'calc(100% - 12px)',
                    bottom: '4px',
                    left: '6px',
                    background: colorsConfig.mainGreen,
                    opacity: 0,
                    transformOrigin: '0% 50%',
                    transition: '0.3s all ease-in-out',
                    transform: 'scaleX(0)',
                  },
                  '&:hover': {
                    '&::after': {
                      opacity: 1,
                      transition: '0.3s all ease-in-out',
                      transform: 'scaleX(1)',
                    },
                  },
                }}
                onClick={() => {
                  dispatch(logOut())
                  setOpen(false)
                }}
              >
                <TextNormal>Log out</TextNormal>
              </Button>
            </Box>
          </Box>
        </Box>
        <Stack gap="20px" justifyContent="center" sx={{ pl: '8px', mt: '30px', pb: '30px' }}>
          <Stack gap="10px">
            <UITextLabelMedium
              sx={{
                color: colorsConfig.lightGray,
              }}
            >
              <NoTranslate>Fleetify Ltd.</NoTranslate>
            </UITextLabelMedium>
            <UITextBodySmall sx={{ color: colorsConfig.lightGray }}>
              Al Sila Tower, Level 12 <br />
              Abu Dhabi Global Market Square <br />
              Al Maryah Island, Abu Dhabi <br />
              United Arab Emirates
            </UITextBodySmall>
            <UITextLabelMedium
              component={'a'}
              href={`tel:${COMPANY_PHONE.number}`}
              sx={{
                textDecoration: 'none',
                transition: '0.25s all ease-in-out',
                color: colorsConfig.lightGray,
                '&:hover': {
                  color: colorsConfig.mainBlue,
                  transition: '0.25s all ease-in-out',
                },
              }}
            >
              {COMPANY_PHONE.text}
            </UITextLabelMedium>
            <UITextLabelMedium
              component={'a'}
              href={`mailto:${MAIN_EMAIL}`}
              sx={{
                textDecoration: 'none',
                transition: '0.25s all ease-in-out',
                color: colorsConfig.lightGray,
                '&:hover': {
                  color: colorsConfig.mainBlue,
                  transition: '0.25s all ease-in-out',
                },
              }}
            >
              {MAIN_EMAIL}
            </UITextLabelMedium>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default HeaderMobileMenu
