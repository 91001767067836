import React, { useState, useEffect } from 'react'

const useValidation = (value, validations) => {
  const [isEmptyError, setIsEmptyError] = useState(true)
  const [hasErrors, setHasErrors] = useState(false)
  const [errorText, setErrorText] = useState('')
  const helperText = {
    isEmpty: 'This field can not be empty',
    emailError: 'Invalid email',
    chooseEmirate: 'You must choose emirate',
    phoneNumberUAEVarious: 'UAE phone number must have 8-9 digits',
  }

  useEffect(() => {
    let findErrors = false
    for (const validation in validations) {
      switch (validation) {
        case 'minLength':
          if (value.length < validations[validation] && value.length > 0) {
            findErrors = true
            setErrorText(`Minimum length is ${validations[validation]} characters`)
          }
          break
        case 'maxLength':
          if (value.length > validations[validation]) {
            findErrors = true
            setErrorText(`Maximum length is ${validations[validation]} characters`)
          }
          break
        case 'isEmail':
          if (
            !value.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ) &&
            value
          ) {
            findErrors = true
            setErrorText(helperText.emailError)
          }
          break
        case 'isEmpty':
          if (value) {
            setIsEmptyError(false)
          } else {
            setIsEmptyError(true)
            findErrors = true
            setErrorText(helperText.isEmpty)
          }
          break
        case 'isEmirateChosen':
          if (value === 'Not Chosen' || !value) {
            findErrors = true
            setErrorText(helperText.chooseEmirate)
          }
          break
        case 'phoneNumberUAE':
          if (value && value.length !== validations[validation]) {
            findErrors = true
            setErrorText(`Must have ${validations[validation]} digits after country code`)
          }
          break
        case 'minDate':
          if (
            value - validations.minDate < -86300000 ||
            (!Boolean(value - validations.minDate) && value !== null)
          ) {
            findErrors = true
            setErrorText('Invalid Date')
          }
          break
        case 'phoneNumberUAEVarious':
          if ((value && value?.length > 9) || value?.length < 8) {
            findErrors = true
            setErrorText(helperText.phoneNumberUAEVarious)
          }
      }
    }

    if (findErrors) {
      setHasErrors(true)
    } else {
      setHasErrors(false)
      setErrorText('')
    }
  }, [value])

  return {
    errorText,
    hasErrors,
  }
}

export default useValidation
