import React, { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { AssuredWorkloadRounded } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRviPolicy } from '../../../store/features/rviPolicy/rviPolicy.actions'
import { resetRviPolicy } from '../../../store/features/rviPolicy/rviPolicy.reducer'
import UIBreadcrumbs from '../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import UIHeaderSectionWrapper from '../../../ui/UIBoxes/UIHeaderSectionWrapper'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import RVIPolicySummary from './RVIPolicySummary'
import RVIPolicyDetails from './RVIPolicyDetails'
import { LOCAL_ROLE } from '../../../utils/constants/global.constants'
import RVIPolicyActionItems from './RVIPolicyActionItems'

const RVIPolicy = () => {
  const isLoading = useSelector((state) => state.rviPolicy.isLoading)
  const policy = useSelector((state) => state.rviPolicy.data)
  const role = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getRviPolicy({ policyId: params.id }))
    return () => {
      dispatch(resetRviPolicy())
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '300px',
        px: {
          xs: '16px',
          md: 0,
        },
      }}
    >
      {isLoading ? (
        <UIPreloader text="Loading the policy..." />
      ) : (
        <Stack gap="30px">
          <UIHeaderSectionWrapper>
            <Stack gap="30px">
              <UIBreadcrumbs
                pages={[
                  {
                    icon: <AssuredWorkloadRounded />,
                    name: 'RVI Policies',
                    link: role && role === LOCAL_ROLE.COMPANY ? '/rvi' : '/operator/rvi',
                  },
                  {
                    name: policy?.details?.policyNumber ?? '-',
                  },
                ]}
              />
              <RVIPolicySummary />
              <RVIPolicyActionItems />
            </Stack>
          </UIHeaderSectionWrapper>
          <RVIPolicyDetails />
        </Stack>
      )}
    </Box>
  )
}

export default RVIPolicy
