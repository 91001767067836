import { privateRequest } from '../../utils/request'

class CarService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      cars: '/cars',
      independentCarInfo: (id) => `/independent-car-info/${id}`,
      makes: '/reference/catalog/makes',
      models: '/reference/catalog/models',
      attachAppraisalDocument: (id) => `/cars/${id}/attach-appraisal-document`,
      workflows: {
        confirmToDraft: (id) => `/cars/${id}/workflow/confirm-to-draft`,
        sendToDraftCompany: (id) => `/cars/${id}/workflow/send-to-draft-company`,
      },
      enums: '/reference/enum-details',
      vinProvider: '/vin-provider/car-details',
    }
  }

  updateIndependentCarInfo = async (carId, data) => {
    const response = await this.#api.put(this.#endPoints.independentCarInfo(carId), data)
    return response
  }

  createCar = async (data) => {
    const response = await this.#api.post(this.#endPoints.cars, data)
    return response
  }

  updateCar = async (car, data) => {
    const response = await this.#api.put(car, data)
    return response
  }

  getMakes = async (params) => {
    const response = await this.#api.get(this.#endPoints.makes, {
      params,
    })
    return response
  }

  getModels = async (params) => {
    const response = await this.#api.get(this.#endPoints.models, {
      params,
    })
    return response
  }

  attachAppraisalDocument = async (carId, data) => {
    const response = await this.#api.put(this.#endPoints.attachAppraisalDocument(carId), data)
    return response
  }

  changeWorkflowConfirmToDraft = async (carId, data) => {
    const response = await this.#api.post(this.#endPoints.workflows.confirmToDraft(carId), data)
    return response
  }

  changeWorkflowSendToDraftCompany = async (carId) => {
    const response = await this.#api.post(this.#endPoints.workflows.sendToDraftCompany(carId), {})
    return response
  }

  getCarDetailsFromVIN = async (data) => {
    const response = await this.#api.post(this.#endPoints.vinProvider, data)
    return response
  }
}

const carService = new CarService({ api: privateRequest })

export default carService
