import React from 'react'
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'
import MainBackground from '../assests/img/main-background-header.png'
import MainBackgroundMobile from '../assests/img/main-background-mobile.png'
import { useSelector } from 'react-redux'
import TextBoldNormal from '../ui/Text/TextBoldNormal'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PageTitle = () => {
  const extraLarge = useMediaQuery('(min-width: 1400px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const { t } = useTranslation()

  const navigate = useNavigate()
  const currentLocation = useLocation().pathname

  const currentPageTitle = useSelector((state) => state.auth.currentPageTitle)

  return (
    <>
      {xsOnly || tabletOnly ? (
        <Box>
          <Box
            sx={{
              position: 'relative',
              height: '69px',
              overflow: 'hidden',
            }}
          >
            <img
              src={MainBackgroundMobile}
              alt=""
              style={{
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
                left: 0,
                bottom: '-78px',
              }}
            />
          </Box>
          <Box
            className={currentLocation === '/virtual-garage' ? 'centered' : ''}
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&.centered': {
                display: 'block',
              },
            }}
          >
            {currentLocation !== '/virtual-garage' ? (
              <IconButton
                variant="contained"
                sx={{ margin: '0 8px 6px 10px' }}
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="22"
                  viewBox="0 0 13 22"
                  fill="none"
                >
                  <path
                    d="M10.9998 20L2.70696 11.7071C2.31643 11.3166 2.31643 10.6834 2.70696 10.2929L10.9998 2"
                    stroke="#3D3D3D"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : null}
            <TextBoldNormal
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                mb: '20px',
                mt: '16px',
              }}
            >
              {t(currentPageTitle)}
            </TextBoldNormal>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            paddingTop: '129px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 'calc(100% + 121px)',
              left: '-23px',
              top: '-103px',
              zIndex: '-1',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                verticalAlign: 'top',
                height: '201px',
                overflow: 'hidden',
                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  background: `linear-gradient(90deg, ${colorsConfig.buttonHover} 0%, transparent 64%, transparent 100%)`,
                  top: 0,
                  left: 0,
                },
              }}
            >
              <img
                src={MainBackground}
                style={{
                  width: extraLarge ? '100%' : 'auto',
                  height: extraLarge ? 'auto' : '100%',
                  verticalAlign: 'top',
                }}
                alt=""
              />
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '40px',
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                left: 0,
                bottom: '35px',
                color: 'white',
              }}
            >
              {currentPageTitle}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default PageTitle
