import React, { useState } from 'react'
import TextFieldSmallEdible from '../../../../../ui/TextFieldSmallEdible'
import { Autocomplete } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TextFieldSmall from '../../../../../ui/TextFieldSmall'
import Box from '@mui/material/Box'
import { setImportedPolicyMatchedCompany } from '../../../../../store/features/rviOperator/rviOperator.reducer'

const ImportedPoliciesActionItems = ({ data }) => {
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const dispatch = useDispatch()

  const matchedCompany = data?.initialData?.matchedCompany

  return (
    <Box sx={{ minWidth: '250px' }}>
      <Autocomplete
        fullWidth
        id={`dealer-of-policy-${data?.initialData?.id}`}
        options={companies.map((item) => ({
          value: item,
          label: item?.name,
        }))}
        value={matchedCompany}
        onChange={(e, newVal) => {
          dispatch(
            setImportedPolicyMatchedCompany({ policyId: data?.initialData?.id, value: newVal }),
          )
        }}
        renderInput={(params) => <TextFieldSmall {...params} label="Select dealer" required />}
      />
    </Box>
  )
}

export default ImportedPoliciesActionItems
