import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Grid,
  Table,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Modal,
  Link,
  useMediaQuery,
} from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import { colorsConfig } from '../../../config/themeConfig'
import { OperatorTableHead, OperatorTableBody } from '../../OperatorPanel/OperatorTable'
import { statusCakeTableHeadCells } from '../DashboardTableData'
import { API_FINANCE_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import useAllCars from '../../../hooks/useAllCars'
import iconSearch from '../../../assests/img/icons/icon-search.svg'
import { useSelector } from 'react-redux'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { privateRequest } from '../../../utils/request'
import StatusCakeFinanceModal from './StatusCakeFinanceModal'
import { useTranslation } from 'react-i18next'

const {
  mainBlue,
  buttonHover,
  mainBlack,
  buttonDisabled,
  darkRed,
  buttonYellow,
  mainGreen,
  fadedBlue,
} = colorsConfig

const statusCakeModalCloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15 1.02344L8 8.02344M8 8.02344L1 15.0234M8 8.02344L1 1.02344M8 8.02344L15 15.0234"
      stroke="#AC0000"
      strokeWidth="2"
    />
  </svg>
)

export const DealerAppraisalStatusCake = ({ title }) => {
  const { t } = useTranslation()
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const { carWorkflowFilters, carWorkflowColours, loading, cars } = useAllCars()

  const {
    filterByAppraisalOnSite,
    filterByAppraisalOnCompound,
    filterByQualityCheck,
    filterByReadyForSale,
    filterByQualityCheckFailed,
  } = carWorkflowFilters

  const {
    getAppraisalOnSiteColour,
    getAppraisalOnCompoundColour,
    getQualityCheckColour,
    getReadyForSaleColour,
    getQualityCheckFailedColour,
  } = carWorkflowColours

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const appraisalOnSiteValue = filterByAppraisalOnSite().length
  const appraisalOnCompoundValue = filterByAppraisalOnCompound().length
  const qualityCheckValue = filterByQualityCheck().length
  const readyForSaleValue = filterByReadyForSale().length
  const qualityCheckFailedValue = filterByQualityCheckFailed().length
  const sumTotal =
    appraisalOnSiteValue +
    appraisalOnCompoundValue +
    qualityCheckValue +
    readyForSaleValue +
    qualityCheckFailedValue

  const [isModalOpen1, setIsModalOpen1] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [isModalOpen3, setIsModalOpen3] = useState(false)
  const [isModalOpen4, setIsModalOpen4] = useState(false)
  const [isModalOpen5, setIsModalOpen5] = useState(false)

  const handleStatusCakeModal1 = () => {
    setIsModalOpen1(true)
  }
  const handleStatusCakeModal2 = () => {
    setIsModalOpen2(true)
  }
  const handleStatusCakeModal3 = () => {
    setIsModalOpen3(true)
  }
  const handleStatusCakeModal4 = () => {
    setIsModalOpen4(true)
  }
  const handleStatusCakeModal5 = () => {
    setIsModalOpen5(true)
  }

  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
  }
  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }
  const handleCloseModal3 = () => {
    setIsModalOpen3(false)
  }
  const handleCloseModal4 = () => {
    setIsModalOpen4(false)
  }
  const handleCloseModal5 = () => {
    setIsModalOpen5(false)
  }

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            width: '100%',
            height: '370px',
            margin: 0,
            padding: '32px 27px 19px 36px',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              marginBottom: '20px',
              lineHeight: 'normal',
              width: 1,
            }}
          >
            {t('titles.blocks.status_cake_appraisal')}
          </Typography>
          <Box
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    '.css-176wh8e-MuiCircularProgress-circle': {
                      color: colorsConfig.mainBlue,
                    },
                    margin: '0 8px',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                  }}
                >
                  <PieChart
                    colors={[
                      getAppraisalOnSiteColour(),
                      getAppraisalOnCompoundColour(),
                      getQualityCheckColour(),
                      getReadyForSaleColour(),
                      getQualityCheckFailedColour(),
                    ]}
                    series={[
                      {
                        data: [
                          { value: appraisalOnSiteValue },
                          { value: appraisalOnCompoundValue },
                          { value: qualityCheckValue },
                          { value: readyForSaleValue },
                          { value: qualityCheckFailedValue },
                        ],
                        innerRadius: 78,
                        outerRadius: 100,
                        paddingAngle: 4,
                        cornerRadius: 50,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 100,
                        cy: 100,
                      },
                    ]}
                    width={210}
                    height={210}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                      {sumTotal}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                    >
                      total
                    </Typography>
                  </Box>
                </Box>
                <Grid
                  container
                  sx={{
                    width: 'fit-content',
                    gap: '5px',
                    flexDirection: 'column',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAppraisalOnCompoundColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Appraisal on site{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{appraisalOnSiteValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal1}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen1}
                        onClose={handleCloseModal1}
                        data={filterByAppraisalOnSite()}
                        title="Appraisals on site"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: buttonHover,
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Appraisal on compound{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{appraisalOnCompoundValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal2}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen2}
                        onClose={handleCloseModal2}
                        data={filterByAppraisalOnCompound()}
                        title="Appraisals on compound"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getQualityCheckColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Quality check in progress{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{qualityCheckValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal3}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen3}
                        onClose={handleCloseModal3}
                        data={filterByQualityCheck()}
                        title="Quality check in progress"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getReadyForSaleColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Ready for action{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{readyForSaleValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal4}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen4}
                        onClose={handleCloseModal4}
                        data={filterByReadyForSale()}
                        title="Ready for action"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getQualityCheckFailedColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Quality check failed{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{qualityCheckFailedValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal5}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen5}
                        onClose={handleCloseModal5}
                        data={filterByQualityCheckFailed()}
                        title="Quality check failed"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '100%',
            height: '350px',
            margin: 0,
            padding: '27px 27px 19px 24px',
            textAlign: 'center',
            '&.xs-only': {
              height: 'auto',
              px: '9px',
            },
            '&.tablet-only': {
              p: '16px',
              height: '300px',
            },
          }}
        >
          <Typography
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '32px',
              fontWeight: 700,
              marginBottom: '20px',
              lineHeight: 'normal',
              width: 1,
              '&.xs-only': {
                fontSize: '24px',
                mb: '11px',
                width: '100%',
                textAlign: 'left',
              },
              '&.tablet-only': {
                fontSize: '24px',
                width: '100%',
                textAlign: 'left',
              },
            }}
          >
            {t('titles.blocks.status_cake_appraisal')}
          </Typography>
          <Box
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
              '&.xs-only': {
                flexWrap: 'wrap',
                gap: '15px',
                height: 'auto',
              },
              '&.tablet-only': {
                gap: '15px',
                height: 'auto',
              },
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    '.css-176wh8e-MuiCircularProgress-circle': {
                      color: colorsConfig.mainBlue,
                    },
                    margin: '0 8px',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                  }}
                >
                  <PieChart
                    colors={[
                      getAppraisalOnSiteColour(),
                      getAppraisalOnCompoundColour(),
                      getQualityCheckColour(),
                      getReadyForSaleColour(),
                      getQualityCheckFailedColour(),
                    ]}
                    series={[
                      {
                        data: [
                          { value: appraisalOnSiteValue },
                          { value: appraisalOnCompoundValue },
                          { value: qualityCheckValue },
                          { value: readyForSaleValue },
                          { value: qualityCheckFailedValue },
                        ],
                        innerRadius: 55,
                        outerRadius: 70,
                        paddingAngle: 4,
                        cornerRadius: 50,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 70,
                        cy: 70,
                      },
                    ]}
                    width={150}
                    height={150}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#11263C' }}>
                      {sumTotal}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                    >
                      total
                    </Typography>
                  </Box>
                </Box>
                <Grid
                  container
                  className={tabletOnly ? 'tablet-only' : ''}
                  sx={{
                    width: 'fit-content',
                    gap: '5px',
                    flexDirection: 'column',
                    '&.tablet-only': {
                      height: '100%',
                      justifyContent: 'space-between',
                      gap: '10px',
                      '& p': {
                        fontSize: '12px',
                        textAlign: 'left',
                        lineHeight: 'normal',
                      },
                      '& .inline-marker': {
                        maxWidth: '15px',
                        mb: 0,
                        position: 'relative',
                        bottom: '-5px',
                      },
                      '& .inline-marker-wrapper': {
                        alignItems: 'flex-start',
                        gap: '7px',
                      },
                      '& .check-wrapper': {
                        ml: '22px',
                        mt: '5px',
                      },
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAppraisalOnCompoundColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Appraisal on site{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{appraisalOnSiteValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal1}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen1}
                        onClose={handleCloseModal1}
                        data={filterByAppraisalOnSite()}
                        title="Appraisals on site"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: buttonHover,
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Appraisal on compound{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{appraisalOnCompoundValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal2}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen2}
                        onClose={handleCloseModal2}
                        data={filterByAppraisalOnCompound()}
                        title="Appraisals on compound"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getQualityCheckColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Quality check in progress{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{qualityCheckValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal3}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen3}
                        onClose={handleCloseModal3}
                        data={filterByQualityCheck()}
                        title="Quality check in progress"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getReadyForSaleColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Ready for action{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{readyForSaleValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal4}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen4}
                        onClose={handleCloseModal4}
                        data={filterByReadyForSale()}
                        title="Ready for action"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getQualityCheckFailedColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Quality check failed{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{
                        display: 'flex',
                        gap: '4px',
                        marginLeft: '45px',
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{qualityCheckFailedValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal5}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen5}
                        onClose={handleCloseModal5}
                        data={filterByQualityCheckFailed()}
                        title="Quality check failed"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export const DealerAuctionStatusCake = ({ title }) => {
  const { t } = useTranslation()
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const { auctionWorkflowFilters, auctionWorkflowColours, loading } = useAllCars()

  const { filterByAuctionPending, filterByAuctionActive, filterByAuctionCompleted } =
    auctionWorkflowFilters

  const { getAuctionPendingColour, getAuctionActiveColour, getAuctionCompletedColour } =
    auctionWorkflowColours

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const auctionPendingValue = filterByAuctionPending().length
  const auctionActiveValue = filterByAuctionActive().length
  const auctionCompletedValue = filterByAuctionCompleted().length
  const sumTotal = auctionPendingValue + auctionActiveValue + auctionCompletedValue

  const [isModalOpen1, setIsModalOpen1] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [isModalOpen3, setIsModalOpen3] = useState(false)

  const handleStatusCakeModal1 = () => {
    setIsModalOpen1(true)
  }
  const handleStatusCakeModal2 = () => {
    setIsModalOpen2(true)
  }
  const handleStatusCakeModal3 = () => {
    setIsModalOpen3(true)
  }

  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
  }
  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }
  const handleCloseModal3 = () => {
    setIsModalOpen3(false)
  }

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            width: '100%',
            height: '370px',
            margin: 0,
            padding: '32px 27px 19px 36px',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              lineHeight: 'normal',
              marginBottom: '20px',
              width: 1,
            }}
          >
            {t('titles.blocks.status_cake_auction')}
          </Typography>
          <Box
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    '.css-176wh8e-MuiCircularProgress-circle': {
                      color: colorsConfig.mainBlue,
                    },
                    margin: '0 8px',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                  }}
                >
                  <PieChart
                    colors={[
                      getAuctionPendingColour(),
                      getAuctionActiveColour(),
                      getAuctionCompletedColour(),
                    ]}
                    series={[
                      {
                        data: [
                          { value: auctionPendingValue },
                          { value: auctionActiveValue },
                          { value: auctionCompletedValue },
                        ],
                        innerRadius: 78,
                        outerRadius: 100,
                        paddingAngle: 4,
                        cornerRadius: 50,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 100,
                        cy: 100,
                      },
                    ]}
                    width={210}
                    height={210}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                      {sumTotal}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                    >
                      total
                    </Typography>
                  </Box>
                </Box>
                <Grid
                  container
                  sx={{
                    width: 'fit-content',
                    gap: '20px',
                    flexDirection: 'column',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionPendingColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction Pending{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{auctionPendingValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal1}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen1}
                        onClose={handleCloseModal1}
                        data={filterByAuctionPending()}
                        title="Auction pending"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionActiveColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction in Progress{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{auctionActiveValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal2}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen2}
                        onClose={handleCloseModal2}
                        data={filterByAuctionActive()}
                        title="Auction in progress"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionCompletedColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction Ended{' '}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                      <Typography sx={{ fontWeight: 700 }}>{auctionCompletedValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal3}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen3}
                        onClose={handleCloseModal3}
                        data={filterByAuctionCompleted()}
                        title="Auction ended"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '100%',
            height: '350px',
            margin: 0,
            padding: '27px 27px 19px 24px',
            textAlign: 'center',
            '&.xs-only': {
              height: 'auto',
              px: '9px',
            },
            '&.tablet-only': {
              p: '16px',
              height: '300px',
            },
          }}
        >
          <Typography
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '32px',
              fontWeight: 700,
              marginBottom: '20px',
              lineHeight: 'normal',
              width: 1,
              '&.xs-only': {
                fontSize: '24px',
                mb: '11px',
                width: '100%',
                textAlign: 'left',
              },
              '&.tablet-only': {
                fontSize: '24px',
                width: '100%',
                textAlign: 'left',
              },
            }}
          >
            {t('titles.blocks.status_cake_auction')}
          </Typography>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
              '&.xs-only': {
                gap: '16px',
                height: 'auto',
                flexWrap: 'wrap',
              },
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    '.css-176wh8e-MuiCircularProgress-circle': {
                      color: colorsConfig.mainBlue,
                    },
                    margin: '0 8px',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                  }}
                >
                  <PieChart
                    colors={[
                      getAuctionPendingColour(),
                      getAuctionActiveColour(),
                      getAuctionCompletedColour(),
                    ]}
                    series={[
                      {
                        data: [
                          { value: auctionPendingValue },
                          { value: auctionActiveValue },
                          { value: auctionCompletedValue },
                        ],
                        innerRadius: 55,
                        outerRadius: 70,
                        paddingAngle: 4,
                        cornerRadius: 50,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 70,
                        cy: 70,
                      },
                    ]}
                    width={150}
                    height={150}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#11263C' }}>
                      {sumTotal}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                    >
                      total
                    </Typography>
                  </Box>
                </Box>
                <Grid
                  container
                  className={tabletOnly ? 'tablet-only' : ''}
                  sx={{
                    width: 'fit-content',
                    gap: '5px',
                    flexDirection: 'column',
                    '&.tablet-only': {
                      height: '100%',
                      gap: '10px',
                      '& p': {
                        fontSize: '12px',
                        textAlign: 'left',
                        lineHeight: 'normal',
                      },
                      '& .inline-marker': {
                        maxWidth: '15px',
                        mb: 0,
                        position: 'relative',
                        bottom: '-5px',
                      },
                      '& .inline-marker-wrapper': {
                        alignItems: 'flex-start',
                        gap: '7px',
                      },
                      '& .check-wrapper': {
                        ml: '22px',
                        mt: '5px',
                      },
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionPendingColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction Pending{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{auctionPendingValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal1}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen1}
                        onClose={handleCloseModal1}
                        data={filterByAuctionPending()}
                        title="Auction pending"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionActiveColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction in Progress{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{auctionActiveValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal2}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen2}
                        onClose={handleCloseModal2}
                        data={filterByAuctionActive()}
                        title="Auction in progress"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className={'inline-marker-wrapper'}
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <Box
                        className={'inline-marker'}
                        sx={{
                          width: '30px',
                          height: '4px',
                          background: getAuctionCompletedColour(),
                          borderRadius: '100px',
                          marginBottom: '6px',
                        }}
                      />
                      <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                        {' '}
                        Auction Ended{' '}
                      </Typography>
                    </Box>
                    <Box
                      className={'check-wrapper'}
                      sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>{auctionCompletedValue}</Typography>
                      <Link sx={{ cursor: 'pointer' }} onClick={handleStatusCakeModal3}>
                        <Typography
                          sx={{ width: '44px', textTransform: 'none', textDecoration: 'underline' }}
                        >
                          check
                        </Typography>
                      </Link>
                      <StatusCakeModal
                        isOpen={isModalOpen3}
                        onClose={handleCloseModal3}
                        data={filterByAuctionCompleted()}
                        title="Auction ended"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export const DealerFinancingStatusCake = ({ title }) => {
  const { t } = useTranslation()
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [loanApplications, setLoanApplications] = useState([])
  const [loanApplicationsByStatuses, setLoanApplicationByStatuses] = useState([
    {
      name: 'Loan calculation saved',
      count: 0,
      color: mainBlue,
      items: [],
    },
    {
      name: 'Customer data saved',
      count: 2,
      color: buttonHover,
      items: [],
    },
    {
      name: 'Awaiting bank replies',
      count: 4,
      color: darkRed,
      items: [],
    },
    {
      name: 'Financed',
      count: 0,
      color: mainGreen,
      items: [],
    },
    {
      name: "Awaiting customer's approval",
      count: 0,
      color: buttonYellow,
      items: [],
    },
    {
      name: 'Bank replies received',
      count: 0,
      color: fadedBlue,
      items: [],
    },
  ])
  const [financeModal, setFinanceModal] = useState(false)
  const [selectedStatusName, setSelectedStatusName] = useState('')
  const [selectedData, setSelectedData] = useState([])

  const getLoanApplicationStatus = (loanApplication) => {
    if (loanApplication.currentWorkflowState === 'new') {
      return 'Loan calculation saved'
    }
    if (loanApplication.currentWorkflowState === 'application_preparation') {
      return 'Customer data saved'
    }
    if (loanApplication.currentWorkflowState === 'approval_process_started') {
      if (
        loanApplication.loanDeals.filter((item) => item.currentWorkflowState === 'bank_sent')
          .length === loanApplication.loanDeals.length
      ) {
        return 'Awaiting bank replies'
      }
      if (loanApplication.loanDeals.filter((item) => item.isCustomerSelected)[0]) {
        return 'Financed'
      }
      if (loanApplication.loanDeals.filter((item) => item.isDealerSelected)[0]) {
        return "Awaiting customer's approval"
      }
      if (
        loanApplication.loanDeals.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        )[0]
      ) {
        return 'Bank replies received'
      }
    }
    return loanApplication.currentWorkflowState
  }

  const handleSetLoanApplicationsByStatuses = (data) => {
    setLoanApplicationByStatuses(
      loanApplicationsByStatuses.map((item) => {
        return {
          ...item,
          items: data.filter(
            (itemLoanApplication) => getLoanApplicationStatus(itemLoanApplication) === item.name,
          ),
        }
      }),
    )
  }

  const getLoanApplications = () => {
    privateRequest
      .get(`${API_FINANCE_ENDPOINTS.loanApplications}?pagination=false`)
      .then((response) => {
        setLoanApplications(response.data['hydra:member'])
        handleSetLoanApplicationsByStatuses(response.data['hydra:member'])
      })
  }

  useEffect(() => {
    if (!financeModal) {
      setSelectedData([])
      setSelectedStatusName('')
    }
  }, [financeModal])

  useEffect(() => {
    getLoanApplications()
  }, [])

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            width: '100%',
            height: '370px',
            margin: 0,
            padding: '32px 27px 19px 36px',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              lineHeight: 'normal',
              marginBottom: '20px',
              width: 1,
            }}
          >
            {t('titles.blocks.status_cake_financing')}
          </Typography>
          <Box
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                position: 'relative',
              }}
            >
              <PieChart
                colors={[mainBlue, buttonHover, darkRed, mainGreen, buttonYellow, fadedBlue]}
                series={[
                  {
                    data: loanApplicationsByStatuses.map((item) => {
                      return {
                        value: item.items.length,
                      }
                    }),
                    innerRadius: 78,
                    outerRadius: 100,
                    paddingAngle: 4,
                    cornerRadius: 50,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 100,
                    cy: 100,
                  },
                ]}
                width={210}
                height={210}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                  {loanApplications.length}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                >
                  total
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                scrollBehavior: 'smooth',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'thin',
                pr: '10px',
                height: '245px',
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '120px',
                  background: buttonDisabled,
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '70px',
                  background: mainBlue,
                  borderRadius: '5px',
                },
              }}
            >
              <Grid
                container
                sx={{
                  width: 'fit-content',
                  gap: '10px',
                  flexDirection: 'column',
                }}
              >
                {loanApplicationsByStatuses.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                        <Box
                          sx={{
                            width: '30px',
                            height: '4px',
                            background: item.color,
                            borderRadius: '100px',
                            marginBottom: '6px',
                          }}
                        />
                        <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                          {' '}
                          {item.name}{' '}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                        <Typography sx={{ fontWeight: 700 }}>{item.items.length}</Typography>
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            transition: '0.25s all ease-in-out',
                            '&:hover': {
                              color: mainBlue,
                              transition: '0.25s all ease-in-out',
                            },
                          }}
                          onClick={() => {
                            setSelectedStatusName(item.name)
                            setSelectedData(item.items)
                            setFinanceModal(true)
                          }}
                        >
                          check
                        </Typography>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '100%',
            height: '350px',
            margin: 0,
            padding: '27px 27px 19px 24px',
            textAlign: 'center',
            '&.xs-only': {
              height: 'auto',
              px: '9px',
            },
            '&.tablet-only': {
              p: '16px',
              height: '300px',
            },
          }}
        >
          <Typography
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '32px',
              fontWeight: 700,
              marginBottom: '20px',
              lineHeight: 'normal',
              width: 1,
              '&.xs-only': {
                fontSize: '24px',
                mb: '11px',
                width: '100%',
                textAlign: 'left',
              },
              '&.tablet-only': {
                fontSize: '24px',
                width: '100%',
                textAlign: 'left',
              },
            }}
          >
            {t('titles.blocks.status_cake_financing')}
          </Typography>
          <Box
            sx={{
              height: '245px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                position: 'relative',
              }}
            >
              <PieChart
                colors={[mainBlue, buttonHover, darkRed, mainGreen, buttonYellow, fadedBlue]}
                series={[
                  {
                    data: loanApplicationsByStatuses.map((item) => {
                      return {
                        value: item.items.length,
                      }
                    }),
                    innerRadius: 55,
                    outerRadius: 70,
                    paddingAngle: 4,
                    cornerRadius: 50,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 70,
                    cy: 70,
                  },
                ]}
                width={150}
                height={150}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#11263C' }}>
                  {loanApplications.length}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
                >
                  total
                </Typography>
              </Box>
            </Box>
            <Box
              className={tabletOnly ? 'tablet-only' : ''}
              sx={{
                overflow: 'auto',
                scrollBehavior: 'smooth',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'thin',
                pr: '10px',
                height: '245px',
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '120px',
                  background: buttonDisabled,
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '70px',
                  background: mainBlue,
                  borderRadius: '5px',
                },
                '&.tablet-only': {
                  pr: 0,
                  height: '217px',
                  gap: '10px',
                  mb: '10px',
                  pb: '16px',
                  '&::-webkit-scrollbar': {
                    width: '3px',
                    height: '90px',
                    background: buttonDisabled,
                    borderRadius: '5px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    height: '40px',
                    background: mainBlue,
                    borderRadius: '5px',
                  },
                  '& p': {
                    fontSize: '12px',
                    textAlign: 'left',
                    lineHeight: 'normal',
                  },
                  '& .inline-marker': {
                    maxWidth: '15px',
                    mb: 0,
                    position: 'relative',
                    bottom: '-5px',
                  },
                  '& .inline-marker-wrapper': {
                    alignItems: 'flex-start',
                    gap: '7px',
                  },
                  '& .check-wrapper': {
                    ml: '22px',
                    mt: '5px',
                  },
                },
              }}
            >
              <Grid
                container
                sx={{
                  width: 'fit-content',
                  gap: '10px',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                }}
              >
                {loanApplicationsByStatuses.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        className={'inline-marker-wrapper'}
                        sx={{ display: 'flex', gap: '15px', alignItems: 'flex-start' }}
                      >
                        <Box
                          className={'inline-marker'}
                          sx={{
                            width: '30px',
                            height: '4px',
                            background: item.color,
                            borderRadius: '100px',
                            position: 'relative',
                            bottom: '-8px',
                          }}
                        />
                        <Typography sx={{ fontSize: '14px', color: '#D0D1D2', textAlign: 'left' }}>
                          {item.name}
                        </Typography>
                      </Box>
                      <Box
                        className={'check-wrapper'}
                        sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}
                      >
                        <Typography sx={{ fontWeight: 700 }}>{item.items.length}</Typography>
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            transition: '0.25s all ease-in-out',
                            '&:hover': {
                              color: mainBlue,
                              transition: '0.25s all ease-in-out',
                            },
                          }}
                          onClick={() => {
                            setSelectedStatusName(item.name)
                            setSelectedData(item.items)
                            setFinanceModal(true)
                          }}
                        >
                          check
                        </Typography>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
      <StatusCakeFinanceModal
        open={financeModal}
        setOpen={setFinanceModal}
        title={selectedStatusName}
        data={selectedData}
      />
    </>
  )
}

export const DealerInsuranceStatusCake = ({ title }) => {
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()

  return (
    <Box
      sx={{
        width: '100%',
        height: '369px',
        margin: 0,
        padding: '27px 27px 19px 24px',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: mainBlack,
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: 'normal',
          marginBottom: '20px',
          width: 1,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          height: '245px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <Box
          sx={{
            width: 'fit-content',
            position: 'relative',
          }}
        >
          <PieChart
            colors={[mainBlue, buttonHover, '#AC0000']}
            series={[
              {
                data: [{ value: 1 }, { value: 1 }, { value: 1 }, { value: 1 }],
                innerRadius: 55,
                outerRadius: 70,
                paddingAngle: 4,
                cornerRadius: 50,
                startAngle: -180,
                endAngle: 180,
                cx: 70,
                cy: 70,
              },
            ]}
            width={150}
            height={150}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#11263C' }}>
              {'sumTotal'}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
            >
              total
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          sx={{
            width: 'fit-content',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '4px',
                  background: mainBlue,
                  borderRadius: '100px',
                  marginBottom: '6px',
                }}
              ></Box>
              <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}> Requests send </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
              <Typography sx={{ fontWeight: 700 }}>{'insuranceRequestsSentValue'}</Typography>
              <Typography sx={{ textDecoration: 'underline' }}>check</Typography>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '4px',
                  background: buttonHover,
                  borderRadius: '100px',
                  marginBottom: '6px',
                }}
              ></Box>
              <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                {' '}
                Responses Received{' '}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
              <Typography sx={{ fontWeight: 700 }}>{'insuranceResponsesReceivedValue'}</Typography>
              <Typography sx={{ textDecoration: 'underline' }}>check</Typography>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '4px',
                  background: '#AC0000',
                  borderRadius: '100px',
                  marginBottom: '6px',
                }}
              ></Box>
              <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}> Cancelled Deals </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
              <Typography sx={{ fontWeight: 700 }}>{'insuranceCancelledDealsValue'}</Typography>
              <Typography sx={{ textDecoration: 'underline' }}>check</Typography>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '4px',
                  background: '#AC0000',
                  borderRadius: '100px',
                  marginBottom: '6px',
                }}
              ></Box>
              <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}> Closed Deals </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
              <Typography sx={{ fontWeight: 700 }}>{'insuranceClosedDealsValue'}</Typography>
              <Typography sx={{ textDecoration: 'underline' }}>check</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const StatusCakeModal = ({ isOpen, onClose, data, title }) => {
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const role = useSelector((state) => state.auth.role)

  const actions = [
    {
      title: 'Start appraisal on-site',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
      ],
      actions: [
        // {
        //     type: 'workflowTransition',
        //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`
        // },
        {
          type: 'navigate',
          link: '/cars/:id/appraisal',
        },
      ],
    },
    {
      title: 'Edit appraisal',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check_failed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id/appraisal',
        },
      ],
    },
    {
      title: 'Edit information',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check_failed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id/edit',
        },
      ],
    },
    {
      title: 'Send to our compound',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check_failed',
        },
      ],
    },
    // {
    //     title: 'Send to our compound',
    //     conditions: [
    //         {
    //             role: 'COMPANY',
    //             status: 'car_workflow.new'
    //         },
    //         {
    //             role: 'COMPANY',
    //             status: 'car_workflow.appraisal_on_site'
    //         },
    //         {
    //             role: 'COMPANY',
    //             status: 'car_workflow.quality_check_failed'
    //         },
    //         {
    //             role: 'COMPANY',
    //             status: 'car_workflow.quality_check'
    //         },
    //     ]
    // },
    {
      title: 'Submit to quality check',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
      ],
      specialConditions: 'appraisalChecks',
      actions: [
        {
          type: 'modal',
          modalName: 'quality_check',
        },
        // {
        //     type: 'extendedWorkflowTransition',
        //     link: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
        //     newStatus: 'Quality check'
        // }
      ],
    },
    {
      title: 'Submit to quality check',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
      ],
      actions: [
        // {
        //     type: 'extendedWorkflowTransitionMulti',
        //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`,
        //     link2: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
        //     newStatus: 'Quality check'
        // }
        {
          type: 'modal',
          modalName: 'quality_check_failed',
        },
      ],
    },
    {
      title: 'Start auction',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'car_workflow.ready_for_sale',
        },
      ],
    },
    {
      title: 'Reschedule auction',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'auction_workflow.pending',
        },
      ],
    },
    {
      title: 'Archive',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.sale_succeed',
        },
      ],
      actions: [
        {
          type: 'archive',
          link: '/cars/:id',
        },
      ],
    },
    {
      title: 'Finish quality check',
      conditions: [
        {
          role: 'OPERATOR',
          status: 'car_workflow.quality_check',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id',
        },
      ],
    },
    {
      title: 'Sell',
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: `/cars/:id`,
        },
      ],
    },
    {
      title: "Don't sell",
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: '/cars/:id',
        },
      ],
    },
  ]

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0]?.value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0]?.value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0]?.value
    }
  }

  const getActionList = (carStatus, isArchived, isChecksDone) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'appraisalChecks' && !isChecksDone) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const [tableData, setTableData] = useState(data)

  const [sort, setSort] = useState({
    field: 0, // Field to sort by
    order: 'desc', // 'asc' or 'desc'
  })

  const handleSortRequest = (field) => {
    if (sort.field === 0) {
      setSort({
        ...sort,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSort({
        field,
        order: 'asc',
      })
    }
  }

  const handleSortClick = (columnIndex, order) => {
    setSort({
      field: columnIndex,
      order: order,
    })
  }

  const handleTransformTableData = () => {
    const res = data.map((item) => {
      const {
        id,
        vin,
        make: { name: make },
        model: { name: model },
      } = item

      return {
        id: id,
        link: `/cars/${id}`,
        auctionCarId: id,
        cells: [
          {
            type: 'text',
            value: id,
          },
          {
            type: 'image',
            value: handleSetCarPhoto(item),
          },
          {
            type: 'text-with-caption',
            value: `${make} ${model}`,
            caption: `VIN ${vin}`,
          },
          {
            type: 'text',
            value: getCarStatus(item.combinedStatus, item.isArchived),
          },
          {
            type: 'text',
            value: 'Your garage',
          },
          {
            type: 'actions',
            value: getActionList(item.combinedStatus, item.isArchived, item.isAppraisalChecksDone),
          },
          // {
          //     type: 'actions',
          //     value: getActionList(item.car.combinedStatus, false, item.maxBid),
          // },
        ],
      }
    })
    setTimeout(() => setTableData(res), 0)
  }

  useEffect(() => {
    if (data.length > 0) {
      handleTransformTableData()
    }
  }, [data])

  const [searchLine, setSearchLine] = useState('')

  const filterBySearchLine = (request) => {
    // Filter by searchLine
    const searchValue = searchLine.toLowerCase()

    return (
      request.id.toString().toLowerCase().includes(searchValue) ||
      request.cells[2].caption.toString().toLowerCase().includes(searchValue) ||
      request.cells[2].value.toString().toLowerCase().includes(searchValue)
    )
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px 20px 37px 55px',
          width: '1030px',
          minHeight: '420px',
          maxHeight: '720px',
          borderRadius: '10px',
          background: 'white',
          // background: '#F7FAFB',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            margin: '25px 0 20px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              lineHeight: 'normal',
            }}
          >
            {title}
          </Typography>
          <TextField
            id="search"
            label="Search"
            size="small"
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
            }}
            sx={{
              background: 'white',
              '& .MuiInputBase-input': {
                width: '250px',
                height: '38px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
                color: colorsConfig.mainBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={iconSearch} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            minHeight: '280px',
            maxHeight: '580px',
            overflow: 'auto',
            scrollBehavior: 'smooth',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'thin', // For Firefox
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '120px',
              background: buttonDisabled,
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              height: '70px',
              background: mainBlue,
              borderRadius: '5px',
            },
          }}
        >
          <Table>
            <OperatorTableHead
              tableCells={statusCakeTableHeadCells}
              sort={sort}
              setSort={setSort}
              onSortRequest={handleSortRequest}
              handleSortClick={handleSortClick}
            />
            <OperatorTableBody
              content={tableData.filter(filterBySearchLine)}
              getActionList={getActionList}
            />
          </Table>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          {statusCakeModalCloseIcon}
        </IconButton>
      </Box>
    </Modal>
  )
}

const handleSetCarPhoto = (car) => {
  if (car && car.mainForeshortening && car.mainForeshortening.photo) {
    return `${CDN_BASE_URL}${car.mainForeshortening.photo.path}`
  } else {
    return carDefaultPicture
  }
}
