import React from 'react'
import { Box, Tab, Tabs, Button, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import CarPageTabTitle from './CarPageTabTitle'
import { useSelector, useDispatch } from 'react-redux'
import TextNormal from '../../ui/Text/TextNormal'
import { HINT_TYPES } from '../../config/default'
import { fixHint } from '../../store/features/qualityCheckSlice'

const CarPageTabs = (props) => {
  const { tab, setTab, sections } = props

  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)

  const renderMarkedTitle = (title, count) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextNormal
          sx={{
            mr: '7px',
          }}
        >
          {title}
        </TextNormal>
        <Box
          sx={{
            color: 'white',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.mainRed,
          }}
        >
          <TextNormal
            sx={{
              color: 'white',
              fontSize: '14px',
            }}
          >
            {count}
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const renderTabTitle = (title) => {
    let matchHints = []
    let indexes = []
    switch (title) {
      case 'Intro':
        return {
          component: title,
          hasFixes: false,
        }
        break
      case 'Documents':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/1' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Photos':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.photos || item['@type'] === HINT_TYPES.photo) &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Damages':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.damage && !item.fixed) ||
            (item['@type'] === HINT_TYPES.damages && !item.fixed)
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Warning Lights':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (item['@type'] === HINT_TYPES.warningLights && !item.fixed) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Interior Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Mechanical Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock !== '/reference/appraisal/subblocks/1' &&
            item.subblock !== '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
        break
      case 'Vehicle Details':
        matchHints = qualityCheckHints.filter((item, index) => {
          return item['@type'] === HINT_TYPES.vehicleDetails && !item.fixed
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component: title,
            hasFixes: false,
          }
        }
      default:
        return {
          component: title,
          hasFixes: false,
        }
        break
    }
  }

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
      }}
    >
      {xsOnly ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            width: 'calc(100% + 4px)',
          }}
        >
          {sections.map((section, index) => {
            return (
              <Button
                key={index}
                className={index === tab ? 'active' : ''}
                sx={{
                  background: colorsConfig.bgLightBlue,
                  color: colorsConfig.mainBlack,
                  position: 'relative',
                  height: '48px',
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  mb: '4px',
                  mr: '4px',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  '&::after': {
                    content: '" "',
                    display: 'block',
                    width: '100%',
                    height: '3px',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    background: colorsConfig.mainGreen,
                    transformOrigin: '50% 50%',
                    transform: 'scale(0)',
                    transition: '0.25s all ease-in-out',
                  },
                  '&.active': {
                    background: colorsConfig.bgLightBlue,
                    color: colorsConfig.mainBlack,
                    '&::after': {
                      transform: 'scale(1)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                }}
                onClick={() => {
                  setTab(index)
                }}
              >
                {combinedStatus && combinedStatus === 'car_workflow.appraisal_on_site' ? (
                  <CarPageTabTitle title={section.name} isCompleted={section.isCompleted} />
                ) : (
                  <TextNormal>{section.name}</TextNormal>
                )}
              </Button>
            )
          })}
        </Box>
      ) : (
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
          variant="scrollable"
          sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
          }}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={index}
                label={
                  combinedStatus === 'car_workflow.new' ||
                  combinedStatus === 'car_workflow.appraisal_on_site' ? (
                    <CarPageTabTitle title={section.name} isCompleted={section.isCompleted} />
                  ) : combinedStatus === 'car_workflow.moderation_failed' ? (
                    renderTabTitle(section.name).component
                  ) : (
                    section.name
                  )
                }
                sx={{
                  fontSize: '13px',
                }}
              />
            )
          })}
        </Tabs>
      )}
    </Box>
  )
}

export default CarPageTabs
