import React, { useState, useEffect } from 'react'
import { Button, TextField, Link, Grid, Typography, Box, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { API_ENDPOINTS } from '../../config/default'
import { publicRequest } from '../../utils/request'
import RequestTokenForm from './RequestTokenForm'
import ChangePasswordForm from './ChangePasswordForm'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const PasswordRecovery = ({ handleSignIn, handleSignUp }) => {
  const { t } = useTranslation()

  const [step, setStep] = useState(1)
  const [username, setUsername] = useState('')
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)

  const requestToken = (username) => {
    setUsername(username)
    const formData = {
      username: username,
    }

    setDisableSubmitButton(true)

    publicRequest
      .post(API_ENDPOINTS.resetPasswordTokenRequest, formData)
      .then(async (response) => {
        setStep(2)
      })
      .catch((e) => {
        toast.error('Invalid username or password, please try again')
      })
      .finally(() => {
        setDisableSubmitButton(false)
      })
  }

  const changePassword = (token, password) => {
    setToken(token)
    setPassword(password)
    const formData = {
      username: username,
      token: token,
      newPassword: password,
    }

    setDisableSubmitButton(true)

    publicRequest
      .post(API_ENDPOINTS.resetPasswordChangeRequest, formData)
      .then(async (response) => {
        setStep(3)
      })
      .catch((e) => {
        toast.error('Invalid username or password, please try again')
      })
      .finally(() => {
        setDisableSubmitButton(false)
      })
  }

  return (
    <Container component="main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: 'Helvetica',
            fontSize: '40px',
            lineHeight: 'normal',
            fontWeight: '700',
            fontStyle: 'normal',
            marginBottom: '8px',
            paddingBottom: '18px',
          }}
        >
          {t('p.reset_password')}
        </Typography>
        {step === 1 && <RequestTokenForm onSubmit={requestToken} disabled={disableSubmitButton} />}

        {step === 2 && (
          <ChangePasswordForm onSubmit={changePassword} disabled={disableSubmitButton} />
        )}

        <Grid
          container
          sx={{
            gap: '11px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid item>
            <Link
              variant="body2"
              sx={{
                cursor: 'pointer',
                fontSize: '12px',
                color: colorsConfig.mainBlue,
                textDecoration: 'none',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
              onClick={handleSignIn}
            >
              {t('p.enter_password')}
            </Link>
          </Grid>
          <Grid item>
            <Link
              variant="body2"
              onClick={handleSignUp}
              sx={{
                cursor: 'pointer',
                fontSize: '12px',
                color: colorsConfig.mainBlue,
                textDecoration: 'none',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >
              {t('p.no_account_yet')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default PasswordRecovery
