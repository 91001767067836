import React, { useEffect } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import TextH1 from '../../../ui/Text/TextH1'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import CarDefaultPicture from '../../../assests/img/car-not-found.png'
import IconApple from '../../../assests/img/icons/icon-apple-green.svg'
import IconCarStatus from '../../../assests/img/icons/icon-car-status.svg'
import ImageVerified from '../../../assests/img/fleetify-verified.svg'
import { CDN_BASE_URL } from '../../../config/default'
import { colorsConfig } from '../../../config/themeConfig'

const CarPageSummary = ({ financeStatus, currentWorkflow, setLoggingTreeModal, appraisalDate }) => {
  const carData = useSelector((state) => state.car.carData)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const carTitle = `${carData?.make?.name} ${carData?.model?.name}`

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const maxWidth1500 = useMediaQuery('(max-width: 1500px)')

  useEffect(() => {
    console.log({ appraisalDate })
  }, [appraisalDate])

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        mt: '13px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&.xs-only': {
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          width: '118px',
          height: '80px',
          mr: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '6px',
          overflow: 'hidden',
          '&.xs-only': {
            mr: '10px',
          },
        }}
      >
        {carData.mainPhoto && carData.mainPhoto.photo ? (
          <img
            src={`${CDN_BASE_URL}${carData.mainPhoto.photo.path}`}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <img
            src={CarDefaultPicture}
            alt=""
            style={{
              width: '80%',
            }}
          />
        )}
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mr: '15px',
          '&.xs-only': {
            width: 'calc(100% - 128px)',
            mr: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <TextH1
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              lineHeight: 'normal',
              '&.xs-only': {
                fontSize: '24px',
              },
              '&.tablet-only': {
                fontSize: '28px',
              },
            }}
          >
            #{carData.referenceNumber} {carTitle}
          </TextH1>
          {carData.carMainSpecs?.engineType === 'electric' ||
          carData.carMainSpecs?.engineType === 'hybrid' ? (
            <Box
              sx={{
                ml: '5px',
                width: '28px',
                position: 'relative',
                mb: '-2.5px',
              }}
            >
              <img
                style={{
                  width: '100%',
                }}
                src={IconApple}
                alt=""
              />
              <TextNormal
                sx={{
                  fontSize: '8px',
                  fontWeight: 700,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                ECO
              </TextNormal>
            </Box>
          ) : null}
        </Box>
        {appraisalDate && appraisalDate !== '' ? (
          <TextBoldNormal
            sx={{
              fontSize: '18px',
              color: colorsConfig.mainGreen,
            }}
          >
            Appraisal date {appraisalDate}
          </TextBoldNormal>
        ) : null}
      </Box>
      <Box
        className={[maxWidth1500 && !xsOnly ? 'max-w-1500' : '', xsOnly ? 'xs-only' : ''].join(' ')}
        sx={{
          display: 'flex',
          gap: '10px',
          '&.max-w-1500': {
            flexDirection: 'column-reverse',
            alignItems: 'flex-end',
          },
          '&.xs-only': {
            width: '100%',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '48px',
            py: '5px',
            borderRadius: '6px',
            border: '1px solid',
            borderColor: carData.isArchived ? colorsConfig.buttonDisabled : colorsConfig.mainGreen,
            paddingLeft: '44px',
            paddingRight: '44px',
            cursor: 'pointer',
            '&.xs-only': {
              width: '100%',
              mt: '20px',
              px: 0,
              py: 0,
              height: '33px',
            },
          }}
          onClick={() => {
            setLoggingTreeModal(true)
          }}
        >
          <img
            src={IconCarStatus}
            style={{
              marginRight: '10px',
              filter: carData.isArchived ? 'brightness(20%)' : '',
            }}
            alt=""
          />
          <TextNormal
            sx={{
              color: carData.isArchived ? colorsConfig.iconGray : colorsConfig.mainBlack,
              lineHeight: 'normal',
            }}
          >
            {carData.isArchived ? 'Archived' : currentWorkflow}
            {financeStatus ? (
              <>
                <br />
                {financeStatus}
              </>
            ) : (
              ''
            )}
          </TextNormal>
        </Box>
        {(combinedStatus === 'car_workfow.ready_for_sale' ||
          combinedStatus.includes('auction_workflow') ||
          combinedStatus.includes('deal_worfklow')) &&
        carData.isOnCompound ? (
          <Box>
            <img src={ImageVerified} alt="" />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default CarPageSummary
