import {
  getClosedPolicies,
  getLinkedPolicies,
  getPoliciesInRestitution,
  getPoliciesStatusCount,
  getUnlinkedPolicies,
} from '../rviOperator.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getUnlinkedPolicies.pending, (state) => {
      state.unlinkedPolicies.isLoading = true
    })
    .addCase(getUnlinkedPolicies.fulfilled, (state, action) => {
      state.unlinkedPolicies.isLoading = false
      state.unlinkedPolicies.total = action.payload?.[apiConstants.hydraTotal]
      state.unlinkedPolicies.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getUnlinkedPolicies.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.unlinkedPolicies.isLoading = false
      }
    })
    .addCase(getPoliciesInRestitution.pending, (state) => {
      state.policiesInRestitution.isLoading = true
    })
    .addCase(getPoliciesInRestitution.fulfilled, (state, action) => {
      state.policiesInRestitution.isLoading = false
      state.policiesInRestitution.total = action.payload?.[apiConstants.hydraTotal]
      state.policiesInRestitution.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getPoliciesInRestitution.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.policiesInRestitution.isLoading = false
      }
    })
    .addCase(getClosedPolicies.pending, (state) => {
      state.closedPolicies.isLoading = true
    })
    .addCase(getClosedPolicies.fulfilled, (state, action) => {
      state.closedPolicies.isLoading = false
      state.closedPolicies.total = action.payload?.[apiConstants.hydraTotal]
      state.closedPolicies.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getClosedPolicies.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.closedPolicies.isLoading = false
      }
    })
    .addCase(getPoliciesStatusCount.fulfilled, (state, action) => {
      state.statusCount = action.payload
    })
    .addCase(getLinkedPolicies.pending, (state) => {
      state.linkedPolicies.isLoading = true
    })
    .addCase(getLinkedPolicies.fulfilled, (state, action) => {
      state.linkedPolicies.isLoading = false
      state.linkedPolicies.total = action.payload?.[apiConstants.hydraTotal]
      state.linkedPolicies.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getLinkedPolicies.rejected, (state, action) => {
      state.linkedPolicies.isLoading = false
    })
}
