import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import useInput from '../../hooks/useInput'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const ChangePasswordForm = ({ onSubmit, disabled }) => {
  const { t } = useTranslation()

  const token = useInput('', { isEmpty: true })
  const password = useInput('', { isEmpty: true, minLength: 4, maxLength: 25 })
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleSubmit = () => {
    onSubmit(token.value, password.value)
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid>
          <Typography
            sx={{
              width: '305px',
              fontFamily: 'Helvetica',
              fontSize: '12px',
              lineHeight: 'normal',
              fontStyle: 'normal',
              marginLeft: '16px',
              marginTop: '-20px',
              marginBottom: '8px',
            }}
          >
            We sent you a code, please enter the code and a new password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="token"
            label={t('input.code')}
            name="token"
            autoComplete="off"
            sx={{
              '&  .MuiInputLabel-root': {
                fontSize: '14px',
              },
              "& .MuiInputLabel-root[data-shrink='false']": {
                marginTop: '-7px',
              },
            }}
            type="text"
            onChange={(e) => {
              token.onChange(e)
            }}
            onBlur={(e) => {
              token.onBlur(e)
            }}
            inputProps={{
              autoComplete: 'off',
              sx: {
                width: '305px',
                height: '38px',
                fontSize: '14px',
                lineHeight: 'normal',
                fontStyle: 'normal',
                boxSizing: 'border-box',
                padding: '0 14px',
              },
            }}
            value={token.value}
            helperText={token.firstBlur && token.hasErrors ? token.errorText : ''}
            error={token.firstBlur && token.hasErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label={t('input.new_password')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            autoComplete="off"
            sx={{
              '&  .MuiInputLabel-root': {
                fontSize: '14px',
              },
              "& .MuiInputLabel-root[data-shrink='false']": {
                marginTop: '-7px',
              },
            }}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setPasswordVisible(!passwordVisible)
                    }}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                width: '305px',
                height: '38px',
                fontSize: '14px',
                lineHeight: 'normal',
                fontStyle: 'normal',
              },
            }}
            onChange={(e) => {
              password.onChange(e)
            }}
            onBlur={(e) => {
              password.onBlur(e)
            }}
            value={password.value}
            helperText={password.firstBlur && password.hasErrors ? password.errorText : ''}
            error={password.firstBlur && password.hasErrors}
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        sx={{
          height: '48px',
          backgroundColor: colorsConfig.mainBlue,
          fontFamily: 'Helvetica',
          fontSize: '16px',
          lineHeight: 'normal',
          fontWeight: '700',
          fontStyle: 'normal',
          textTransform: 'none',
          margin: '28px 0 22px',
        }}
        onClick={() => {
          handleSubmit()
        }}
        disabled={token.hasErrors || password.hasErrors || disabled}
      >
        {t('buttons.change_password')}
      </Button>
    </Box>
  )
}

export default ChangePasswordForm
