import { useMediaQuery } from '@material-ui/core'

const useBreakpoints = () => {
  const isMinWidth1440 = useMediaQuery('(min-width:1440px)')
  const isMaxWidth1440 = useMediaQuery('(max-width:1439.98px)')
  const isMaxWidth1280 = useMediaQuery('(max-width:1279.98px)')
  const isMaxWidth960 = useMediaQuery('(max-width:959.98px)')
  const isMaxWidth600 = useMediaQuery('(max-width:599.98px)')
  const isMinWidth600 = useMediaQuery('(min-width:600px)')

  return {
    isMinWidth1440,
    isMaxWidth1440,
    isMaxWidth1280,
    isMaxWidth960,
    isMaxWidth600,
    isMinWidth600,
  }
}

export default useBreakpoints
