import React, { useEffect, useState } from 'react'
import { Table, Stack, Box, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import CustomTabs from '../../../ui/CustomTabs'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import SearchInput from '../../../components/Search/SearchInput'
import UIButtonAdd from '../../../ui/Buttons/UIButtonAdd'
import CustomTab from '../../../ui/CustomTab'
import BannerSingle from '../../../components/Banner/BannerSingle'
import Banner from '../../../assests/img/banners/banner-green-insurance.png'
import {
  warrantyApplicationsActivatedTableCells,
  warrantyApplicationsTableCells,
} from '../warranty.constants'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { colorsConfig } from '../../../config/themeConfig'
import {
  getWarrantyApplications,
  getWarrantyApplicationsWorkflowStateCounts,
} from '../../../store/features/warrantyApplicationsSlice/warrantyApplications.actions'
import {
  getWarrantyApplicationsActivatedTableData,
  getWarrantyApplicationsTableData,
} from '../warranty.helpers'
import UITablePreloader from '../../../ui/UIPreloader/UITablePreloader'
import {
  setCurrentTab,
  setSearchLine,
} from '../../../store/features/warrantyApplicationsSlice/warrantyApplications.reducer'
import ActivateWarrantyModal from './ActivateWarrantyModal'
import RBox from '../../../ui/RBox'

const WarrantyApplicationsPage = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const isLoading = useSelector((state) => state.warrantyApplications.isLoading)
  const isLoadingWorkflowStateCounts = useSelector(
    (state) => state.warrantyApplications.workflowStateCounts.isLoading,
  )
  const { applicationPreparationCount, issuedCount } = useSelector(
    (state) => state.warrantyApplications.workflowStateCounts,
  )
  const warrantyApplications = useSelector(
    (state) => state.warrantyApplications.warrantyApplications,
  )

  const currentTab = useSelector((state) => state.warrantyApplications.currentTab)
  const searchLine = useSelector((state) => state.warrantyApplications.searchLine)
  const [activateWarrantyModal, setActivateWarrantyModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWarrantyApplications())
  }, [searchLine, currentTab])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Car Warranty'))
    dispatch(getWarrantyApplicationsWorkflowStateCounts())
  }, [])

  return (
    <Stack gap="30px" sx={{ px: { xs: '16px', md: 0 } }}>
      <BannerSingle banner={Banner} link={'/go-greener'} />
      <Stack direction="row" justifyContent="flex-end">
        <UIButtonAdd onClick={() => setActivateWarrantyModal(true)}>Activate warranty</UIButtonAdd>
      </Stack>
      <BoxShadowed
        sx={{
          '&.xs-only': {
            p: '0',
            boxShadow: 'none',
          },
        }}
      >
        <Stack gap="30px">
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            gap={{ xs: '15px', md: 0 }}
          >
            <CustomTabs
              value={currentTab}
              onChange={(e, newVal) => dispatch(setCurrentTab(newVal))}
            >
              <CustomTab
                label={
                  <UITextRegular>
                    Warranty in progress{' '}
                    <span style={{ color: colorsConfig.mainBlue }}>
                      ({applicationPreparationCount ?? 0})
                    </span>
                  </UITextRegular>
                }
              />
              <CustomTab
                label={
                  <UITextRegular>
                    Activated{' '}
                    <span style={{ color: colorsConfig.mainBlue }}>({issuedCount ?? 0})</span>
                  </UITextRegular>
                }
              />
            </CustomTabs>
            <SearchInput
              sx={{
                minWidth: {
                  xs: '100%',
                  md: '250px',
                },
                '& .MuiInputBase-input': {
                  width: {
                    xs: '100%',
                    md: '205px',
                  },
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
              }}
              value={searchLine}
              onChange={(e) => dispatch(setSearchLine(e.target.value))}
            />
          </Stack>
          <RBox
            sx={{
              position: 'relative',
            }}
          >
            <UITablePreloader
              loading={isLoading || isLoadingWorkflowStateCounts}
              sx={{ minHeight: '300px' }}
            >
              <RBox
                sx={{
                  '&.xs-only': {
                    maxWidth: 'calc(100vw - 32px)',
                    overflowX: 'auto',
                  },
                }}
              >
                <Table
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    '&.xs-only': {
                      minWidth: '900px',
                    },
                  }}
                >
                  <CarTableHead
                    tableCells={
                      currentTab === 0
                        ? warrantyApplicationsTableCells
                        : warrantyApplicationsActivatedTableCells
                    }
                  />
                  <CarTableBody
                    content={
                      currentTab === 0
                        ? getWarrantyApplicationsTableData(warrantyApplications)
                        : getWarrantyApplicationsActivatedTableData(warrantyApplications)
                    }
                  />
                </Table>
              </RBox>
            </UITablePreloader>
          </RBox>
        </Stack>
        <ActivateWarrantyModal open={activateWarrantyModal} setOpen={setActivateWarrantyModal} />
      </BoxShadowed>
    </Stack>
  )
}

export default WarrantyApplicationsPage
