import axios from 'axios'
import { API_BASE_URL, FINANCE_ADMIN_URL, FINANCE_CALCULATOR_API_BASE_URL } from '../config/default'
import { localStorageKeys, getToken, removeToken, setToken } from './localStorage.helpers'

const BASE_URL = API_BASE_URL
const FINANCE_URL = FINANCE_CALCULATOR_API_BASE_URL

let isRefreshingToken = false
let refreshSubscribers = []

function processSubscribers(token) {
  refreshSubscribers.forEach((callback) => callback(token))
  refreshSubscribers = []
}

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const adminRequest = axios.create({
  baseURL: FINANCE_ADMIN_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const publicPatchRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/merge-patch+json',
  },
})

export const privateRequest = axios.create({
  baseURL: BASE_URL,
  retry: 20,
  retryDelay: 2000,
})

export const patchRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/merge-patch+json',
  },
  retry: 20,
  retryDelay: 2000,
})

export const privateMultipartRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  retry: 20,
  retryDelay: 5000,
})

export const financeRequest = axios.create({
  baseURL: FINANCE_URL,
  // headers: {
  //     Authorization: `Bearer ${userData && userData.accessToken ? userData.accessToken : ''}`,
  // },
})

privateRequest.interceptors.request.use((config) => {
  const token = getToken(localStorageKeys.token)
  if (token) config.headers.Authorization = `Bearer ${getToken(localStorageKeys.token)}`
  return config
})

patchRequest.interceptors.request.use((config) => {
  const token = getToken(localStorageKeys.token)
  if (token) config.headers.Authorization = `Bearer ${getToken(localStorageKeys.token)}`
  return config
})

privateMultipartRequest.interceptors.request.use((config) => {
  const token = getToken(localStorageKeys.token)
  if (token) config.headers.Authorization = `Bearer ${getToken(localStorageKeys.token)}`
  return config
})

publicRequest.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

privateMultipartRequest.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    const { config, message } = error
    if (!config || !config.retry) {
      console.log('Failed to connect to the server, please try again later')
      return Promise.reject(error)
    }
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(error)
    }
    config.retry -= 1
    console.log('Connection problems, retrying to connect to the server...')
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, config.retryDelay || 2000)
    })
    return delayRetryRequest.then(() => privateMultipartRequest(config))
  },
)

privateRequest.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log({ error })
    const originalRequest = error?.config
    if (error.response?.status === 401 && error.config && !error.config?._isRetry) {
      if (isRefreshingToken) {
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(privateRequest(originalRequest))
          })
        })
      }
      isRefreshingToken = true
      originalRequest._isRetry = true
      removeToken(localStorageKeys.token)
      try {
        const refresh_token = getToken(localStorageKeys.refreshToken)
        if (refresh_token) {
          const response = await axios.post(`${BASE_URL}/authentication_token/refresh`, {
            refresh_token,
          })
          setToken(localStorageKeys.token, response.data.token)
          setToken(localStorageKeys.refreshToken, response.data.refresh_token)
          processSubscribers(response.data.token)
          isRefreshingToken = false
          return privateRequest.request(originalRequest)
        }
        throw error
      } catch (e) {
        removeToken(localStorageKeys.refreshToken)
        isRefreshingToken = false
        refreshSubscribers = []
        return Promise.reject(e)
      }
    }
    const { config, message } = error
    if (!config || !config.retry) {
      console.log('Failed to connect to the server, please try again later')
      return Promise.reject(error)
    }
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(error)
    }
    console.log('Failed to connect, retrying...')
    config.retry -= 1
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, config.retryDelay || 2000)
    })
    return delayRetryRequest.then(() => privateRequest(config))
  },
)

patchRequest.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    const { config, message } = error
    if (!config || !config.retry) {
      console.log('Failed to connect to the server, please try again later')
      return Promise.reject(error)
    }
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(error)
    }
    console.log('Failed to connect, retrying...')
    config.retry -= 1
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, config.retryDelay || 2000)
    })
    return delayRetryRequest.then(() => patchRequest(config))
  },
)
