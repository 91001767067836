import React, { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import TextFieldSmallEdible from './TextFieldSmallEdible'
import IconEdit from '../assests/img/icons/icon-edit.svg'
import IconCheckmark from '../assests/img/icons/icon-checkbox.svg'
import LDSRoller from '../components/Preloaders/LDSRoller'
import TextNormal from './Text/TextNormal'
import { colorsConfig } from '../config/themeConfig'

const TextFieldEditable = (props) => {
  const { loading, handleSubmit, openModal, InputProps, ...rest } = props

  const [editMode, setEditMode] = useState(false)
  const [saved, setSaved] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const [inputRef, setInputRef] = useState(null)

  useEffect(() => {
    if (!loading && firstRender) {
      setSaved(true)
      setTimeout(() => {
        setSaved(false)
      }, 1500)
    }
    if (!firstRender) setFirstRender(true)
  }, [loading])

  useEffect(() => {
    if (editMode) {
      inputRef.focus()
      inputRef.select()
    }
  }, [editMode])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TextFieldSmallEdible
        inputRef={(input) => {
          setInputRef(input)
        }}
        disabled={!editMode}
        onBlur={(e) => {
          if (!e?.relatedTarget?.classList.contains('edit-submit')) {
            setEditMode(false)
            handleSubmit()
          }
        }}
        InputProps={{
          endAdornment: loading ? (
            <LDSRoller
              wrapperStyles={{
                right: '-7px',
              }}
            />
          ) : editMode ? (
            <IconButton
              className={'edit-submit'}
              sx={{
                borderRadius: '6px',
                position: 'relative',
                right: '-9px',
                width: '32px',
                height: '32px',
              }}
              onClick={() => {
                setEditMode(false)
                handleSubmit()
              }}
            >
              <img
                src={IconCheckmark}
                alt=""
                style={{
                  width: '15px',
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                borderRadius: '6px',
                position: 'relative',
                right: '-9px',
              }}
              onClick={() => {
                if (openModal) {
                  openModal()
                  return
                }
                setEditMode(true)
              }}
            >
              <img src={IconEdit} alt="" />
            </IconButton>
          ),
          ...InputProps,
        }}
        {...rest}
      />
      <TextNormal
        className={saved ? 'active' : ''}
        sx={{
          color: colorsConfig.mainGreen,
          position: 'absolute',
          top: '100%',
          left: '14px',
          fontSize: '12px',
          opacity: 0,
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        Saved
      </TextNormal>
    </Box>
  )
}

export default TextFieldEditable
