import React from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import useInput from '../../hooks/useInput'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const RequestTokenForm = ({ onSubmit, disabled }) => {
  const userName = useInput('', { isEmpty: true })

  const { t } = useTranslation()

  const handleSubmit = () => {
    onSubmit(userName.value)
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            width: '305px',
            fontFamily: 'Helvetica',
            fontSize: '12px',
            lineHeight: 'normal',
            fontStyle: 'normal',
            marginLeft: '16px',
            marginBottom: '18px',
          }}
        >
          {t('p.reset_password_instructions')}
        </Typography>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="username"
            label={t('input.username')}
            name="username"
            autoComplete="username"
            sx={{
              '&  .MuiInputLabel-root': {
                fontSize: '14px',
              },
              "& .MuiInputLabel-root[data-shrink='false']": {
                marginTop: '-7px',
              },
            }}
            type="text"
            onChange={(e) => {
              userName.onChange(e)
            }}
            onBlur={(e) => {
              userName.onBlur(e)
            }}
            value={userName.value}
            helperText={userName.firstBlur && userName.hasErrors ? userName.errorText : ''}
            error={userName.firstBlur && userName.hasErrors}
            InputProps={{
              sx: {
                width: '305px',
                height: '38px',
                fontSize: '14px',
                lineHeight: 'normal',
                fontStyle: 'normal',
              },
            }}
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        sx={{
          height: '48px',
          backgroundColor: colorsConfig.mainBlue,
          fontFamily: 'Helvetica',
          fontSize: '16px',
          lineHeight: 'normal',
          fontWeight: '700',
          fontStyle: 'normal',
          textTransform: 'none',
          margin: '28px 0 22px',
        }}
        onClick={() => {
          handleSubmit()
        }}
      >
        {t('buttons.submit')}
      </Button>
    </Box>
  )
}

export default RequestTokenForm
