import React, { useState, useEffect } from 'react'
import {
  Button,
  Link,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Modal,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Close } from '@mui/icons-material'
import useInput from '../../hooks/useInput'
import { toast } from 'react-toastify'
import { API_ENDPOINTS } from '../../config/default'
import { publicRequest } from '../../utils/request'
import { colorsConfig } from '../../config/themeConfig'
import logo from '../../assests/img/logo.png'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import useBreakpoints from '../../hooks/useBreakpoints'
import { countries } from '../../default-data/coutries'
import { useTranslation, Trans } from 'react-i18next'
import TextFieldSmall from '../../ui/TextFieldSmall'
import { REGION_LOCALE, REGION_VERSIONS } from '../../utils/constants/global.constants'

const SignUp = (props) => {
  const { setSignInModal, handleSignIn } = props

  const { t } = useTranslation()

  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [uploadedFile, setUploadedFile] = useState(null)
  const userName = useInput('', { isEmpty: true })
  const email = useInput('', { isEmpty: true, isEmail: true })
  const company = useInput('', { isEmpty: true })
  const firstName = useInput('', {})
  const lastName = useInput('', {})
  const emirate = useInput(null, { isEmpty: true })
  const licenseNumber = useInput('', { isEmpty: true })
  const phoneNumber = useInput('', { isEmpty: true, phoneNumberUAE: 9 })
  const landLineNumber = useInput('', { isEmpty: true, phoneNumberUAE: 8 })
  const country = useInput(
    countries.find((item) => item.code === 'CH'),
    { isEmpty: true },
  )
  const addressLine1 = useInput('', { isEmpty: true })
  const addressLine2 = useInput('', { isEmpty: true })
  const postcode = useInput('', { isEmpty: true })
  const date = useInput(null, { minDate: currentDate })
  const taxRegistrationNumber = useInput('', { isEmpty: true })
  const terms = useInput('', { isEmpty: true })
  const [uploadFile, setUploadFile] = useState('')
  const [disableOnSubmit, setDisableOnSubmit] = useState(false)
  const [notificationModal, setNotificationModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const { isMaxWidth600 } = useBreakpoints()

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleUploadLicense = (value) => {
    const fileName = value.replace('C:\\fakepath\\', '')
    if (fileName) {
      setUploadedFile(fileName)
    }
  }

  const handleFormSubmit = () => {
    setDisableOnSubmit(true)
    const phonePrefix = '971'
    const phoneNumberFull = phonePrefix.concat(phoneNumber.value)
    const landLineNumberFull = phonePrefix.concat(landLineNumber.value)
    const roles = ['ROLE_COMPANY', 'ROLE_SELLER', 'ROLE_BUYER']
    let formData = new FormData()
    formData.append('login', userName.value)
    formData.append('email', email.value)
    formData.append('phoneNumber', phoneNumberFull)
    formData.append('companyName', company.value)
    formData.append('taxRegistrationNumber', taxRegistrationNumber.value)
    formData.append('country', country.value?.label)
    if (landLineNumber && !landLineNumber.hasErrors) {
      formData.append('landlineNumber', landLineNumberFull)
    }
    if (firstName) {
      formData.append('firstName', firstName.value)
    }
    if (lastName) {
      formData.append('surname', lastName.value)
    }
    for (let i = 0; i < roles.length; i++) {
      formData.append(`roles[${i}]`, roles[i])
    }
    formData.append('state', emirate.value)
    if (addressLine1) {
      formData.append('addressLine1', addressLine1.value)
    }
    if (addressLine2) {
      formData.append('addressLine2', addressLine2.value)
    }
    if (postcode.value !== '') {
      formData.append('postcode', postcode.value)
    }
    formData.append('tradeLicenseNumber', licenseNumber.value.toString())
    formData.append('tradeLicenseExpiryDate', date.value)
    formData.append('tradeLicense', uploadFile)
    publicRequest
      .post(API_ENDPOINTS.register, formData, {
        headers: {
          accept: 'application/ld+json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        toast.success(`Thank you! Your request has been sent successfully!`)
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      })
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => {
        if (error.response.data['hydra:description'].indexOf('login') !== -1) {
          toast.error('This username has already been used')
        }
      })
      .finally(() => {
        setDisableOnSubmit(false)
      })
  }

  useEffect(() => {
    if (submitted) {
      userName.onChangeValue('')
      email.onChangeValue('')
      company.onChangeValue('')
      firstName.onChangeValue('')
      lastName.onChangeValue('')
      emirate.onChangeValue('')
      licenseNumber.onChangeValue('')
      phoneNumber.onChangeValue('')
      landLineNumber.onChangeValue('')
      addressLine1.onChangeValue('')
      addressLine2.onChangeValue('')
      date.onChangeValue(null)
      terms.onChangeValue('')
      setUploadedFile(null)
      setUploadFile('')
    }
  }, [submitted])

  return (
    <React.Fragment>
      {disableOnSubmit ? (
        <div className="preloader">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
      <Box
        className={tabletOnly ? 'tablet-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '&.tablet-only': {
            display: 'block',
          },
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ mb: !isMaxWidth600 && '25px' }}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="username"
                  label={t('form_input_label_username')}
                  name="username"
                  onChange={(e) => {
                    userName.onChange(e)
                  }}
                  onBlur={(e) => {
                    userName.onBlur(e)
                  }}
                  value={userName.value}
                  helperText={userName.firstBlur && userName.hasErrors ? userName.errorText : ''}
                  error={userName.firstBlur && userName.hasErrors}
                />
                <TextFieldSmallEdible
                  fullWidth
                  id="firstName"
                  label={t('form_input_label_first_name')}
                  name="firstName"
                  autoComplete="given-name"
                  onChange={(e) => {
                    firstName.onChange(e)
                  }}
                  onBlur={(e) => {
                    firstName.onBlur(e)
                  }}
                  value={firstName.value}
                />
                <TextFieldSmallEdible
                  fullWidth
                  id="lastName"
                  label={t('form_input_label_last_name')}
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(e) => {
                    lastName.onChange(e)
                  }}
                  onBlur={(e) => {
                    lastName.onBlur(e)
                  }}
                  value={lastName.value}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="email"
                  label={t('form_input_label_email')}
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    email.onChange(e)
                  }}
                  onBlur={(e) => {
                    email.onBlur(e)
                  }}
                  value={email.value}
                  helperText={email.firstBlur && email.hasErrors ? email.errorText : ''}
                  error={email.firstBlur && email.hasErrors}
                />
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <TextFieldSmallEdible value="+971" readOnly />
                    </Grid>
                    <Grid item xs={9}>
                      <TextFieldSmallEdible
                        fullWidth
                        type="number"
                        required
                        label={t('form_input_label_mobile_number')}
                        onChange={(e) => {
                          phoneNumber.onChange(e)
                        }}
                        onBlur={(e) => {
                          phoneNumber.onBlur(e)
                        }}
                        value={phoneNumber.value}
                        helperText={
                          phoneNumber.firstBlur && phoneNumber.hasErrors
                            ? phoneNumber.errorText
                            : ''
                        }
                        error={phoneNumber.firstBlur && phoneNumber.hasErrors}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Stack spacing={2}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="company-name"
                  label={t('form_input_label_company_name')}
                  name="company-name"
                  onChange={(e) => {
                    company.onChange(e)
                  }}
                  onBlur={(e) => {
                    company.onBlur(e)
                  }}
                  value={company.value}
                  helperText={company.firstBlur && company.hasErrors ? company.errorText : ''}
                  error={company.firstBlur && company.hasErrors}
                />
                <Autocomplete
                  id="address-country"
                  options={[
                    {
                      label: null,
                    },
                    ...countries,
                  ]}
                  getOptionLabel={(option) => option.label || ''}
                  fullWidth
                  autoHighlight
                  value={country.value}
                  onChange={(e, newVal) => {
                    country.onChangeValue(newVal)
                  }}
                  renderOption={(props, option) => {
                    if (!option.label) return null
                    return (
                      <Box
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )
                  }}
                  renderInput={(params) => (
                    <TextFieldSmall
                      required
                      {...params}
                      label={t('form_input_label_country')}
                      helperText={country.firstBlur && country.hasErrors ? country.errorText : ''}
                      error={country.firstBlur && country.hasErrors}
                    />
                  )}
                />
                {REGION_LOCALE === REGION_VERSIONS.AE ? (
                  <Autocomplete
                    required
                    disablePortal
                    id="emirate"
                    freeSolo={country.value?.code !== 'AE'}
                    options={
                      country.value?.code !== 'AE'
                        ? []
                        : [
                            'Dubai',
                            'Abu Dhabi',
                            'Sharjah',
                            'Ajman',
                            'Umm Al-Quwain',
                            'Fujairah',
                            'Ras Al Khaimah',
                          ]
                    }
                    fullWidth
                    value={emirate.value}
                    onChange={(e, newVal) => {
                      emirate.onChangeValue(newVal)
                    }}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        label={
                          country.value?.code !== 'AE'
                            ? t('form_input_label_state')
                            : t('form_input_label_emirate')
                        }
                        value={emirate.value}
                        onChange={(e) => {
                          emirate.onChangeValue(e.target.value)
                        }}
                        required
                        helperText={emirate.firstBlur && emirate.hasErrors ? emirate.errorText : ''}
                        error={emirate.firstBlur && emirate.hasErrors}
                      />
                    )}
                    sx={{
                      marginTop: '10px',
                    }}
                  />
                ) : null}

                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={t('form_input_label_address_line1')}
                  onChange={(e) => {
                    addressLine1.onChange(e)
                  }}
                  onBlur={(e) => {
                    addressLine1.onBlur(e)
                  }}
                  value={addressLine1.value}
                />
                <TextFieldSmallEdible
                  fullWidth
                  label={t('form_input_label_address_line2')}
                  onChange={(e) => {
                    addressLine2.onChange(e)
                  }}
                  onBlur={(e) => {
                    addressLine2.onBlur(e)
                  }}
                  value={addressLine2.value}
                />
                <TextFieldSmallEdible
                  fullWidth
                  label={t('form_input_label_postcode')}
                  onChange={(e) => {
                    postcode.onChange(e)
                  }}
                  onBlur={(e) => {
                    postcode.onBlur(e)
                  }}
                  value={postcode.value}
                />
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <TextFieldSmallEdible value="+971" readOnly />
                    </Grid>
                    <Grid item xs={9}>
                      <TextFieldSmallEdible
                        fullWidth
                        type="number"
                        required
                        label={t('form_input_label_landline_number')}
                        onChange={(e) => {
                          landLineNumber.onChange(e)
                        }}
                        onBlur={(e) => {
                          landLineNumber.onBlur(e)
                        }}
                        value={landLineNumber.value}
                        helperText={
                          landLineNumber.firstBlur && landLineNumber.hasErrors
                            ? landLineNumber.errorText
                            : ''
                        }
                        error={landLineNumber.firstBlur && landLineNumber.hasErrors}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="trade-license-number"
                  label={t('form_input_label_trade_license_number')}
                  name="trade-license-number"
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onChange={(e) => {
                    licenseNumber.onChange(e)
                  }}
                  onBlur={(e) => {
                    licenseNumber.onBlur(e)
                  }}
                  value={licenseNumber.value}
                  helperText={
                    licenseNumber.firstBlur && licenseNumber.hasErrors
                      ? licenseNumber.errorText
                      : ''
                  }
                  error={licenseNumber.firstBlur && licenseNumber.hasErrors}
                />
                {REGION_LOCALE === REGION_VERSIONS.AE ? (
                  <Box
                    sx={{
                      '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiButtonBase-root .MuiSvgIcon-root': {
                        fill: '#757575',
                      },
                    }}
                  >
                    <LocalizationProvider
                      sx={{ width: '100%' }}
                      fullWidth
                      dateAdapter={AdapterDayjs}
                    >
                      <DesktopDatePicker
                        label={t('form_input_label_trade_licence_expiry_date')}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => (
                          <TextFieldSmallEdible {...params} fullWidth required />
                        )}
                        onChange={(newVal) => {
                          date.onChangeValue(newVal)
                        }}
                        value={date.value}
                        disableHighlightToday={true}
                        mask={'__/__/____'}
                        minDate={currentDate}
                        helperText={date.firstBlur && date.hasErrors ? date.errorText : ''}
                        error={date.hasErrors}
                        DateRangeIcon={
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 3.5H20.0001V1.49994C20.0001 0.948078 19.552 0.5 18.9999 0.5H18C17.448 0.5 17.0001 0.948078 17.0001 1.49994V3.5H6.99994V1.49994C6.99994 0.948078 6.55205 0.5 6 0.5H5.00006C4.44802 0.5 3.99994 0.948078 3.99994 1.49994V3.5H3C1.34602 3.5 0 4.84602 0 6.5V21.5C0 23.154 1.34602 24.5 3 24.5H21C22.654 24.5 24 23.154 24 21.5V6.5C24 4.84602 22.654 3.5 21 3.5ZM21.9999 21.5C21.9999 22.051 21.551 22.4999 21 22.4999H3C2.44903 22.4999 2.00006 22.051 2.00006 21.5V10.54H21.9999V21.5Z"
                              fill="#757575"
                            />
                          </svg>
                        }
                        InputProps={{
                          calendaricon: (
                            <svg
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 3.5H20.0001V1.49994C20.0001 0.948078 19.552 0.5 18.9999 0.5H18C17.448 0.5 17.0001 0.948078 17.0001 1.49994V3.5H6.99994V1.49994C6.99994 0.948078 6.55205 0.5 6 0.5H5.00006C4.44802 0.5 3.99994 0.948078 3.99994 1.49994V3.5H3C1.34602 3.5 0 4.84602 0 6.5V21.5C0 23.154 1.34602 24.5 3 24.5H21C22.654 24.5 24 23.154 24 21.5V6.5C24 4.84602 22.654 3.5 21 3.5ZM21.9999 21.5C21.9999 22.051 21.551 22.4999 21 22.4999H3C2.44903 22.4999 2.00006 22.051 2.00006 21.5V10.54H21.9999V21.5Z"
                                fill="#757575"
                              />
                            </svg>
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : null}
                <TextFieldSmallEdible
                  fullWidth
                  required
                  label={t('form_input_label_tax_registration_number')}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onChange={(e) => {
                    taxRegistrationNumber.onChange(e)
                  }}
                  onBlur={(e) => {
                    taxRegistrationNumber.onBlur(e)
                  }}
                  value={taxRegistrationNumber.value}
                  helperText={
                    taxRegistrationNumber.firstBlur && taxRegistrationNumber.hasErrors
                      ? taxRegistrationNumber.errorText
                      : ''
                  }
                  error={taxRegistrationNumber.firstBlur && taxRegistrationNumber.hasErrors}
                />
                {uploadedFile ? (
                  <Box>
                    <Button
                      variant="outlined"
                      component="div"
                      sx={{
                        cursor: 'auto',
                        textAlign: 'left',
                        justifyContent: 'space-between',
                      }}
                      disableRipple
                      fullWidth
                      endIcon={
                        <Close
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setUploadedFile(null)
                          }}
                        />
                      }
                    >
                      {uploadedFile}
                    </Button>
                  </Box>
                ) : null}
                <Box
                  sx={{
                    '& .MuiButton-root': {
                      backgroundColor: 'transparent',
                      justifyContent: 'start',
                    },
                  }}
                >
                  <Button
                    fullWidth
                    component="label"
                    variant="contained"
                    sx={{
                      maxWidth: '300px',
                      height: '38px',
                      border: `1px solid ${colorsConfig.mainBlue}`,
                      color: '#757575',
                      textTransform: 'none',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        color: 'white',
                        background: colorsConfig.mainBlue,
                        transition: '0.25s all ease-in-out',
                        '& > svg > path': {
                          fill: 'white',
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                  >
                    <svg
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      <path
                        d="M1.58203 18.5H12.1289C13.0013 18.5 13.7109 17.7903 13.7109 16.918V5.77344H10.0195C9.14716 5.77344 8.4375 5.06377 8.4375 4.19141V0.5H1.58203C0.709664 0.5 0 1.20966 0 2.08203V16.918C0 17.7903 0.709664 18.5 1.58203 18.5ZM3.69141 7.91797H10.0195C10.311 7.91797 10.5469 8.15383 10.5469 8.44531C10.5469 8.73679 10.311 8.97266 10.0195 8.97266H3.69141C3.39993 8.97266 3.16406 8.73679 3.16406 8.44531C3.16406 8.15383 3.39993 7.91797 3.69141 7.91797ZM3.69141 10.0273H10.0195C10.311 10.0273 10.5469 10.2632 10.5469 10.5547C10.5469 10.8462 10.311 11.082 10.0195 11.082H3.69141C3.39993 11.082 3.16406 10.8462 3.16406 10.5547C3.16406 10.2632 3.39993 10.0273 3.69141 10.0273ZM3.69141 12.1367H10.0195C10.311 12.1367 10.5469 12.3726 10.5469 12.6641C10.5469 12.9555 10.311 13.1914 10.0195 13.1914H3.69141C3.39993 13.1914 3.16406 12.9555 3.16406 12.6641C3.16406 12.3726 3.39993 12.1367 3.69141 12.1367ZM3.69141 14.2461H7.91016C8.20164 14.2461 8.4375 14.482 8.4375 14.7734C8.4375 15.0649 8.20164 15.3008 7.91016 15.3008H3.69141C3.39993 15.3008 3.16406 15.0649 3.16406 14.7734C3.16406 14.482 3.39993 14.2461 3.69141 14.2461Z"
                        fill="#757575"
                      />
                      <path
                        d="M10.0195 4.71836H13.4019L9.49219 0.808594V4.19101C9.49219 4.48196 9.72858 4.71836 10.0195 4.71836Z"
                        fill="#757575"
                      />
                    </svg>
                    {t('form_input_label_upload_trade_license')}
                    <input
                      type="file"
                      hidden
                      name="upload-license"
                      onChange={(e) => {
                        handleUploadLicense(e.target.value)
                        setUploadFile(e.target.files[0])
                      }}
                    />
                  </Button>
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) => {
                          terms.onChangeValue(e.target.checked)
                        }}
                        value={terms.value}
                      />
                    }
                    label={
                      <Typography>
                        <Trans
                          components={{
                            a: (
                              <Link
                                sx={{
                                  cursor: 'pointer',
                                  marginLeft: '5px',
                                  color: colorsConfig.mainBlue,
                                  textDecoration: 'none',
                                }}
                              />
                            ),
                          }}
                        >
                          form_input_label_terms
                        </Trans>
                      </Typography>
                    }
                  >
                    I agree with terms and conditions
                  </FormControlLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: '8px',
                      backgroundColor: colorsConfig.mainBlue,
                      fontFamily: 'Helvetica',
                      width: '187px',
                      height: '48px',
                      marginLeft: 'auto',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                    }}
                    onClick={() => {
                      handleFormSubmit()
                      setNotificationModal(true)
                    }}
                    disabled={
                      disableOnSubmit ||
                      date.hasErrors ||
                      userName.hasErrors ||
                      email.hasErrors ||
                      !terms.value ||
                      !uploadedFile ||
                      company.hasErrors ||
                      phoneNumber.hasErrors ||
                      licenseNumber.hasErrors ||
                      country.hasErrors ||
                      emirate.hasErrors ||
                      addressLine1.hasErrors ||
                      taxRegistrationNumber.hasErrors
                    }
                  >
                    {t('button_submit')}
                  </Button>
                </Box>
              </Stack>
              <Grid
                container
                justifyContent="flex-end"
                sx={{
                  paddingRight: '10px',
                }}
              >
                <Grid item>
                  <Link
                    variant="body2"
                    onClick={() => {
                      if (setSignInModal) {
                        setSignInModal(true)
                        return
                      }
                      if (handleSignIn) handleSignIn()
                    }}
                    sx={{
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: colorsConfig.mainBlue,
                      textDecoration: 'none',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('p_already_have_account')}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Modal
            open={notificationModal}
            onClose={() => {
              setNotificationModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '608px',
                height: '280px',
                background: 'white',
                borderRadius: '10px',
                padding: '44px 56px',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <img src={logo} alt="logo-pic" />
              <Typography
                sx={{
                  textAlign: 'center',
                  lineHeight: 'normal',
                }}
              >
                {t('p_sign_up_success_message')}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  width: '464px',
                  height: '48px',
                  padding: '15px 60px',
                  backgroundColor: colorsConfig.mainBlue,
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontFamily: 'Helvetica',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  textTransform: 'none',
                  color: 'white',
                }}
                onClick={() => {
                  setNotificationModal(false)
                }}
              >
                {t('button_got_it')}
              </Button>
            </Box>
          </Modal>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default SignUp
