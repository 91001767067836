import React, { useState } from 'react'
import { Box, Typography, Grid, IconButton, Modal, useMediaQuery } from '@mui/material'
import IconAppraisalIntro from '../../assests/img/icons/icon-appraisal-intro.svg'
import ImageAppraisalIntro1 from '../../assests/img/appraisal-intro-1.png'
import ImageAppraisalIntro2 from '../../assests/img/appraisal-intro-2.png'
import ImageAppraisalIntro3 from '../../assests/img/appraisal-intro-3.png'
import { colorsConfig } from '../../config/themeConfig'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import { Close } from '@mui/icons-material'
import SendToCompound from '../SendToCompound'
import { useSelector } from 'react-redux'
import EditVehicle from '../EditVehicle'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import SendToCompoundModal from '../SendToCompound/SendToCompoundModal'

const AppraisalIntro = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carData = useSelector((state) => state.car.carData)

  const [sendToCompoundModal, setSendToCompoundModal] = useState(false)

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        mt: '60px',
        '&.xs-only': {
          mt: '40px',
        },
      }}
    >
      {carData.combinedStatus && carData.combinedStatus === 'car_workflow.moderation_failed' ? (
        <EditVehicle mode={'fromAppraisal'} />
      ) : (
        <Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '15px',
              '&.xs-only': {
                justifyContent: 'flex-start',
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  display: 'none',
                },
              }}
            >
              <img src={IconAppraisalIntro} alt="" />
            </Box>
            <TextH1
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                ml: '25px',
                '&.xs-only': {
                  ml: 0,
                  fontSize: '32px',
                },
              }}
            >
              {!xsOnly ? 'Car Appraisal' : ''} Introduction
            </TextH1>
          </Box>
          <TextNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              textAlign: 'center',
              mb: '60px',
              lineHeight: '18.4px',
              '&.xs-only': {
                textAlign: 'left',
                fontSize: '14px',
                lineHeight: 'normal',
                mb: '10px',
              },
            }}
          >
            Welcome to Car Appraisal, here we can find out more information about your vehicle.This
            process is needed to ensure customers of your vehicle's quality and to estimate the
            right price for it. The more information you provide the higher the price you will be
            able to get.
          </TextNormal>
          <Grid container spacing={xsOnly ? 0 : 2}>
            <Grid item md={6} xs={12}>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '24px',
                  '&.xs-only': {
                    mb: '19px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro1} style={{ width: '100%' }} alt="" />
              </Box>
              {xsOnly ? (
                <TextNormal
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    mb: '25px',
                    lineHeight: '18.4px',
                    '&.xs-only': {
                      fontSize: '14px',
                      lineHeight: 'normal',
                      mb: '10px',
                    },
                  }}
                >
                  Please make sure to take pictures according to the guidelines in the high quality.
                  We suggest to take multiple pictures for every position. Please take your time to
                  check all the additional options your vehicle has, as every little detail can add
                  up to the final price.
                </TextNormal>
              ) : null}
              {!xsOnly ? (
                <>
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '24px',
                      lineHeight: '18.4px',
                      '&.xs-only': {
                        fontSize: '14px',
                        lineHeight: 'normal',
                        mb: '10px',
                      },
                    }}
                  >
                    If you don't have time or equipment to complete this process with desired
                    quality, you can always send your vehicle to our compound, where our trained
                    experts will do everything for you with the best quality and as detailed as
                    possible.
                  </TextNormal>
                  <ButtonAttention
                    className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
                    onClick={() => {
                      setSendToCompoundModal(true)
                    }}
                    sx={{
                      mb: '25px',
                      '&.xs-only': {
                        width: '100%',
                      },
                      '&.tablet-only': {
                        px: '40px',
                      },
                    }}
                  >
                    Send to our compound
                  </ButtonAttention>
                </>
              ) : null}
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  '&.xs-only': {
                    mb: '20px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro2} style={{ width: '100%' }} alt="" />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {!xsOnly ? (
                <TextNormal
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    mb: '25px',
                    lineHeight: '18.4px',
                    '&.xs-only': {
                      fontSize: '14px',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  Please make sure to take pictures according to the guidelines in the high quality.
                  We suggest to take multiple pictures for every position. Please take your time to
                  check all the additional options your vehicle has, as every little detail can add
                  up to the final price.
                </TextNormal>
              ) : null}
              {xsOnly ? (
                <>
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '24px',
                      lineHeight: '18.4px',
                      '&.xs-only': {
                        fontSize: '14px',
                        lineHeight: 'normal',
                        mb: '10px',
                      },
                    }}
                  >
                    If you don't have time or equipment to complete this process with desired
                    quality, you can always send your vehicle to our compound, where our trained
                    experts will do everything for you with the best quality and as detailed as
                    possible.
                  </TextNormal>
                  <ButtonAttention
                    className={xsOnly ? 'xs-only' : ''}
                    onClick={() => {
                      setSendToCompoundModal(true)
                    }}
                    sx={{
                      mb: '25px',
                      '&.xs-only': {
                        width: '100%',
                      },
                    }}
                  >
                    Send to our compound
                  </ButtonAttention>
                </>
              ) : null}
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '24px',
                  '&.xs-only': {
                    mb: '10px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro3} style={{ width: '100%' }} alt="" />
              </Box>
              <TextNormal
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  lineHeight: '18.4px',
                  '&.xs-only': {
                    lineHeight: 'normal',
                    fontSize: '14px',
                  },
                }}
              >
                After finishing the appraisal process, it will take up to 24 hours to pass the
                moderation. Once it's done you will be notified as soon as possible and you will be
                able to auction your car. You may also request the quality check of your appraisal
                to ensure that it's done up to the platform's standards. We will check all the
                photos and leave the comments after each missing or incorrectly filled information.
                <br />
                <br />
                You can always save your progress and go back whenever you want to finish the
                appraisal.
              </TextNormal>
            </Grid>
          </Grid>
          <Modal
            open={sendToCompoundModal}
            onClose={() => {
              setSendToCompoundModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 430,
                background: 'white',
                borderRadius: '10px',
                padding: '55px',
                maxHeight: '80vh',
                overflow: 'auto',
              }}
              className={'disable-scrollbar'}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setSendToCompoundModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '40px',
                  fontWeight: 700,
                  mb: '30px',
                  lineHeight: '46px',
                }}
              >
                Send your car <br /> to us
              </Typography>
              <SendToCompoundModal
                closeModal={() => {
                  setSendToCompoundModal(false)
                }}
              />
            </Box>
          </Modal>
        </Box>
      )}
    </Box>
  )
}

export default AppraisalIntro
