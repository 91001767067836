import formDate from '../../../../components/Forms/FormComponents/FormDate'

export const editRVIPolicyInitialState = {
  policyNumber: '',
  internalCustomerId: '',
  dealerId: '',
  modelId: '',
  customerContractNumber: '',
  color: '',
  mileage: '',
  vehicleGrossPriceExcludingOptionsIncludingVAT: '',
  vehicleOptionsIncludingVAT: '',
  discountIncludingVAT: '',
  vehicleGrossPriceIncludingOptionsIncludingVAT: '',
  vehicleNetPriceIncludingVAT: '',
  mileagePa: '',
  contractDurationInMonths: '',
  offerId: '',
  firstRegistrationDate: '',
  salesPeriod: '',
  businessPartnerId: '',
  RVWarrant: '',
  customerReference: '',
  listPriceIncludingOptionsExcludingVAT: '',
  RVPremiumExcludingVAT: '',
  RVPremiumPercent: '',
  risk: '',
  handlingFee: '',
  inRisk: '',
  reinsurance: '',
  reinsurer: '',
  reinsuranceBrutto: '',
  insuranceTax: '',
  reinsuranceNetto: '',
  reinsuranceRisk: '',
  reinsuranceVK: '',
  reserves: '',
  status: '',
  realEndDate: '',
  initialRVPercent: '',
  initialResidualValueIncludingVAT: '',
}

export const getRVIPolicyFormData = (formData) => {
  return {
    details: {
      ...formData,
      RVPremiumPercent: formData.RVPremiumPercent.toString(),
      initialRVPercent: formData.initialRVPercent.toString(),
    },
  }
}
