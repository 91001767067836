import { createAsyncThunk } from '@reduxjs/toolkit'
import rviApiService from '../../../api/rviService/rviApiService'
import { setCurrentPageTitle } from '../authSlice'
import { toast } from 'react-toastify'

export const getRviPolicy = createAsyncThunk(
  'rviPolicy/getRviPolicy',
  async ({ policyId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await rviApiService.getPolicyById(policyId)
      dispatch(setCurrentPageTitle(`RVI Policy: ${response.data?.details?.policyNumber ?? '-'}`))
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const updateRviPolicy = createAsyncThunk(
  'rviPolicy/updateRviPolicy',
  async ({ policyId, data }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.patchPolicy(policyId, data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const rviStartRestitution = createAsyncThunk(
  'rviPolicy/rviStartRestitution',
  async ({ policyId, formData }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToRestitutionStarted(policyId, formData)
      return response.data
    } catch (e) {
      toast.error(e?.response?.data?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const cancelPolicy = createAsyncThunk(
  'rviPolicy/cancelPolicy',
  async ({ policyId, data }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToCancel(policyId, data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const closeDueToEndOfContract = createAsyncThunk(
  'rviPolicy/closeDueToEndOfContract',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToClosedDueToEndOfContract(policyId)
      return response.data
    } catch (e) {
      console.log({ e })
      return rejectWithValue(e)
    }
  },
)

export const closePolicyAsDealer = createAsyncThunk(
  'rviPolicy/closePolicyAsDealer',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToClosedByDealer(policyId)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
