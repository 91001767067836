import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  InputAdornment,
  useMediaQuery,
} from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import { ExpandMoreRounded } from '@mui/icons-material'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import IconBlueCar from '../../../assests/img/icons/icon-blue-car.svg'
import OffersSlider from './OffersSlider'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { useParams } from 'react-router-dom'
import IconAppleGreen from '../../../assests/img/icons/icon-apple-green.svg'
import IconAppleRed from '../../../assests/img/icons/icon-apple-red.svg'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import SliderApple from '../../../assests/img/eco-slider-apple.png'
import { financeRequest, privateRequest } from '../../../utils/request'
import { API_FINANCE_ENDPOINTS, DEALER_PRODUCTS_TYPES } from '../../../config/default'
import { useSelector, useDispatch } from 'react-redux'
import SelectSmall from '../../../ui/SelectSmall'
import {
  setLoanCalculatorOpened,
  updateLoanCalculatorParams,
  updateTerms,
  updateLoanApplication,
} from '../../../store/features/financeCar'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { toast } from 'react-toastify'
import FormControlTFS from '../../../ui/CustomSelect/FormControlTFS'
import InputLabelTFS from '../../../ui/CustomSelect/InputLabelTFS'
import SelectTFS from '../../../ui/CustomSelect/SelectTFS'
import AdditionalProductsModal from './AdditionalProductsModal'
import AdditionalProductsBlock from './AdditionalProductsBlock'

const renderEcoLabel = (topLine, bottomLine) => {
  return (
    <Box sx={{ textAlign: 'center', pt: '7px' }}>
      <TextNormal sx={{ fontSize: '14px' }}>{topLine}</TextNormal>
      <TextNormal sx={{ fontSize: '12px' }}>{bottomLine}</TextNormal>
    </Box>
  )
}

export const ecoMarks = [
  {
    value: 0,
    amount: 0,
    label: renderEcoLabel('I breathe CO2', '0 AED/month'),
    title: '0 AED/month (I breathe CO2)',
  },
  {
    value: 1,
    amount: 5,
    label: renderEcoLabel('Eco Enthusiast', '+5 AED/month'),
    title: '+5 AED/month (Eco Enthusiast)',
  },
  {
    value: 2,
    amount: 10,
    label: renderEcoLabel('Eco Enthusiast', '+10 AED/month'),
    title: '+10 AED/month (Eco Enthusiast)',
  },
  {
    value: 3,
    amount: 20,
    label: renderEcoLabel('Earth Friend', '+20 AED/month'),
    title: '+20 AED/month (Earth Friend)',
  },
  {
    value: 4,
    amount: 30,
    label: renderEcoLabel('Earth Friend', '+30 AED/month'),
    title: '+30 AED/month (Earth Friend)',
  },
  {
    value: 5,
    amount: 40,
    label: renderEcoLabel('Earth Supporter', '+40 AED/month'),
    title: '+40 AED/month (Earth Supporter)',
  },
  {
    value: 6,
    amount: 50,
    label: renderEcoLabel('Earth Supporter', '+50 AED/month'),
    title: '+50 AED/month (Earth Supporter)',
  },
  {
    value: 7,
    amount: 60,
    label: renderEcoLabel('Earth Supporter', '+60 AED/month'),
    title: '+60 AED/month (Earth Supporter)',
  },
  {
    value: 8,
    amount: 70,
    label: renderEcoLabel('Earth Protector', '+70 AED/month'),
    title: '+70 AED/month (Earth Protector)',
  },
  {
    value: 9,
    amount: 80,
    label: renderEcoLabel('Earth Hero', '+80 AED/month'),
    title: '+80 AED/month (Earth Hero)',
  },
  {
    value: 10,
    amount: 100,
    label: renderEcoLabel('Earth Savior', '+100 AED/month'),
    title: '+100 AED/month (Earth Savior)',
  },
]

const LoanCalculator = () => {
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const loanCalculatorParams = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams,
  )
  const expanded = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorOpened,
  )
  const saved = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorSaved,
  )
  const loanApplicationId = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab]['@id'],
  )
  const currentWorkflowState = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].currentWorkflowState,
  )
  const selectedPackage = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.selectedPackage,
  )
  const additionalProducts = useSelector(
    (state) =>
      state.carFinance.loanApplications[selectedTab].loanCalculatorParams.additionalProducts,
  )

  const [annualMileage, setAnnualMileage] = useState(2)
  const [termsLoading, setTermsLoading] = useState(false)
  const [additionalProductsModal, setAdditionalProductsModal] = useState(false)

  const params = useParams()

  const carData = useSelector((state) => state.car.carData)

  const isEditingEnabled =
    currentWorkflowState === 'blank' ||
    currentWorkflowState === 'new' ||
    currentWorkflowState === 'application_preparation'

  const handleSubmitLoanApplication = () => {
    const currentDownPayment =
      loanCalculatorParams.downPaymentIn === 'in %'
        ? Math.floor(
            parseFloat(loanCalculatorParams.carPrice) *
              (parseFloat(loanCalculatorParams.downPayment) / 100),
          )
        : parseInt(loanCalculatorParams.downPaymentInCurrency)
    const creditType =
      selectedPackage === 'full' || (selectedPackage === 'custom' && additionalProducts.rvi)
        ? 'CREDIT_TYPE_BALLOON'
        : 'CREDIT_TYPE_CLASSICAL'
    let requestedDealerProducts = []
    if (additionalProducts.casco) requestedDealerProducts.push(DEALER_PRODUCTS_TYPES.casco)
    if (additionalProducts.warranty) requestedDealerProducts.push(DEALER_PRODUCTS_TYPES.warranty)
    if (additionalProducts.ppi) requestedDealerProducts.push(DEALER_PRODUCTS_TYPES.ppi)
    const formData = {
      dp: currentDownPayment.toString(),
      additionalAmount: '0',
      carPrice: loanCalculatorParams.carPrice.toString(),
      term: parseInt(loanCalculatorParams.selectedTerm),
      car: `/cars/${params.id}`,
      creditType: creditType,
      annualMileage: annualMileage * 5000,
      additionalMonthlyProducts: [
        {
          formula: 'monthly_fixed',
          amount: ecoMarks[loanCalculatorParams.ecoSacrifice].amount.toString(),
        },
      ],
      requestedDealerProducts,
    }
    const putFormData = {
      dp: currentDownPayment.toString(),
      carPrice: loanCalculatorParams.carPrice.toString(),
      additionalAmount: '0',
      term: parseInt(loanCalculatorParams.selectedTerm),
      additionalMonthlyProducts: [
        {
          formula: 'monthly_fixed',
          amount: ecoMarks[loanCalculatorParams.ecoSacrifice].amount.toString(),
        },
      ],
      requestedDealerProducts,
    }
    if (saved) {
      privateRequest
        .put(loanApplicationId, putFormData, {
          headers: {
            'Content-type': 'application/ld+json',
            Accept: 'application/ld+json',
          },
        })
        .then(() => {
          dispatch(setLoanCalculatorOpened(false))
          window.scrollTo(0, 740)
        })
    } else {
      privateRequest
        .post(`/finance/loan-applications`, formData)
        .then((response) => {
          dispatch(updateLoanApplication(response.data))
          window.scrollTo(0, 740)
        })
        .catch((error) => {
          toast.error(
            error?.response?.data['hydra:description']
              ? error?.response?.data['hydra:description']
              : 'Unexpected error',
          )
        })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 740)
  }, [])

  const isDownPaymentValid = () => {
    return (
      (loanCalculatorParams.downPaymentIn === 'in %' && loanCalculatorParams.downPayment !== '') ||
      (loanCalculatorParams.downPaymentIn === 'in AED' &&
        parseInt(loanCalculatorParams.downPaymentInCurrency) > 0)
    )
  }

  const getDownPaymentInPercents = () => {
    return Math.round(
      (parseInt(loanCalculatorParams.downPaymentInCurrency) /
        parseInt(loanCalculatorParams.carPrice)) *
        100,
    )
  }

  const isSaveButtonDisabled = () => {
    if (!isDownPaymentValid()) return true
    if (!loanCalculatorParams.carPrice) return true
    if (!loanCalculatorParams.selectedTerm) return true
    if (loanCalculatorParams.terms.length === 0) return true
    return false
  }

  useEffect(() => {
    let calculationRequestSent = true
    if (
      loanCalculatorParams.carPrice &&
      loanCalculatorParams.carPrice > 0 &&
      isDownPaymentValid()
    ) {
      setTermsLoading(true)
      const currentDate = new Date()
      const currentDownPayment =
        loanCalculatorParams.downPaymentIn === 'in %'
          ? Math.floor(
              parseFloat(loanCalculatorParams.carPrice) *
                (parseFloat(loanCalculatorParams.downPayment) / 100),
            )
          : parseInt(loanCalculatorParams.downPaymentInCurrency)
      const carAge =
        (currentDate.getFullYear() - carData.carMainSpecs.firstRegistrationYear) * 12 +
        currentDate.getMonth() -
        carData.carMainSpecs.firstRegistrationMonth +
        1
      let customPackProducts = []
      if (additionalProducts.casco)
        customPackProducts.push({ classification: DEALER_PRODUCTS_TYPES.casco })
      if (additionalProducts.ppi)
        customPackProducts.push({ classification: DEALER_PRODUCTS_TYPES.ppi })
      if (additionalProducts.warranty)
        customPackProducts.push({ classification: DEALER_PRODUCTS_TYPES.warranty })
      if (calculationRequestSent) {
        Promise.all([
          financeRequest.post(API_FINANCE_ENDPOINTS.loanPrograms.calculationSummary, {
            fromTerm: 12,
            toTerm: 60,
            step: 12,
            commonCalcParams: {
              makeId: carData.make.id.toString(),
              modelId: carData.model.id.toString(),
              carType: 'CAR_TYPE_USED',
              mileage: carData.carMainSpecs.mileage,
              annualMileage: annualMileage * 5000,
              creditType: 'CREDIT_TYPE_CLASSICAL',
              carAge: carAge || 20,
              dp: currentDownPayment,
              carPrice: parseFloat(loanCalculatorParams.carPrice),
              additionalAmount: 0,
              additionalProducts: [
                {
                  formula: 'monthly_fixed',
                  amount: ecoMarks[loanCalculatorParams.ecoSacrifice].amount,
                },
              ],
            },
          }),
          financeRequest.post(API_FINANCE_ENDPOINTS.loanPrograms.calculationSummary, {
            fromTerm: 12,
            toTerm: 60,
            step: 12,
            commonCalcParams: {
              makeId: carData.make.id.toString(),
              modelId: carData.model.id.toString(),
              carType: 'CAR_TYPE_USED',
              mileage: carData.carMainSpecs.mileage,
              annualMileage: annualMileage * 5000,
              creditType: 'CREDIT_TYPE_BALLOON',
              carAge: carAge || 20,
              dp: currentDownPayment,
              carPrice: parseFloat(loanCalculatorParams.carPrice),
              additionalAmount: 0,
              additionalProducts: [
                {
                  formula: 'monthly_fixed',
                  amount: ecoMarks[loanCalculatorParams.ecoSacrifice].amount,
                },
              ],
              dealerProducts: [
                {
                  classification: DEALER_PRODUCTS_TYPES.casco,
                },
                {
                  classification: DEALER_PRODUCTS_TYPES.ppi,
                },
                {
                  classification: DEALER_PRODUCTS_TYPES.warranty,
                },
              ],
            },
          }),
          financeRequest.post(API_FINANCE_ENDPOINTS.loanPrograms.calculationSummary, {
            fromTerm: 12,
            toTerm: 60,
            step: 12,
            commonCalcParams: {
              makeId: carData.make.id.toString(),
              modelId: carData.model.id.toString(),
              carType: 'CAR_TYPE_USED',
              mileage: carData.carMainSpecs.mileage,
              annualMileage: (annualMileage + 1) * 5000,
              creditType: additionalProducts.rvi ? 'CREDIT_TYPE_BALLOON' : 'CREDIT_TYPE_CLASSICAL',
              carAge: carAge || 20,
              dp: currentDownPayment,
              carPrice: parseFloat(loanCalculatorParams.carPrice),
              additionalAmount: 0,
              additionalProducts: [
                {
                  formula: 'monthly_fixed',
                  amount: ecoMarks[loanCalculatorParams.ecoSacrifice].amount,
                },
              ],
              dealerProducts: customPackProducts,
            },
          }),
        ])
          .then((result) => {
            const response = result[0]
            const responseFullPack = result[1]
            const responseCustomPack = result[2]
            if (calculationRequestSent) {
              const combinedTerms = response.data.items.map((item) => {
                return {
                  ...item,
                  fullPackAmount: Math.floor(
                    responseFullPack.data.items.find((term) => term.term === item.term)
                      ?.minimumMonthlyPayment,
                  ),
                  customPackAmount: Math.floor(
                    responseCustomPack.data.items.find((term) => term.term === item.term)
                      ?.minimumMonthlyPayment,
                  ),
                }
              })
              dispatch(
                updateTerms(
                  combinedTerms.map((item) => {
                    return {
                      ...item,
                      averageMonthlyPayment: Math.floor(item.minimumMonthlyPayment),
                      selected: false,
                    }
                  }),
                ),
              )
            }
          })
          .finally(() => {
            if (calculationRequestSent) {
              setTermsLoading(false)
            }
          })
      }
    }
    return () => {
      calculationRequestSent = false
    }
  }, [
    loanCalculatorParams.carPrice,
    loanCalculatorParams.downPayment,
    loanCalculatorParams.downPaymentInCurrency,
    loanCalculatorParams.ecoSacrifice,
    annualMileage,
    additionalProducts,
  ])

  return (
    <Box
      id={'loan-calculator'}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: colorsConfig.bgLightBlue,
      }}
    >
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          height: '79px',
          py: '17px',
          px: '29px',
          border: '1px solid #DCDCDC',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          zIndex: 2,
          '&.xs-only': {
            px: '16px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box>
          <TextBoldNormal
            sx={{
              color: colorsConfig.mainBlue,
              fontSize: '18px',
            }}
          >
            Loan calculator
          </TextBoldNormal>
          <TextNormal>Set up your car loan</TextNormal>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {saved ? (
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                color: colorsConfig.mainGreen,
                mr: '20px',
              }}
            >
              Saved
            </TextBoldNormal>
          ) : null}
          <IconButton
            className={expanded ? 'open' : ''}
            sx={{
              position: 'relative',
              mr: '-15px',
              transition: '0.25s all ease-in-out',
              '&.open': {
                transform: 'rotate(180deg)',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              dispatch(
                setLoanCalculatorOpened({
                  expanded: !expanded,
                  selectedTab: selectedTab,
                }),
              )
            }}
          >
            <ExpandMoreRounded
              sx={{
                fontSize: '23px',
                width: '40px',
                height: '40px',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={[
          expanded ? '' : 'collapsed',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
        sx={{
          paddingTop: '119px',
          mt: '-79px',
          position: 'relative',
          px: '40px',
          pb: '35px',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: colorsConfig.buttonDisabled,
          background: colorsConfig.bgLightBlue,
          transition: '0.3s all ease-in-out',
          '&.collapsed': {
            mt: '-70%',
            display: 'none',
            opacity: '0',
            pointerEvents: 'none',
            transition: '0.3s all ease-in-out',
          },
          '&.xs-only': {
            px: '20px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '30px',
            '&.xs-only': {
              display: 'block',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: '28%',
              mr: '16px',
              '&.xs-only': {
                width: '100%',
                mb: '20px',
              },
            }}
          >
            <TextFieldSmall
              fullWidth
              type={'text'}
              label={'Car Price'}
              inputProps={{
                inputMode: 'numeric',
              }}
              value={sumDelimiter(loanCalculatorParams?.carPrice)}
              disabled={!isEditingEnabled}
              onChange={(e) => {
                dispatch(
                  updateLoanCalculatorParams({
                    ...loanCalculatorParams,
                    carPrice: e.target.value.replace(/[^0-9]/g, ''),
                  }),
                )
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <TextNormal
                      sx={{
                        fontSize: '14px',
                        color: colorsConfig.iconGray,
                      }}
                    >
                      AED
                    </TextNormal>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {isEditingEnabled ? (
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: 'calc(28% + 99px)',
                mr: '16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '&.xs-only': {
                  width: '100%',
                  mb: '20px',
                },
              }}
            >
              {loanCalculatorParams?.downPaymentIn === 'in %' ? (
                <FormControl
                  fullWidth
                  size={'small'}
                  sx={{
                    color: colorsConfig.iconGray,
                    fontWeight: 400,
                    fontSize: '14px',
                    border: 'none',
                  }}
                >
                  <InputLabel
                    id="label-down-payment"
                    sx={{
                      color: colorsConfig.iconGray,
                      fontWeight: 400,
                      fontSize: '14px',
                      '&.Mui-focused': {
                        background: 'white',
                        color: colorsConfig.mainBlue,
                        boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                        overflow: 'visible',
                      },
                    }}
                  >
                    Down payment
                  </InputLabel>
                  <Select
                    labelId={'label-down-payment'}
                    value={loanCalculatorParams?.downPayment}
                    onChange={(e) => {
                      dispatch(
                        updateLoanCalculatorParams({
                          ...loanCalculatorParams,
                          downPayment: e.target.value,
                        }),
                      )
                    }}
                    sx={{
                      borderRadius: '4px',
                      height: '38px',
                      fontSize: '14px',
                      border: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                      },
                    }}
                  >
                    <MenuItem sx={{ display: 'none' }} selected value={''}></MenuItem>
                    <MenuItem value={5}>5 %</MenuItem>
                    <MenuItem value={10}>10 %</MenuItem>
                    <MenuItem value={15}>15 %</MenuItem>
                    <MenuItem value={20}>20 %</MenuItem>
                    <MenuItem value={25}>25 %</MenuItem>
                    <MenuItem value={30}>30 %</MenuItem>
                    <MenuItem value={35}>35 %</MenuItem>
                    <MenuItem value={40}>40 %</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextFieldSmall
                  fullWidth
                  label={'Down payment'}
                  value={sumDelimiter(loanCalculatorParams?.downPaymentInCurrency)}
                  onChange={(e) => {
                    dispatch(
                      updateLoanCalculatorParams({
                        ...loanCalculatorParams,
                        downPaymentInCurrency: e.target.value.replace(/[^0-9]/g, ''),
                      }),
                    )
                  }}
                />
              )}
              <Box
                sx={{
                  minWidth: '92px',
                  ml: '7px',
                }}
              >
                <SelectSmall
                  value={loanCalculatorParams?.downPaymentIn}
                  setValue={(newVal) =>
                    dispatch(
                      updateLoanCalculatorParams({
                        ...loanCalculatorParams,
                        downPaymentIn: newVal,
                      }),
                    )
                  }
                  id={'down-payment-in'}
                  options={['in %', 'in AED']}
                  sx={{
                    '& .MuiSelect-select': {
                      color: `${colorsConfig.iconGray} !important`,
                    },
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: '28%',
                mr: '16px',
                '&.xs-only': {
                  width: '100%',
                  mb: '20px',
                },
              }}
            >
              <TextFieldSmallEdible
                disabled
                fullWidth
                label={'Down payment'}
                value={`${sumDelimiter(loanCalculatorParams?.downPaymentInCurrency)} AED (${getDownPaymentInPercents()}%)`}
              />
            </Box>
          )}
        </Box>
        <Box>
          {!xsOnly ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '25px',
                  mt: '30px',
                }}
              >
                <TextNormal>Annual Mileage</TextNormal>
                <TextNormal>{annualMileage * 5000 - 5000} km/year</TextNormal>
              </Box>
              <Slider
                marks
                min={1}
                step={1}
                max={10}
                value={annualMileage}
                disabled={!isEditingEnabled}
                onChange={(e, newVal) => {
                  setAnnualMileage(newVal === 1 ? 2 : newVal)
                }}
                sx={{
                  color: colorsConfig.mainBlue,
                  height: '10px',
                  '& .MuiSlider-track': {
                    background: colorsConfig.mainBlue,
                    border: 'none',
                  },
                  '& .MuiSlider-rail': {
                    background: colorsConfig.bgLightBlue,
                    border: 'none',
                    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
                  },
                  '& .MuiSlider-thumb': {
                    width: 0,
                    height: 0,
                    boxShadow: 'none !important',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                    '&::before': {
                      display: 'none',
                    },
                    '&::after': {
                      content: '" "',
                      position: 'absolute',
                      display: 'block',
                      width: '79px',
                      height: '30px',
                      background: `url(${IconBlueCar}) no-repeat`,
                      borderRadius: '0',
                      transform: 'none',
                      top: '-35px',
                      left: '-79px',
                    },
                  },
                  '& .MuiSlider-mark': {
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    background: '#ECEFF0',
                    transform: 'translate(-5px, -50%)',
                    opacity: 1,
                    '&.MuiSlider-markActive': {
                      background: colorsConfig.mainBlue,
                    },
                  },
                }}
              />
            </>
          ) : (
            <FormControlTFS fullWidth>
              <InputLabelTFS id={'annual-mileage'}>Annual mileage</InputLabelTFS>
              <SelectTFS
                labelId={'annual-mileage'}
                value={annualMileage}
                onChange={(e) => setAnnualMileage(e.target.value)}
              >
                <MenuItem value={1}>5'000 km/year</MenuItem>
                <MenuItem value={2}>10'000 km/year</MenuItem>
                <MenuItem value={3}>15'000 km/year</MenuItem>
                <MenuItem value={4}>20'000 km/year</MenuItem>
                <MenuItem value={5}>25'000 km/year</MenuItem>
                <MenuItem value={6}>30'000 km/year</MenuItem>
                <MenuItem value={7}>35'000 km/year</MenuItem>
                <MenuItem value={8}>40'000 km/year</MenuItem>
                <MenuItem value={9}>45'000 km/year</MenuItem>
                <MenuItem value={10}>50'000 km/year</MenuItem>
              </SelectTFS>
            </FormControlTFS>
          )}
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mt: '30px',
            borderRadius: '6px',
            paddingRight: '115px',
            paddingBottom: '15px',
            position: 'relative',
            background: `linear-gradient(90deg, ${colorsConfig.bgLightBlue} 0%, ${colorsConfig.bgLightBlue} 29%, rgba(244,255,243,1) 100%)`,
            '&.xs-only': {
              px: 0,
              borderRadius: 0,
              background: 'none',
            },
          }}
        >
          <TextBoldNormal sx={{ mb: '2px' }}>Make the planet greener</TextBoldNormal>
          <TextNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mb: '8px',
              lineHeight: 'normal',
              '&.xs-only': {
                mb: '20px',
              },
            }}
          >
            By opting for this service, you're supporting carbon offset initiatives that counteract
            the environmental impact of using conventional vehicles.
            {!xsOnly ? (
              <>
                We invite you to join us in our mission and get{' '}
                <span style={{ fontWeight: 700 }}>[your offer]</span>
              </>
            ) : (
              ''
            )}
          </TextNormal>
          {!xsOnly ? (
            <Box sx={{ px: '10px' }}>
              <Slider
                marks={ecoMarks.map((item, index) => {
                  return {
                    ...item,
                    label: loanCalculatorParams?.ecoSacrifice === index ? item.label : '',
                  }
                })}
                min={0}
                step={1}
                max={10}
                disabled={!isEditingEnabled}
                value={loanCalculatorParams?.ecoSacrifice}
                onChange={(e, newVal) => {
                  dispatch(
                    updateLoanCalculatorParams({
                      ...loanCalculatorParams,
                      ecoSacrifice: newVal,
                    }),
                  )
                }}
                sx={{
                  color: colorsConfig.mainBlue,
                  height: '10px',
                  '& .MuiSlider-track': {
                    background: colorsConfig.mainGreen,
                    border: 'none',
                  },
                  '& .MuiSlider-rail': {
                    background: colorsConfig.bgLightBlue,
                    border: 'none',
                    boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
                  },
                  '& .MuiSlider-thumb': {
                    width: 0,
                    height: 0,
                    boxShadow: 'none !important',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                    '&::before': {
                      display: 'none',
                    },
                    '&::after': {
                      content: '"ECO"',
                      position: 'absolute',
                      width: '28px',
                      height: '29px',
                      top: '-2.5px',
                      left: '4px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '8px',
                      color:
                        loanCalculatorParams?.ecoSacrifice === 0 ? 'white' : colorsConfig.mainBlack,
                      background: `url(${loanCalculatorParams?.ecoSacrifice === 0 ? IconAppleRed : IconAppleGreen}) no-repeat`,
                      backgroundSize: '100% auto',
                    },
                  },
                  '& .MuiSlider-mark': {
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    background: '#ECEFF0',
                    transform: 'translate(-5px, -50%)',
                    opacity: 1,
                    '&.MuiSlider-markActive': {
                      background: colorsConfig.mainGreen,
                    },
                  },
                }}
              />
            </Box>
          ) : (
            <Box>
              <FormControlTFS fullWidth>
                <SelectTFS
                  value={loanCalculatorParams?.ecoSacrifice}
                  onChange={(e) => {
                    dispatch(
                      updateLoanCalculatorParams({
                        ...loanCalculatorParams,
                        ecoSacrifice: e.target.value,
                      }),
                    )
                  }}
                >
                  {ecoMarks.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.title}
                      </MenuItem>
                    )
                  })}
                </SelectTFS>
              </FormControlTFS>
            </Box>
          )}
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              height: '100%',
              borderRadius: '0 6px 6px 0',
              overflow: 'hidden',
              '&.xs-only': {
                display: 'none',
              },
            }}
          >
            <img src={SliderApple} style={{ height: '100%', width: 'auto' }} alt="" />
          </Box>
        </Box>
        <Box
          sx={{
            mt: '30px',
          }}
        >
          {loanCalculatorParams?.terms?.length > 0 ? (
            <TextBoldNormal
              sx={{
                mb: '30px',
              }}
            >
              Select the term
            </TextBoldNormal>
          ) : null}
          <Box
            sx={{
              position: 'relative',
              mb: '30px',
            }}
          >
            <OffersSlider
              loading={termsLoading}
              onSelectSlide={() => {
                setAdditionalProductsModal(true)
              }}
            />
          </Box>
        </Box>
        {loanCalculatorParams?.terms?.length === 0 &&
        isDownPaymentValid() &&
        loanCalculatorParams?.carPrice &&
        !termsLoading ? (
          <TextNormal
            sx={{
              textAlign: 'center',
              p: '20px',
            }}
          >
            Sorry, we couldn't find any suitable offer for requested parameters
          </TextNormal>
        ) : null}
        {selectedPackage !== '' ? (
          <AdditionalProductsBlock
            handleChange={() => {
              setAdditionalProductsModal(true)
            }}
          />
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: '40px',
          }}
        >
          {isEditingEnabled ? (
            <ButtonLG
              fullWidth={xsOnly}
              disabled={isSaveButtonDisabled()}
              onClick={() => {
                handleSubmitLoanApplication()
              }}
            >
              {currentWorkflowState === 'blank' ? 'Save and continue' : 'Update'}
            </ButtonLG>
          ) : null}
        </Box>
      </Box>
      <AdditionalProductsModal
        open={additionalProductsModal}
        setOpen={setAdditionalProductsModal}
      />
    </Box>
  )
}

export default LoanCalculator
