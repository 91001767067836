import React from 'react'
import TextNormal from '../ui/Text/TextNormal'
import { colorsConfig } from '../config/themeConfig'

const TabTitleWithRedCounter = (props) => {
  const { title, counter } = props

  return (
    <TextNormal
      sx={{
        fontWeight: 500,
      }}
    >
      {title} <span style={{ color: colorsConfig.mainRed, fontWeight: 500 }}>({counter})</span>
    </TextNormal>
  )
}

export default TabTitleWithRedCounter
