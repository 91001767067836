import { createSlice } from '@reduxjs/toolkit'
import { ecoMarks } from '../../pages/CarPage/DealerFinancing/LoanCalculator'
import { countries } from '../../default-data/coutries'
import { DEALER_PRODUCTS_TYPES } from '../../config/default'

const initialLoanCalculatorParams = {
  carPrice: '',
  downPayment: '',
  downPaymentIn: 'in %',
  downPaymentInCurrency: '',
  ecoSacrifice: 2,
  terms: [],
  selectedTerm: '',
  selectedPackage: '',
  additionalProducts: {
    rvi: true,
    warranty: false,
    casco: false,
    ppi: false,
  },
}

const initialCustomerDocuments2 = [
  {
    id: 1,
    required: true,
    title: 'Valid Emirates ID',
    systemName: 'emirates_id',
    files: [],
    employmentStatuses: ['Employed', 'Self-employed'],
  },
  {
    id: 2,
    title: 'Valid passport (valid UAE visa for expatriates)',
    systemName: 'passport',
    files: [],
    employmentStatuses: ['Employed', 'Self-employed'],
  },
  {
    id: 3,
    title: 'Valid UAE driving license',
    systemName: 'driving_license',
    files: [],
    employmentStatuses: ['Employed', 'Self-employed'],
  },
  {
    id: 4,
    title: "Last 3 months' salary or income transfer bank statements",
    systemName: 'statements_3months',
    files: [],
    employmentStatuses: ['Employed'],
    caption: 'A longer period may be required if cheque returns are identified',
  },
  {
    id: 5,
    title: 'Proof of income',
    systemName: 'proof_of_income',
    files: [],
    employmentStatuses: ['Employed'],
    caption:
      'Salary certificate/salary transfer letter if salary is not assigned to a HSBC bank or other banks under narration salary',
  },
  {
    id: 6,
    title: "Last 6 months' personal and business bank statements",
    systemName: 'statements_6months',
    files: [],
    employmentStatuses: ['Self-employed'],
  },
  {
    id: 7,
    title: 'Audited financials for the last 2 years',
    systemName: 'audited_financials',
    files: [],
    employmentStatuses: ['Self-employed'],
  },
  {
    id: 8,
    title: 'KYC forms / Company Profile',
    systemName: 'kyc_forms',
    files: [],
    employmentStatuses: ['Company loan'],
    caption: 'Available at the branch or with the sales staff',
  },
  {
    id: 9,
    title: 'Signed Etihad Credit Bureau consent form',
    systemName: 'credit_bureau_consent',
    files: [],
    employmentStatuses: ['Company loan'],
  },
  {
    id: 10,
    title: 'UAE FTS',
    systemName: 'uae_fts',
    files: [],
    employmentStatuses: ['Company loan'],
    caption: 'Statement of account',
  },
  {
    id: 11,
    title: 'Trade license',
    systemName: 'trade_license',
    files: [],
    employmentStatuses: ['Self-employed', 'Company loan'],
  },
  {
    id: 12,
    title: 'Memorandum of association (MOA)',
    systemName: 'moa',
    files: [],
    employmentStatuses: ['Company loan'],
  },
  {
    id: 13,
    title: 'Articles of Association (AOA)',
    systemName: 'aoa',
    files: [],
    employmentStatuses: ['Company loan'],
  },
  {
    id: 14,
    title: 'Power of Attorney (POA)',
    systemName: 'poa',
    files: [],
    employmentStatuses: ['Company loan'],
    caption: 'Borrowing power',
  },
  {
    id: 15,
    title: 'Documents to show your company ownership structure',
    systemName: 'ownership_documents',
    files: [],
    employmentStatuses: ['Self-employed'],
  },
  {
    title:
      'National ID(s) or valid passport(s) with UAE residence visa and Emirates ID(s) of all signing authorities and shareholders',
    systemName: 'company_owners_identity_documents',
    files: [],
    employmentStatuses: ['Company loan'],
  },
]

const initialCustomerData = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  country: null,
  email: '',
  phoneNumber: '',
  nationality: '',
  employerName: '',
  yearsInEmployment: '',
  salaryTransferBank: '',
  personalDataConsent: false,
  companyName: '',
}

const initialState = {
  banks: [],
  loanApplications: [],
  loanApplicationsLoading: true,
  selectedTab: 0,
}

const LoanApplicationWorkflowTypes = {
  new: 'new',
  applicationPreparation: 'application_preparation',
  approvalProcessStarted: 'approval_process_started',
}

const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2)
}

export const carFinanceSlice = createSlice({
  name: 'carFinance',
  initialState,
  reducers: {
    setBanks: (state, action) => {
      state.banks = action.payload
    },
    setLoanApplications: (state, action) => {
      state.loanApplications = action.payload.map((item) => {
        const loanDealsExtended = item.loanDeals.map((item) => {
          return {
            ...item,
            note: '',
            additionalDocuments: [],
          }
        })
        const customerDocs = initialCustomerDocuments2.map((document) => {
          return {
            ...document,
            files: item.customer.files.filter((file) => file.type === document.systemName),
          }
        })
        let selectedPackage = 'finance-only'
        if (
          item.creditType === 'CREDIT_TYPE_BALLOON' &&
          item?.requestedDealerProducts?.length === 3
        ) {
          selectedPackage = 'full'
        } else if (
          item.creditType === 'CREDIT_TYPE_BALLOON' ||
          item?.requestedDealerProducts?.length > 0
        ) {
          selectedPackage = 'custom'
        }
        const additionalProductsSaved = Boolean(
          item.additionalMonthlyProducts && item.additionalMonthlyProducts.length > 0,
        )
        return {
          ...item,
          loanCalculatorOpened: !!item.loanCalculatorOpened,
          loanCalculatorSaved: true,
          customerDataFormOpened: item.currentWorkflowState === LoanApplicationWorkflowTypes.new,
          customerDataFormSaved: item.currentWorkflowState !== LoanApplicationWorkflowTypes.new,
          customerDataFormDisabled: false,
          loanCalculatorParams: {
            carPrice: parseInt(item.carPrice),
            downPayment: '',
            downPaymentIn: 'in AED',
            downPaymentInCurrency: parseInt(item.dp),
            ecoSacrifice: additionalProductsSaved
              ? ecoMarks.filter(
                  (mark) =>
                    mark.amount ===
                    parseInt(
                      item.additionalMonthlyProducts.filter(
                        (product) => product.formula === 'monthly_fixed',
                      )[0].amount,
                    ),
                )[0].value
              : 0,
            terms: [],
            selectedTerm: item.term,
            selectedPackage,
            additionalProducts: {
              rvi: item?.creditType === 'CREDIT_TYPE_BALLOON',
              casco: Boolean(
                item?.requestedDealerProducts?.find((item) => item === DEALER_PRODUCTS_TYPES.casco),
              ),
              ppi: Boolean(
                item?.requestedDealerProducts?.find((item) => item === DEALER_PRODUCTS_TYPES.ppi),
              ),
              warranty: Boolean(
                item?.requestedDealerProducts?.find(
                  (item) => item === DEALER_PRODUCTS_TYPES.warranty,
                ),
              ),
            },
          },
          customer: {
            ...initialCustomerData,
            ...item.customer,
            ...item.customer?.person,
            country:
              countries.filter((country) => country.label === item.customer?.person?.country)[0] ||
              null,
            nationality:
              countries.filter(
                (country) => country.label === item.customer?.person?.nationality,
              )[0] || null,
            phoneNumber: item.customer?.person?.phoneNumber?.replace('+971', '')
              ? item.customer?.phoneNumber?.replace('+971', '')
              : '',
          },
          customerDocuments: customerDocs,
          customerEmploymentStatus: item.customer?.employmentStatus
            ? item.customer?.employmentStatus
            : 'Employed',
          customerYearlySalary: item.customer?.yearlySalary ? item.customer?.yearlySalary : '',
          customerMonthlySalary: item.customer?.monthSalary ? item.customer?.monthSalary : '',
          sendToBanksButtonDisabled:
            item.currentWorkflowState !== LoanApplicationWorkflowTypes.applicationPreparation,
          bankResponsesDisabled: !Boolean(
            item.loanDeals.filter(
              (loanDeal) =>
                loanDeal.currentWorkflowState !== 'new' &&
                loanDeal.currentWorkflowState !== 'bank_sent',
            )[0],
          ),
          bankResponsesOpened: Boolean(
            item.loanDeals.filter(
              (loanDeal) =>
                loanDeal.currentWorkflowState !== 'new' &&
                loanDeal.currentWorkflowState !== 'bank_sent',
            )[0],
          ),
          loanDeals: loanDealsExtended.sort((x, y) =>
            x.isCustomerSelected === y.isCustomerSelected ? 0 : x.isCustomerSelected ? -1 : 1,
          ),
        }
      })
      state.loanApplicationsLoading = false
    },
    createLoanApplication: (state) => {
      state.loanApplications.push({
        '@id': null,
        id: `blank_${uid()}`,
        additionalMonthlyProducts: [
          {
            formula: 'monthly_fixed',
            amount: '20',
          },
        ],
        loanCalculatorOpened: true,
        loanCalculatorSaved: false,
        customerDataFormOpened: false,
        customerDataFormSaved: false,
        customerDataFormDisabled: true,
        loanCalculatorParams: initialLoanCalculatorParams,
        customer: initialCustomerData,
        customerEmploymentStatus: 'Employed',
        customerYearlySalary: '',
        customerMonthlySalary: '',
        customerDocuments: initialCustomerDocuments2,
        currentWorkflowState: 'blank',
        sendToBanksButtonDisabled: true,
        bankResponsesDisabled: true,
        bankResponsesOpened: false,
      })
      state.selectedTab = state.loanApplications.length - 1
      state.loanApplicationsLoading = false
    },
    updateLoanApplication: (state, action) => {
      state.loanApplications[state.selectedTab].id = action.payload.id
      state.loanApplications[state.selectedTab]['@id'] = action.payload['@id']
      state.loanApplications[state.selectedTab].currentWorkflowState = 'new'
      state.loanApplications[state.selectedTab].loanCalculatorOpened = false
      state.loanApplications[state.selectedTab].loanCalculatorSaved = true
      state.loanApplications[state.selectedTab].customerDataFormDisabled = false
      state.loanApplications[state.selectedTab].customerDataFormOpened = true
      state.loanApplications[state.selectedTab].additionalMonthlyProducts =
        action.payload.additionalMonthlyProducts
      state.loanApplications[state.selectedTab].loanDeals = action.payload.loanDeals
    },
    updateLoanCalculatorParams: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorParams = action.payload
    },
    updateTerms: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorParams.terms = action.payload
    },
    updateSelectedTerm: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorParams.selectedTerm = action.payload
    },
    updateCustomer: (state, action) => {
      state.loanApplications[state.selectedTab].customer = action.payload
    },
    updateMonthlySalary: (state, action) => {
      state.loanApplications[state.selectedTab].customerMonthlySalary = action.payload
      if (action.payload) {
        state.loanApplications[state.selectedTab].customerYearlySalary =
          parseFloat(action.payload) * 12
      }
    },
    updateYearlySalary: (state, action) => {
      state.loanApplications[state.selectedTab].customerYearlySalary = action.payload
    },
    updateEmploymentStatus: (state, action) => {
      state.loanApplications[state.selectedTab].customerEmploymentStatus = action.payload
    },
    updateCustomerDataForm: (state, action) => {
      state.loanApplications[state.selectedTab].currentWorkflowState =
        LoanApplicationWorkflowTypes.applicationPreparation
      state.loanApplications[state.selectedTab].customerDataFormSaved = true
      state.loanApplications[state.selectedTab].customerDataFormOpened = false
      state.loanApplications[state.selectedTab].sendToBanksButtonDisabled = false
      const customerDocs = state.loanApplications[state.selectedTab].customerDocuments.map(
        (document) => {
          return {
            ...document,
            files: [
              ...document.files.filter((file) => !file.deleted && !file.newFile),
              ...action.payload.filter((file) => file.type === document.systemName),
            ],
          }
        },
      )
      state.loanApplications[state.selectedTab].customerDocuments = customerDocs
    },
    updateWorkflowState: (state, action) => {
      state.loanApplications[state.selectedTab].currentWorkflowState = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setLoanCalculatorOpened: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorOpened = action.payload.expanded
    },
    setLoanCalculatorSaved: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorSaved = action.payload
    },
    setCustomerDataFormOpened: (state, action) => {
      state.loanApplications[state.selectedTab].customerDataFormOpened = action.payload
    },
    setBankResponsesOpened: (state, action) => {
      state.loanApplications[state.selectedTab].bankResponsesOpened = action.payload
    },
    setIsDealerSelected: (state, action) => {
      state.loanApplications[state.selectedTab].loanDeals.find(
        (loanDeal) => loanDeal.id === action.payload.loanDeal,
      ).isDealerSelected = action.payload.value
    },
    deleteLoanApplication: (state, action) => {
      state.loanApplications = state.loanApplications.filter((item) => item.id !== action.payload)
    },
    updateCustomerDocuments: (state, action) => {
      const { id, files } = action.payload
      const targetDocument = state.loanApplications[state.selectedTab].customerDocuments.find(
        (item) => item.id === id,
      )
      targetDocument.files = files
    },
    updateAdditionalDocuments: (state, action) => {
      state.loanApplications[state.selectedTab].loanDeals[
        action.payload.index
      ].additionalDocuments = action.payload.value
    },
    updateLoanDealNote: (state, action) => {
      state.loanApplications[state.selectedTab].loanDeals[action.payload.index].note =
        action.payload.value
    },
    updateLoanDealWorkflowState: (state, action) => {
      state.loanApplications[state.selectedTab].loanDeals[
        action.payload.index
      ].currentWorkflowState = action.payload.value
    },
    updateSelectedPackage: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorParams.selectedPackage =
        action.payload
    },
    updateAdditionalProducts: (state, action) => {
      state.loanApplications[state.selectedTab].loanCalculatorParams.additionalProducts =
        action.payload
    },
    updateLoanDealsWorkflow: (state, action) => {
      state.loanApplications[state.selectedTab].loanDeals = state.loanApplications[
        state.selectedTab
      ].loanDeals.map((item) => {
        return {
          ...item,
          currentWorkflowState: action.payload,
        }
      })
    },
    resetData: (state) => {
      state.loanApplications = []
      state.selectedTab = 0
      state.loanApplicationsLoading = true
    },
  },
})

export const {
  setBanks,
  setLoanApplications,
  setSelectedTab,
  setLoanCalculatorOpened,
  setCustomerDataFormOpened,
  createLoanApplication,
  updateLoanApplication,
  updateLoanCalculatorParams,
  updateTerms,
  updateCustomer,
  updateEmploymentStatus,
  updateMonthlySalary,
  updateYearlySalary,
  updateSelectedTerm,
  updateCustomerDataForm,
  updateWorkflowState,
  setBankResponsesOpened,
  setIsDealerSelected,
  deleteLoanApplication,
  updateCustomerDocuments,
  updateAdditionalDocuments,
  updateLoanDealNote,
  updateLoanDealWorkflowState,
  updateSelectedPackage,
  updateAdditionalProducts,
  updateLoanDealsWorkflow,
  resetData,
} = carFinanceSlice.actions

export default carFinanceSlice.reducer
