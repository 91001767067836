import React, { useEffect, useState } from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import ModalWrap from '../../../components/Modals/ModalWrap'
import TextH1 from '../../../ui/Text/TextH1'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedPackage, updateAdditionalProducts } from '../../../store/features/financeCar'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import IconCheckmark from '../../../assests/img/icons/icon-checkmark-black.svg'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'

const AdditionalProductsModal = (props) => {
  const { open, setOpen } = props

  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const terms = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.terms,
  )
  const selectedTerm = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.selectedTerm,
  )
  const selectedPackage = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams.selectedPackage,
  )
  const additionalProducts = useSelector(
    (state) =>
      state.carFinance.loanApplications[selectedTab].loanCalculatorParams.additionalProducts,
  )
  const [currentTerm, setCurrentTerm] = useState(null)

  useEffect(() => {
    if (terms.length > 0 && selectedTerm) {
      setCurrentTerm(terms.find((item) => item.term === selectedTerm))
    }
  }, [terms, selectedTerm])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: '1042px',
        maxHeight: '91vh',
        '&.xs-only': {
          px: '22px',
          width: '90vw',
        },
        '&.tablet-only': {
          px: '22px',
          width: '90vw',
        },
      }}
    >
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '25px',
          '&.xs-only': {
            mb: '20px',
            fontSize: '24px',
          },
        }}
      >
        Include additional products?
      </TextH1>
      <Grid container spacing={'16px'}>
        <Grid item xs={12} md={4}>
          <Box
            className={selectedPackage === 'full' ? 'active' : ''}
            sx={{
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '35px',
              py: '30px',
              height: '400px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Full Pack
            </TextBoldNormal>
            <TextNormal
              sx={{
                mb: '15px',
              }}
            >
              Loan +
            </TextNormal>
            <Box
              sx={{
                mb: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Residual Value</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Warranty</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Casco</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>PPI</TextNormal>
              </Box>
            </Box>
            <TextNormal
              sx={{
                mb: '20px',
              }}
            >
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {sumDelimiter(Math.floor(currentTerm?.fullPackAmount)).replace("'", ' ')}
              </span>{' '}
              AED/month
            </TextNormal>
            <ButtonLG
              sx={{
                minWidth: '180px',
              }}
              onClick={() => {
                dispatch(updateSelectedPackage('full'))
                setOpen(false)
              }}
            >
              {selectedPackage === 'full' ? 'Selected' : 'Choose'}
            </ButtonLG>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            className={[
              selectedPackage === 'finance-only' ? 'active' : '',
              xsOnly ? 'xs-only' : '',
              tabletOnly ? 'tablet-only' : '',
            ].join(' ')}
            sx={{
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '35px',
              py: '30px',
              height: '400px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
              '&.xs-only, &.tablet-only': {
                height: 'auto',
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Finance Only
            </TextBoldNormal>
            <TextNormal
              className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
              sx={{
                mb: '176px',
                '&.xs-only, &.tablet-only': {
                  mb: '20px',
                },
              }}
            >
              Loan
            </TextNormal>
            <TextNormal
              sx={{
                mb: '20px',
              }}
            >
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {sumDelimiter(Math.floor(currentTerm?.minimumMonthlyPayment)).replace("'", ' ')}
              </span>{' '}
              AED/month
            </TextNormal>
            <ButtonLG
              sx={{
                minWidth: '180px',
              }}
              onClick={() => {
                dispatch(updateSelectedPackage('finance-only'))
                setOpen(false)
              }}
            >
              {selectedPackage === 'finance-only' ? 'Selected' : 'Choose'}
            </ButtonLG>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            className={selectedPackage === 'custom' ? 'active' : ''}
            sx={{
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '35px',
              py: '30px',
              height: '400px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Custom Pack
            </TextBoldNormal>
            <TextNormal
              sx={{
                mb: '15px',
              }}
            >
              Loan +
            </TextNormal>
            <Box
              sx={{
                mb: '21px',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={additionalProducts.rvi}
                  setValue={(newVal) => {
                    dispatch(
                      updateAdditionalProducts({
                        ...additionalProducts,
                        rvi: newVal,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>Residual Value</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={additionalProducts.warranty}
                  setValue={(newVal) => {
                    dispatch(
                      updateAdditionalProducts({
                        ...additionalProducts,
                        warranty: newVal,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>Warranty</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={additionalProducts.casco}
                  setValue={(newVal) => {
                    dispatch(
                      updateAdditionalProducts({
                        ...additionalProducts,
                        casco: newVal,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>Casco</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={additionalProducts.ppi}
                  setValue={(newVal) => {
                    dispatch(
                      updateAdditionalProducts({
                        ...additionalProducts,
                        ppi: newVal,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>PPI</TextNormal>
              </Box>
            </Box>
            <TextNormal
              sx={{
                mb: '20px',
              }}
            >
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {sumDelimiter(Math.floor(currentTerm?.customPackAmount)).replace("'", ' ')}
              </span>{' '}
              AED/month
            </TextNormal>
            <ButtonLG
              sx={{
                minWidth: '180px',
              }}
              onClick={() => {
                dispatch(updateSelectedPackage('custom'))
                setOpen(false)
              }}
            >
              {selectedPackage === 'custom' ? 'Selected' : 'Choose'}
            </ButtonLG>
          </Box>
        </Grid>
      </Grid>
    </ModalWrap>
  )
}

export default AdditionalProductsModal
