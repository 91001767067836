import React, { useState } from 'react'
import { Grid } from '@mui/material'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import TextFieldEditable from '../../ui/TextFieldEditable'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserSettings } from '../../store/features/authSlice'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import ChangeEmailModal from './ChangeEmailModal'
import TextNormal from '../../ui/Text/TextNormal'
import ChangePasswordModal from './ChangePasswordModal'

const UserSettings = () => {
  const dispatch = useDispatch()
  const userSettings = useSelector((state) => state.auth.userSettings)
  const user = useSelector((state) => state.auth.user)

  const [fieldsLoading, setFieldsLoading] = useState([])
  const [emailModal, setEmailModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)

  const handleSubmit = (name, endPoint) => {
    if (Boolean(fieldsLoading.find((item) => item === name))) return
    setFieldsLoading([...fieldsLoading, name])
    const formData = {}
    formData[name] = userSettings[name]
    privateRequest.put(endPoint, formData).then(() => {
      setFieldsLoading(fieldsLoading.filter((item) => item !== name))
    })
  }

  return (
    <>
      <Grid container spacing={'24px'}>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldSmallEdible fullWidth label={'User name'} value={user?.login} disabled />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldEditable
            fullWidth
            label={'First name'}
            value={userSettings.firstName}
            onChange={(e) => {
              dispatch(
                updateUserSettings({
                  ...userSettings,
                  firstName: e.target.value,
                }),
              )
            }}
            handleSubmit={() => {
              handleSubmit('firstName', API_ENDPOINTS.managerCredentials)
            }}
            loading={Boolean(fieldsLoading.find((item) => item === 'firstName'))}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldEditable
            fullWidth
            label={'Last name'}
            value={userSettings.surname}
            onChange={(e) => {
              dispatch(
                updateUserSettings({
                  ...userSettings,
                  surname: e.target.value,
                }),
              )
            }}
            handleSubmit={() => {
              handleSubmit('surname', API_ENDPOINTS.managerCredentials)
            }}
            loading={Boolean(fieldsLoading.find((item) => item === 'surname'))}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldEditable
            fullWidth
            label={'Email'}
            value={user?.email}
            openModal={() => {
              setEmailModal(true)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldEditable
            fullWidth
            label={'Mobile number'}
            value={userSettings.phoneNumber?.replace('971', '')}
            onChange={(e) => {
              dispatch(
                updateUserSettings({
                  ...userSettings,
                  phoneNumber: `971${e.target.value}`,
                }),
              )
            }}
            handleSubmit={() => {
              handleSubmit('phoneNumber', API_ENDPOINTS.managerCredentials)
            }}
            loading={Boolean(fieldsLoading.find((item) => item === 'phoneNumber'))}
            InputProps={{
              startAdornment: (
                <TextNormal
                  sx={{
                    color: 'rgba(0, 0, 0, 0.38)',
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    mr: '5px',
                    position: 'relative',
                    lineHeight: 'normal',
                  }}
                >
                  + 971
                </TextNormal>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFieldEditable
            fullWidth
            label={'Password'}
            value={'*********'}
            openModal={() => {
              setPasswordModal(true)
            }}
          />
        </Grid>
      </Grid>
      <ChangeEmailModal open={emailModal} setOpen={setEmailModal} />
      <ChangePasswordModal open={passwordModal} setOpen={setPasswordModal} />
    </>
  )
}

export default UserSettings
