import React, { useState, useEffect } from 'react'
import { Box, Button, useMediaQuery } from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import CarTable from '../VirtualGarage/CarTable'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { useSelector, useDispatch } from 'react-redux'
import { privateRequest } from '../../utils/request'
import { useNavigate } from 'react-router-dom'
import { setAuctionCarsAmount } from '../../store/features/authSlice'

const AppraisalModal = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const actions = [
    {
      title: 'Appraisal on-site',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
      ],
      actions: [
        // {
        //     type: 'workflowTransition',
        //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`
        // },
        {
          type: 'navigate',
          link: '/cars/:id/appraisal',
        },
      ],
    },
    {
      title: 'Appraisal on Fleatify compounds',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        // {
        //     role: 'COMPANY',
        //     status: 'car_workflow.moderation_failed'
        // },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'send_to_compound',
        },
      ],
    },
  ]

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const role = useSelector((state) => state.auth.role)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [cars, setCars] = useState([])
  const [statuses, setStatuses] = useState([])
  const [filterStatus, setFilterStatus] = useState(null)
  const [sort, setSort] = useState('&order[lastUpdatedAt]=desc')
  const [searchLine, setSearchLine] = useState('')
  const [archivedCarsCount, setArchivedCarsCount] = useState(0)

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getDaysInStock = (date) => {
    const date1 = new Date()
    const date2 = new Date(date)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return diffDays === 0 ? '0 days' : diffDays === 1 ? '1 day' : `${diffDays} days`
  }

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const getActionList = (carStatus, isArchived, isChecksDone) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'appraisalChecks' && !isChecksDone) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const handleSetFilters = (data, mode) => {
    if (mode === 'cars') {
      let filteredData
      if (role === 'OPERATOR') {
        filteredData = data.filter(
          (item) =>
            (item.name === 'car_workflow.quality_check' ||
              item.name === 'deal_workflow.pending' ||
              item.name === 'car_workflow.requested_appraisal_compound' ||
              item.name === 'car_workflow.awaiting_appraisal_compound' ||
              item.name === 'deal_workflow.pending') &&
            item.count !== 0,
        )
      } else if (role === 'EXPERT') {
        filteredData = data.filter(
          (item) =>
            (item.name === 'car_workflow.quality_check' ||
              item.name === 'deal_workflow.pending' ||
              item.name === 'car_workflow.requested_appraisal_compound' ||
              item.name === 'car_workflow.awaiting_appraisal_compound' ||
              item.name === 'deal_workflow.pending') &&
            item.count !== 0,
        )
      } else if (role === 'COMPANY') {
        filteredData = data.filter((item) => item.count !== 0)
      }
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
  }

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  const getCars = () => {
    let link
    if (!filterStatus) {
      link = API_ENDPOINTS.cars.newCars
    } else if (filterStatus !== 'Archived') {
      link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
    } else if (filterStatus === 'Archived') {
      link = API_ENDPOINTS.cars.companyCars.replace('isArchived=false', 'isArchived=true')
    }
    if (searchLine) {
      link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
    }
    if (sort) {
      link = `${link}${sort}`
    }
    privateRequest.get(link).then((response) => {
      setCars(
        response.data['hydra:member'].map((item) => {
          let link = `/cars/${item.id}`
          return {
            id: item.id,
            link: link,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.referenceNumber ? item.referenceNumber : item.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.make.name} ${item.model.name}`,
                caption: `VIN ${item.vin}`,
              },
              {
                type: 'text',
                value: 'Your garage',
              },
              {
                type: 'text',
                value: getDaysInStock(item.createdAt),
              },
              {
                type: 'text',
                value: getCarStatus(item.combinedStatus, item.isArchived),
              },
              {
                type: 'actions',
                value: getActionList(
                  item.combinedStatus,
                  item.isArchived,
                  item.isAppraisalChecksDone,
                ),
              },
            ],
          }
        }),
      )
    })
  }

  const getCarStatusCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.statusCount).then((response) => {
      const data = response.data['hydra:member']
      handleSetFilters(data, 'cars')
    })
  }

  const getArchivedCarsCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.archivedCars).then((response) => {
      setArchivedCarsCount(response.data['hydra:totalItems'])
    })
  }

  useEffect(() => {
    if (workflowsLoaded) {
      getCars()
      getCarStatusCount()
      getAuctionsAmount()
      getArchivedCarsCount()
    }
  }, [workflowsLoaded, filterStatus, searchLine, sort])

  return (
    <Box
      sx={{
        position: 'relative',
        display: {
          sm: 'block',
        },
        '& > :last-child > * > :nth-child(2)': {
          display: xsOnly && 'none',
        },
      }}
    >
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: '10px',
          mb: '-20px',
          display: 'flex',
          '&.xs-only': {
            display: 'block',
            mt: 0,
            mb: 0,
            position: 'relative',
          },
        }}
      >
        <Button
          className={xsOnly ? 'xs-only' : ''}
          fullWidth={xsOnly}
          variant="contained"
          sx={{
            background: colorsConfig.mainGreen,
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: 700,
            textTransform: 'none',
            px: '60px',
            py: '10px',
            mr: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '85px',
            right: 0,
            color: colorsConfig.mainBlack,
            transition: '0.25s all ease-in-out',
            '&:hover': {
              background: colorsConfig.mainGreen,
              opacity: '0.8',
              transition: '0.25s all ease-in-out',
            },
            '&.xs-only': {
              bottom: '-178px',
              right: 'auto',
              left: 0,
              width: '100%',
              top: 'auto',
              height: '48px',
            },
          }}
          onClick={() => {
            navigate('/add-new-vehicle')
          }}
        >
          <AddRounded
            sx={{
              fill: colorsConfig.mainBlack,
              mr: '11px',
              position: 'relative',
              mt: '-1px',
            }}
          />
          Add new vehicle
        </Button>
      </Box>
      <Box
        id={'car-table'}
        sx={{
          py: {
            xs: 0,
            sm: '37px',
          },
          pl: {
            xs: 0,
            sm: '40px',
          },
          pr: {
            xs: 0,
            sm: '15px',
          },
          display: {
            md: 'block',
          },
        }}
      >
        <CarTable
          type={'appraisal-modal'}
          content={cars}
          filters={statuses}
          setFilterStatus={setFilterStatus}
          getCars={getCars}
          searchLine={searchLine}
          setSearchLine={setSearchLine}
          sort={sort}
          setSort={setSort}
          cars={cars}
          setCars={setCars}
          getActionList={getActionList}
          archivedCount={archivedCarsCount}
        />
      </Box>
    </Box>
  )
}

export default AppraisalModal
