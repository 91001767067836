import { privateMultipartRequest, privateRequest } from '../../utils/request'

class FilesService {
  #api

  #multiPartApi

  #endPoints

  constructor({ api, multiPartApi }) {
    this.#multiPartApi = multiPartApi
    this.#api = api
    this.#endPoints = {
      files: '/files',
      privateFileById: (id) => `/private-files/${id}`,
    }
  }

  getPrivateFile = async (fileId) => {
    const response = await this.#api.get(this.#endPoints.privateFileById(fileId))
    return response
  }

  createFile = async (file, type) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    const response = await this.#multiPartApi.post(this.#endPoints.files, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }
}

const filesService = new FilesService({
  api: privateRequest,
  multiPartApi: privateMultipartRequest,
})

export default filesService
