import React from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Stack,
  Box,
  useMediaQuery,
} from '@mui/material'
import UITextTitleSmall from '../UIText/UITextTitleSmall'
import UITriangleButton from '../UIButtons/UITriangleButton'
import { useTranslation } from 'react-i18next'
import UITableBodyCell from './UITableBodyCell'
import UITextRegular from '../UIText/UITextRegular'
import UITextBodySmall from '../UIText/UITextBodySmall'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'

const UITable = ({ head, content, emptyText, sort, setSort, renderActionItems }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return content && content?.length !== 0 ? (
    <Table
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        '&.xs-only': {
          minWidth: '1000px',
        },
      }}
    >
      <TableHead sx={{ border: 'none' }}>
        <TableRow>
          {head.map((item) => {
            return (
              <UITableBodyCell key={item.title}>
                <UITextTitleSmall>{t(item.title)}</UITextTitleSmall>
                {item.sortable && sort ? (
                  <React.Fragment>
                    <UITriangleButton
                      direction="down"
                      active={sort.order === 'desc' && sort.key === item.sortKey}
                      onClick={() => {
                        if (sort.order === 'desc' && sort.key === item.sortKey) return
                        setSort({
                          order: 'desc',
                          key: item.sortKey,
                        })
                      }}
                    />
                    <UITriangleButton
                      direction="up"
                      active={sort.order === 'asc' && sort.key === item.sortKey}
                      onClick={() => {
                        if (sort.order === 'asc' && sort.key === item.sortKey) return
                        setSort({
                          order: 'asc',
                          key: item.sortKey,
                        })
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </UITableBodyCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {content.map((row) => {
          return (
            <TableRow
              key={row.id}
              sx={{ my: '8px', cursor: row?.link ? 'pointer' : 'auto' }}
              onClick={() => {
                if (row?.link) {
                  navigate(row?.link)
                }
              }}
            >
              {row.cells.map((cell, index) => {
                return (
                  <UITableBodyCell
                    key={cell.key ?? index}
                    sx={{
                      py: cell.type === 'action' ? 0 : '12px',
                    }}
                    onClick={(e) => {
                      if (cell.type === 'action' && row?.link) {
                        e.stopPropagation()
                      }
                    }}
                  >
                    {cell.type === 'text' ? <UITextRegular text={cell.value} /> : null}

                    {cell.type === 'text-with-vin' ? (
                      <Stack>
                        <UITextRegular text={cell.value} />
                        <UITextBodySmall
                          sx={{ color: colorsConfig.lightGray, whiteSpace: 'no-wrap' }}
                          text={`VIN: ${cell.vin}`}
                        />
                      </Stack>
                    ) : null}
                    {cell.type === 'component' ? cell.value : null}
                    {cell.type === 'action' ? renderActionItems(row) : null}
                  </UITableBodyCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  ) : (
    <Box
      sx={{
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UITextRegular text={emptyText} sx={{ color: colorsConfig.lightGray }} />
    </Box>
  )
}

export default UITable
