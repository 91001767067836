import { createAsyncThunk } from '@reduxjs/toolkit'
import carService from '../../../api/carService/carApiService'
import { toast } from 'react-toastify'
import carApiService from '../../../api/carService/carApiService'
import filesApiService from '../../../api/filesService/filesApiService'
import { fileTypeEnums } from '../../../utils/constants/enums.constants'
import { apiConstants } from '../../../api/api'

export const createCar = createAsyncThunk(
  'addNewCar/createCar',
  async ({ coreValuesFormData, mainValuesFormData }, { rejectWithValue }) => {
    try {
      const createRes = await carService.createCar(coreValuesFormData)
      const carUrl = createRes.data?.['@id']
      const editRes = await carService.updateCar(carUrl, mainValuesFormData)
      return editRes.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const updateCar = createAsyncThunk(
  'addNewCar/updateCar',
  async ({ coreValuesFormData, mainValuesFormData }, { rejectWithValue, getState }) => {
    try {
      const carUrl = getState().addCar.carCreatedId
      const editRes = await carService.updateCar(carUrl, {
        ...coreValuesFormData,
        ...mainValuesFormData,
      })
      return editRes.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const createCarOperator = createAsyncThunk(
  'addNewCar/createCarOperator',
  async ({ carData, companyData }, { rejectWithValue }) => {
    try {
      const response = await carApiService.createCar(carData)
      await carApiService.changeWorkflowConfirmToDraft(response.data?.id, companyData)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getCarDetailsFromVINProvider = createAsyncThunk(
  'addNewCar/getCarDetailsFromVINProvider',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await carApiService.getCarDetailsFromVIN(data)
      if (response.data?.responseStatus === 'missing_fields') {
        throw new Error('missing_fields')
      }
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const attachAppraisalDocument = createAsyncThunk(
  'addNewCar/attachAppraisalDocument',
  async ({ file }, { rejectWithValue, getState }) => {
    try {
      const carId = getState().addCar.createdCarData?.id
      const fileRes = await filesApiService.createFile(file, fileTypeEnums.default)
      const response = await carApiService.attachAppraisalDocument(carId, {
        appraisalDocument: fileRes.data?.['@id'],
      })
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getInitialMakeAndModel = createAsyncThunk(
  'addCar/getInitialMakeAndModel',
  async ({ makeName, modelName }, { rejectWithValue }) => {
    try {
      const makesParams = {
        pagination: false,
        name: makeName,
      }
      const makesRes = await carApiService.getMakes(makesParams)
      const makes = makesRes.data?.[apiConstants.hydraMember]
      const matchMake = makes.find((item) => item?.name?.toLowerCase() === makeName.toLowerCase())
      if (matchMake) {
        const modelsParams = {
          pagination: false,
          make: matchMake?.['@id'],
        }
        const modelsRes = await carApiService.getModels(modelsParams)
        const models = modelsRes.data?.[apiConstants.hydraMember]
        const matchModel = models.find(
          (item) => item?.name?.toLowerCase() === modelName.toLowerCase(),
        )
        return {
          make: matchMake,
          model: matchModel,
        }
      }
      return {
        make: matchMake,
        model: null,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
