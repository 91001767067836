import { getPoliciesStatusCount } from '../rviDealer.actions'

export default (builder) => {
  builder
    // .addCase(.pending, (state) => {
    //
    // })
    .addCase(getPoliciesStatusCount.fulfilled, (state, action) => {
      state.statusCount.active = action.payload.activePoliciesCount
      state.statusCount.closed = action.payload.closedPoliciesCount
    })
  // .addCase(.rejected, (state, action) => {
  //
  // })
}
