import UITextBodySmall from '../../ui/UIText/UITextBodySmall'
import { formatDate, getFormattedDate, getRequestMarkerClass } from '../date.helpers'
import UITableCellIcon from '../../ui/UITable/UITableCellIcon'
import {
  policyWorkflowStateNameKeys,
  policyWorkflowStateNames,
} from '../constants/workflows.constants'
import { vehicleExpertiseNames } from '../constants/enums.constants'
import UIMarkerCircle from '../../ui/Markers/UIMarkerCircle'
import TextNormal from '../../ui/Text/TextNormal'
import { handleFormatDate } from '../formatDate'
import { Box } from '@mui/material'
import ButtonLink from '../../ui/Buttons/ButtonLink'
import { RemoveRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'

export const getOperatorRviTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    const details = item?.details
    return {
      initialData: item,
      id: item.id,
      link: `/operator/rvi/policies/${item?.id}`,
      cells: [
        {
          type: 'text',
          value: item?.id ?? '-',
        },
        {
          type: 'text-with-vin',
          value: `${details?.make} ${details?.model}`,
          vin: details?.vin,
        },
        {
          type: 'text',
          value: details?.dealerName,
        },
        {
          type: 'text',
          value: getFormattedDate(details?.RVIStartDate) ?? '-',
        },
        {
          type: 'text',
          value: getFormattedDate(details?.RVIEndDate) ?? '-',
        },
        {
          type: 'text',
          value: policyWorkflowStateNames[item?.currentWorkflowState] ?? '-',
        },
        {
          type: 'action',
        },
      ],
    }
  })
}

export const getOperatorRviRestitutionInProgressTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    const details = item?.details
    const restitutionDetails = item?.restitutionDetails || {}
    const { restitutionDate, mileage, location, vehicleExpertise } = restitutionDetails
    return {
      initialData: item,
      id: item.id,
      link: `/operator/rvi/policies/${item?.id}`,
      cells: [
        {
          type: 'text-with-vin',
          value: `${details?.make} ${details?.model}`,
          vin: details?.vin,
        },
        {
          type: 'text',
          value: details?.dealerName,
        },
        // {
        //   type: 'text',
        //   value: location ?? '-',
        // },
        {
          type: 'text',
          value: mileage ? `${mileage} km` : '-',
        },
        {
          type: 'text',
          value: vehicleExpertise ? vehicleExpertiseNames[vehicleExpertise] : '-',
        },
        {
          type: 'text',
          value: item?.currentWorkflowState
            ? policyWorkflowStateNames[item?.currentWorkflowState]
            : 'Unknown',
        },
        {
          type: 'component',
          value: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <UIMarkerCircle className={getRequestMarkerClass(restitutionDate)} />
              <TextNormal>{handleFormatDate(restitutionDate, '.')}</TextNormal>
            </Box>
          ),
        },
        {
          type: 'action',
        },
      ],
    }
  })
}

export const getRviTableData = (data, t) => {
  if (!data) return []
  return data.map((item) => {
    const details = item?.details
    return {
      initialData: item,
      id: item.id,
      link: `/rvi/policies/${item?.id}`,
      cells: [
        {
          type: 'text',
          value: item?.id ?? '-',
        },
        {
          type: 'text-with-vin',
          value: `${details?.make} ${details?.model}`,
          vin: details?.vin,
        },
        {
          type: 'text',
          value: getFormattedDate(details?.RVIStartDate) ?? '-',
        },
        {
          type: 'text',
          value: getFormattedDate(details?.RVIEndDate) ?? '-',
        },
        {
          type: 'text',
          value: item?.currentWorkflowState
            ? t(policyWorkflowStateNameKeys[item?.currentWorkflowState])
            : 'Unknown',
        },
        {
          type: 'action',
        },
      ],
    }
  })
}

export const getPolicyImportsTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    const status = `${item?.status.slice(0, 1).toUpperCase()}${item?.status?.slice(1)}`
    return {
      initialData: item,
      id: item.id,
      cells: [
        {
          type: 'text',
          value: item?.id ?? '-',
        },
        {
          type: 'text',
          value: getFormattedDate(item?.createdAt),
        },
        {
          type: 'text',
          value: status ?? '-',
        },
        {
          type: 'text',
          value: item?.errorMessage ?? '-',
        },
        {
          type: 'text',
          value: item?.policyCount ?? '-',
        },
        {
          type: 'action',
        },
      ],
    }
  })
}

export const getImportedPoliciesTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    const details = item?.details
    return {
      initialData: item,
      id: item.id,
      cells: [
        {
          type: 'text',
          value: item?.policyNumber,
        },
        {
          type: 'text-with-vin',
          value: `${details?.make} ${details?.model}`,
          vin: details?.vin,
        },
        {
          type: 'text',
          value: details?.dealerName,
        },
        {
          type: 'action',
        },
      ],
    }
  })
}

export const getCompaniesTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    return {
      initialData: item,
      id: item.id,
      cells: [
        {
          type: 'text',
          value: item?.id,
        },
        {
          type: 'text',
          value: item?.name,
        },
        {
          type: 'component',
          value: (
            <ButtonLink
              sx={{ textDecoration: 'none', fontSize: '16px' }}
              component="a"
              href={`mailto:${item?.invoiceEmail}`}
            >
              {item?.invoiceEmail}
            </ButtonLink>
          ),
        },
        {
          type: 'component',
          value: item?.landlineNumber ? (
            <ButtonLink
              sx={{ textDecoration: 'none', fontSize: '16px' }}
              component="a"
              href={`tel:${item?.landlineNumber}`}
            >
              +{item?.landlineNumber}
            </ButtonLink>
          ) : (
            <RemoveRounded sx={{ fill: colorsConfig.lightGray }} />
          ),
        },
        {
          type: 'text',
          value: getFormattedDate(item?.createdAt),
        },
      ],
    }
  })
}
