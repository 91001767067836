import React, { useEffect, useState } from 'react'
import { MERCURE_BASE_URL } from '../config/default'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { toast } from 'react-toastify'

const _endPoints = {
  importRvi: (id) => `${MERCURE_BASE_URL}?topic=/rvi/policy-imports/${id}/import-result`,
}

const UseImportRvi = ({ onImportSuccess }) => {
  const [policyImport, setPolicyImport] = useState(null)
  const [importResult, setImportResult] = useState(null)
  const [importResultError, setImportResultError] = useState(null)

  const handleSubscribe = () => {
    const mercureToken = localStorage.getItem('mercure_token')

    if (!mercureToken) {
      setImportResultError({ errorMessage: 'Mercure token not found' })
      return
    }
    const eventSource = new EventSourcePolyfill(_endPoints.importRvi(policyImport).toString(), {
      headers: {
        Authorization: `Bearer ${mercureToken}`,
      },
    })

    eventSource.onmessage = function (event) {
      if (event && event.data) {
        const data = JSON.parse(event.data)
        if (data.success === true) {
          setImportResult(data)
          onImportSuccess(data)
        } else if (data.success === false) {
          setImportResultError(event.data)
          toast.error('Wrong file format')
        }
        eventSource.close()
      } else {
        setImportResult('SUCCESS')
        onImportSuccess('SUCCESS')
        eventSource.close()
      }
    }

    eventSource.onerror = function (error) {
      if (error && error.data) {
        setImportResultError(error.data)
        toast.error(error.data)
        eventSource.close()
      } else {
        setImportResultError('Wrong file format')
        toast.error('Wrong file format')
        eventSource.close()
      }
    }
  }

  useEffect(() => {
    if (policyImport) {
      handleSubscribe()
    }
  }, [policyImport])

  return {
    setPolicyImport,
    importResult,
    importResultError,
  }
}

export default UseImportRvi
