import React, { useState } from 'react'
import { Box, Button, Typography, IconButton, useMediaQuery } from '@mui/material'
import { AccountCircleRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import iconBell from '../../../assests/img/icons/icon-bell.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LogoWhite from '../../../assests/img/logo-white.svg'
import IconMobileBurger from '../../../assests/img/logo-white.svg'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import HeaderMobileMenu from '../../HeaderMobileMenu'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import i18n from 'i18next'
import RequestCoinsModal from '../../Modals/RequestCoinsModal'
import LanguageSelect from '../../LanguageSelect'
import HeaderProfile from './HeaderProfile/HeaderProfile'

const Header = () => {
  const navigate = useNavigate()
  const login = useSelector((state) => state.auth.login)
  const avatar = useSelector((state) => state.auth.avatar)
  const balance = useSelector((state) => state.auth.balance)
  const role = useSelector((state) => state.auth.role)
  const unreadNotificationsCount = useSelector((state) => state.auth.unreadNotificationsCount)
  const mobileOnly = useMediaQuery('(max-width: 767px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [notEnoughCoinsModal, setNotEnoughCoinsModal] = useState(false)

  return (
    <React.Fragment>
      {mobileOnly || tabletOnly ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            width: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              height: '65px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '15px',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/virtual-garage')
              }}
            >
              <img
                src={LogoWhite}
                alt=""
                style={{
                  width: '90px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Box>
                <IconButton
                  sx={{
                    position: 'relative',
                  }}
                  onClick={() => {
                    if (role && role === 'COMPANY') {
                      navigate('/notifications')
                    }
                  }}
                >
                  <img src={iconBell} alt="" />
                  {unreadNotificationsCount > 0 ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '7px',
                        right: '9px',
                        background: colorsConfig.mainGreen,
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        pointerEvents: 'none',
                      }}
                    />
                  ) : null}
                </IconButton>
              </Box>
              {role && role === 'COMPANY' ? (
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: colorsConfig.mainBlack,
                      background: 'white',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                      fontSize: '16px',
                      fontWeight: 700,
                      py: '3.2px',
                      px: '7.425px',
                      minWidth: '94px',
                      boxShadow: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '&:hover': {
                        background: 'white',
                      },
                    }}
                    onClick={() => {
                      setNotEnoughCoinsModal(true)
                    }}
                  >
                    <img
                      src={IconCoin}
                      style={{ width: '18px', height: '18px', marginRight: '6px' }}
                      alt=""
                    />
                    {sumDelimiter(balance)}
                  </Button>
                </Box>
              ) : null}
              <IconButton
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
              >
                <img src={IconMobileBurger} alt="" />
              </IconButton>
            </Box>
          </Box>
          <HeaderMobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
        </Box>
      ) : (
        <Box
          sx={{
            height: '72px',
            backgroundSize: '100% auto',
            paddingLeft: '20px',
            paddingTop: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingRight: '100px',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <HeaderProfile />
            {role === 'COMPANY' ? (
              <Box>
                <LanguageSelect variant={'square'} />
              </Box>
            ) : null}
            <Box
              sx={{
                marginRight: '10px',
              }}
            >
              <IconButton
                sx={{
                  position: 'relative',
                }}
                onClick={() => {
                  if (role && role === 'COMPANY') {
                    navigate('/notifications')
                  }
                }}
              >
                <img src={iconBell} alt="" />
                {unreadNotificationsCount > 0 ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '7px',
                      right: '9px',
                      background: colorsConfig.mainGreen,
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      pointerEvents: 'none',
                    }}
                  />
                ) : null}
              </IconButton>
            </Box>
            {role && role === 'COMPANY' ? (
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    color: colorsConfig.mainBlack,
                    background: 'white',
                    border: '1px solid',
                    borderColor: colorsConfig.mainBlack,
                    fontSize: '16px',
                    fontWeight: 700,
                    py: '3.2px',
                    px: '7.425px',
                    minWidth: '94px',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  onClick={() => {
                    setNotEnoughCoinsModal(true)
                  }}
                >
                  <img
                    src={IconCoin}
                    style={{ width: '18px', height: '18px', marginRight: '6px' }}
                    alt=""
                  />
                  {sumDelimiter(balance)}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
      <RequestCoinsModal open={notEnoughCoinsModal} setOpen={setNotEnoughCoinsModal} />
    </React.Fragment>
  )
}

export default Header
