export const isEngineTypeElectric = (engineType) => {
  return engineType === 5
}

export const cleanObject = (obj) => {
  const cleanedObj = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value !== '' && value !== null && value !== undefined && value !== 0) {
        cleanedObj[key] = value
      }
    }
  }
  return cleanedObj
}

export const capitalizeString = (str) => {
  return `${str?.slice(0, 1)?.toUpperCase()}${str?.slice(1)}`
}

export const getCarPaintwork = (car) => {
  const paintwork = []
  if (car?.isMetallic) paintwork.push('Metallic')
  if (car?.isMatt) paintwork.push('Matt')
  if (car?.isWrapped) paintwork.push('Wrapped')
  return paintwork.length !== 0 ? paintwork.join(', ') : '-'
}
