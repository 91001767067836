import companiesApiService from '../../../api/companiesApiService/companiesApiService'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().companies.companies.params
      const response = await companiesApiService.getCompanies(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAllCompanies = createAsyncThunk(
  'companies/getAllCompanies',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        pagination: false,
      }
      const response = await companiesApiService.getCompanies(params)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)
