import React from 'react'
import { Button, Box } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'

const HeaderProfileMenuItem = ({ icon, text, ...props }) => {
  return (
    <Button
      sx={{
        py: '5px',
        px: '12px',
        borderTop: '1px solid',
        borderColor: colorsConfig.gray20,
        textTransform: 'none',
        fontFamily: '"Helvetica", sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        color: colorsConfig.mainBlack,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '7px',
        '& svg': {
          fill: colorsConfig.mainBlack,
        },
      }}
      {...props}
    >
      <span style={{ minWidth: '30px', display: 'inline-flex', alignItems: 'center' }}>{icon}</span>

      {text}
    </Button>
  )
}

export default HeaderProfileMenuItem
